import React, { Component } from 'react';
import { apiHelper } from "../../Helper/apiHelper";
class LikeComponent extends Component {
    
    constructor(props) {
		super(props);
		this.state = {
			postList: [],
            postModel: false,
            LikeCount: 0,
            UnLikeCount: 0
		}
	
    }

    async componentDidMount() {
        // var result = await apiHelper("get", `api/likePosts?filter[where][postId]=${this.props.postId}`, {}, null);
		// this.setState({ LikeCount : result.length })
		
	}

    async componentWillReceiveProps() {
        if(this.props.postId){
            this.getPostLike();
        }
	}


    async getPostLike(){
        var result = await apiHelper("get", `api/likePosts?filter[where][postId]=${this.props.postId}`, {}, null);
       
        let positive = [];
        let negative = [];
        
        positive = result.filter(function(item) {if(item.typeLike === "p") return item;});

        negative = result.filter(function(item) {if(item.typeLike === "n") return item;});

        let LikeCount = positive.length - negative.length;
        
        
        
		this.setState({LikeCount})
    }
    
   

    PostAction =  async (type) =>{
        let user = JSON.parse(localStorage.getItem("accessToken"));
        let userId = user.id;
        let postId = this.props.postId 
        let data = {
            userId :userId ,
            postId :postId ,
            typeLike : type
        }
        
        
        // https://bake.website/api/likePosts?filter[where][userId]=${userId}&[where][postId]=${postId}
        const result = await apiHelper("get", `api/likeposts?filter[where][userId]=${userId}&filter[where][postId]=${postId}`, {}, null);
        
        // let result = await apiHelper("get", `api/likePosts?filter[where][userId]=${userId}&[where][postId]=${postId}`, {}, null);
        if(result.length === 0){
             await apiHelper("post", `api/likeposts`, data , null);
             this.getPostLike();
        }else{
            const resultUpdate = await apiHelper(
                "post",
                `api/likeposts/update?where=%7B%22userId%22%3A%22${userId}%22%2C%22postId%22%3A%22${postId}%22%7D`,
                data,
                null
              );
            //   api/likePosts/update?where=%7B%22userId%22%3A%22${userId}%22%2C%22postId%22%3A%22${postId}%22%7D  
            //   /api/likePosts/update?where=%7B%20%22userId%22%3A%20%20%2261a526ae4c8f9406eba3261c%22%20%2C%20%20%22postId%22%20%3A%20%2261a397254c1f4c0c06536b33%22%20%7D
              if (resultUpdate.count == 1) {
                 this.getPostLike();
              }
        }
	}


    kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
    }

    render() {
        return (
            <div class="post-meta-like d-flex align-items-center">
            <span>{this.kFormatter(this.state.LikeCount)}</span>
            <div className="d-flex flex-column ml-2 align-items-center justify-content-center">
            <span className="fa fa-chevron-up p-0" onClick={() => this.PostAction('p')}> </span> 
            <img src="images/Popcorn.png" alt="" className="icon my-1" />
            <span class="fa fa-chevron-down p-0" onClick={() => this.PostAction('n')}></span>
            </div> 
        </div>
        );
    }
}

export default LikeComponent;
