import React, { Component } from "react";
import { Link } from "react-router-dom";
import { apiHelper } from "./../Helper/apiHelper";
import NotificationSystem from "react-notification-system";
import Header from "./Header";
import imageLoader from "../Image/loader.gif";
import "./home.css";
import $ from "jquery";
window.jQuery = $;

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addList: [],
      searchList: [],
      loader: false,
      search: "",
    };
    this._notificationSystem = React.createRef();
  }

  componentDidMount() {
    $("body").removeClass("transparent-header");
    $("html, body").animate({ scrollTop: 0 }, "slow");
    this.getRandomUser();
  }

  async getRandomUser() {
    var user = JSON.parse(localStorage.getItem("accessToken"));
    const notification = this._notificationSystem.current;
    this.setState({ loader: true });
    var result = await apiHelper(
      "get",
      `api/posts?filter[where][type]=show&filter[limit]=10`,
      {},
      null
    );
    this.setState({ loader: false });
    if (result.length > 0) {
      this.setState({
        addList: result,
      });
      notification.addNotification({
        message: "Select shows you have seen!",
        level: "success",
        tr: "top , right",
      });
    } else {
      notification.addNotification({
        message: result.message,
        level: "warning",
        tr: "top , right",
      });
    }
  }

  async addShowMovies(id, index, type) {
    var user = JSON.parse(localStorage.getItem("accessToken"));
    var data = {
      userId: user.id,
      postId: id,
      postStatus: type,
    };
    // if (type === "list") {
      var list = this.state.addList;
      list.splice(index, 1);
      this.setState({ addList: list });
    // } else {
    //   var list = this.state.searchList;
    //   list.splice(index, 1);
    //   this.setState({ searchList: list });
    // }

    const notification = this._notificationSystem.current;

    var resultData = await apiHelper(
      "get",
      `api/showLists?filter[where][userId]=${user.id}&filter[where][postId]=${id}`,
      {},
      null
    );
   
    if (resultData.length == 0) {
      var result = await apiHelper("post", `api/showLists`, data, null);
      if (result) {
        notification.addNotification({
          message: "Add Show Successfully",
          level: "success",
          tr: "top , right",
        });
      } else {
        notification.addNotification({
          message: "Something went wrong !",
          level: "warning",
          tr: "top , right",
        });
      }
    }
  }

  // http://localhost:8080/api/WebUsers?filter={%22where%22:{%22userName%22:{%22like%22:%22Tom*%22,%22options%22:%22i%22}}}

  imagePath = (avatar) => {
    var str = avatar;
    var n = str.includes("uploads/");
    if (n) {
      avatar = window.APIURL + avatar;
      return <img src={avatar} alt="profile picture" />;
    } else {
      return <img src={avatar} alt="profile picture" />;
    }
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  searchForUser = async (e) => {
    let th = this;
    if (e.key === "Enter") {
      var user = JSON.parse(localStorage.getItem("accessToken"));
      const notification = th._notificationSystem.current;
      th.setState({ loader: true });
      ;
      var result = await apiHelper(
        "get",
        `api/posts?filter={"where":{"and":[{"title":{"like":"${th.state.search}*","options":"i"}},{"type":"show"}]}}`,
        {},
        null
      );
      th.setState({ loader: false });
      ;
      if (result.length > 0) {
        th.setState({
          addList: result,
        });
        // notification.addNotification({
        //   message: "Select shows you have seen!",
        //   level: "success",
        //   tr: "top , right",
        // });
      } else {
        notification.addNotification({
          message: "No Record Find",
          level: "warning",
          tr: "top , right",
        });
      }
    }
  };

  itemCreated(data) {
    if (data.created_by) {
      if (data.created_by.length > 0) {
        let cre = data.created_by;
        return cre[0].name;
      }
    }
  }

  render() {
    var th = this;
    
    return (
      <div>
        <NotificationSystem ref={this._notificationSystem} />

        <main>
          <div class="main-wrapper">
            <Header />
            <div class="container">
              <div class="row">
                <div class="users-list">
                  <h5 className="h3New">What have you watched?</h5>
                  <h1 className="h1New">
                    Select shows & Movies you have seen!
                  </h1>

                  <div class="mt-5 mb-4">
                    <div class="search-form d-flex">
                      <input
                        name="search"
                        class="form-control share-text-field"
                        id="email"
                        type="search"
                        placeholder="Search your next binge..."
                        value={this.state.search}
                        onKeyPress={this.searchForUser}
                        onChange={this.onChange}
                      />
                      <span
                        className="search-icon"
                        onClick={(e) => {
                          this.searchForUser(e);
                        }}
                      >
                        <img
                          src="images/search-icon.png"
                          alt="Search Icon"
                          className="img-fluid"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="video-list mb-5">
                    <div className="row">
                      {this.state.addList.map(function (item, i) {
                        return (
                          <div key={item.id} className="col-md-4">
                            <div className="item">
                              <div className="image">
                                <figure class="">
                                  {th.imagePath(item.avatar)}
                                </figure>
                              </div>
                              <div className="overlay-button">
                                <div class="post-btn-list">
                                  <button
                                    class="btn btn-primary-outline"
                                    type ="button" 
                                    onClick={() => {
                                      th.addShowMovies(item.id, i, "binging");
                                    }}
                                  >
                                    Add to Binging
                                  </button>
                                  <button class="btn btn-primary"
                                    type ="button" 
                                    onClick={() => {
                                      th.addShowMovies(item.id, i, "upnext");
                                    }}
                                  >
                                    Add to Up Next
                                  </button>
                                  <button class="btn btn-secondary"
                                    type ="button" 
                                    onClick={() => {
                                      th.addShowMovies(item.id, i, "binged");
                                    }}
                                  >
                                    Add to Binged
                                  </button>
                                </div>
                              </div>
                              <div
                                style={{
                                  "text-align": "initial",
                                  "margin-top": "16px",
                                }}
                                class="share-content-box w-100"
                              >
                                <h6 class="author">
                                  {item.title}
                                  <br />
                                  {/* <span>{th.itemCreated(item)}</span> */}
                                </h6>
                              </div>
                            </div>
                          </div>
                          // <div key={i} class="card card-small">
                          //   <div class="share-box-inner">
                          //     <div class="profile-thumb">
                          //       <a>
                          //         <figure class="profile-thumb-middle">
                          //           {th.imagePath(item.avatar)}
                          //         </figure>
                          //       </a>
                          //     </div>

                          //     <div
                          //       style={{
                          //         "text-align": "initial",
                          //         "margin-top": "16px",
                          //       }}
                          //       class="share-content-box w-100"
                          //     >
                          //       <form class="share-text-box">
                          //         <h6 class="author">
                          //           <a>{item.title}</a>
                          //           <span>{th.itemCreated(item)}</span>
                          //         </h6>
                          //         <p>
                          //           {item.genres}
                          //         </p>
                          //       </form>
                          //     </div>
                          //   </div>
                          // </div>
                        );
                      })}
                      {this.state.addList.length === 0
                        ? "No Record Found!"
                        : ""}
                    </div>
                  </div>

                  <div class="lets-Go">
                    <div class="lets-inter">
                      <Link to="" className="letgoAtag ">
                        {" "}
                        Finish{" "}
                      </Link>
                    </div>
                  </div>
                  <div class="lets-OR">
                    <div class="share-box-inner lets-inter-or">
                      <Link to="" className="letgoAtag">
                        {" "}
                        Skip, and go to feed{" "}
                      </Link>
                    </div>
                  </div>

                  {this.state.searchList.map(function (item, i) {
                    return (
                      <div key={i} class="card card-small">
                        <div class="share-box-inner">
                          <div class="profile-thumb">
                            <a>
                              <figure class="profile-thumb-middle">
                                {th.imagePath(item.avatar)}
                              </figure>
                            </a>
                          </div>

                          <div
                            style={{
                              "text-align": "initial",
                              "margin-top": "16px",
                            }}
                            class="share-content-box w-100"
                          >
                            <form class="share-text-box">
                              <h6 class="author">
                                <a>{item.title} </a>
                                <span>{th.itemCreated(item)}</span>
                              </h6>
                              <p>{item.genres}</p>
                            </form>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </main>

        {this.state.loader === true ? (
          <div class="Loader">
            <img src={imageLoader} alt="profile picture" />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Footer;
