import socketIOClient from "socket.io-client";
var socket;
socket = socketIOClient('https://bake.website');
// socket = socketIOClient('http://localhost:8080');

  function postNotification(data) {
    socket.emit('new-notification', data);
  }
  
  function CreateTvCLub(data) {
    socket.emit('new-tv-club', data);
  }

  function AddFriend(data) {
    socket.emit('newFriendRequest', data);
  }

  function AddFriendMovieShowSuggestion(data) {
    socket.emit('movieShowSuggestion', data);
  }
  
  export { CreateTvCLub, postNotification, AddFriend, AddFriendMovieShowSuggestion, socket};
  