import React, { Component } from "react";
import { Link } from "react-router-dom";
import { apiHelper } from "./../Helper/apiHelper";
import NotificationSystem from "react-notification-system";
import Header from "./Header";
import imageLoader from "../Image/loader.gif";
import { AddFriend } from "../DashBoard/notification/create-notification";

import "./home.css";
import $ from "jquery";
window.jQuery = $;
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addList: [],
      searchList: [],
      loader: false,
      search: "",
    };
    this._notificationSystem = React.createRef();
  }

  componentDidMount() {
    $("body").removeClass("transparent-header");
    $("html, body").animate({ scrollTop: 0 }, "slow");

    this.getRandomUser();
  }

  async getRandomUser() {
    var user = JSON.parse(localStorage.getItem("accessToken"));
    const notification = this._notificationSystem.current;
    this.setState({ loader: true });
    var result = await apiHelper(
      "get",
      `api/WebUsers?filter[where][id][neq]=${user.id}&filter[limit]=10`,
      {},
      null
    );
    this.setState({ loader: false });
    if (result.length > 0) {
      this.setState({
        addList: result,
      });
      notification.addNotification({
        message: "Who Do You Know ?",
        level: "success",
        tr: "top , right",
      });
    } else {
      notification.addNotification({
        message: result.message,
        level: "warning",
        tr: "top , right",
      });
    }
  }

  searchForUser = async (e) => {
    let th = this;
    if (e.key === "Enter") {
      var user = JSON.parse(localStorage.getItem("accessToken"));
      const notification = th._notificationSystem.current;
      th.setState({ loader: true });
      var data = {
        search: th.state.search,
        userId: user.id,
        limit: 25,
      };
      var result = await apiHelper("post", `api/get/search/users`, data, null);
      th.setState({ loader: false });
      if (result.status === 200) {
        th.setState({
          addList: result.users,
        });
        // notification.addNotification({
        //     message: "Who Do You Know ?",
        //     level: "success",
        //     tr: "top , right",
        // });
      } else {
        notification.addNotification({
          message: "No Record Find",
          level: "warning",
          tr: "top , right",
        });
      }
    }
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // async addFriend(id, index, type) {
  //   var user = JSON.parse(localStorage.getItem("accessToken"));
  //   var data = {
  //     userId: user.id,
  //     friendId: id,
  //   };
  //   if (type === "list") {
  //     var list = this.state.addList;
  //     list.splice(index, 1);
  //     this.setState({ addList: list });
  //   } else {
  //     var list = this.state.searchList;
  //     list.splice(index, 1);
  //     this.setState({ searchList: list });
  //   }
  //   const notification = this._notificationSystem.current;
  //   var resultData = await apiHelper(
  //     "get",
  //     `api/friendLists?filter[where][friendId]=${id}&filter[where][userId]=${user.id}`,
  //     {},
  //     null
  //   );
  //   if (resultData.length == 0) {
  //     var result = await apiHelper("post", `api/friendLists`, data, null);
  //     if (result) {
  //       notification.addNotification({
  //         message: "Add friend Successfully",
  //         level: "success",
  //         tr: "top , right",
  //       });
  //     } else {
  //       notification.addNotification({
  //         message: "Something went wrong !",
  //         level: "warning",
  //         tr: "top , right",
  //       });
  //     }
  //   }
  // }



  async addFriend(id, index, type) {
    var user = JSON.parse(localStorage.getItem('accessToken'));
    var data = {
        userId: user.id,
        friendId: id
    }
    if (type === "list") {
        var list = this.state.addList;
        list.splice(index, 1);
        this.setState({ addList: list })
    } else {
        var list = this.state.searchList;
        list.splice(index, 1);
        this.setState({ searchList: list })
    }
    const notification = this._notificationSystem.current;
    let notifyCheck = 0;
    var resultData = await apiHelper("get", `api/friendLists?filter[where][friendId]=${id}&filter[where][userId]=${user.id}`, {}, null);
    
    if (resultData.length == 0) {
        var result = await apiHelper("post", `api/friendLists`, data, null);
        notifyCheck = 1;
        if (result) {
        } else {
            notification.addNotification({
                message: "Something went wrong !",
                level: "warning",
                tr: "top , right",
            });
        }
    }
    var user1 = JSON.parse(localStorage.getItem('accessToken'));
    var data1 = {
        userId: id,
        friendId: user1.id
    }
    var resultData = await apiHelper("get", `api/friendLists?filter[where][friendId]=${user1.id}&filter[where][userId]=${data1.userId}`, {}, null);
    if (resultData.length == 0) {
        var result = await apiHelper("post", `api/friendLists`, data1, null);
        if (result) {
        } else {
            notification.addNotification({
                message: "Something went wrong !",
                level: "warning",
                tr: "top , right",
            });
        }
    }
    
    if(notifyCheck === 1){
        const fina_obj = {
            userId: data.userId,
            type: "friendReq",
            friendId: data.friendId,
        };
        AddFriend(fina_obj);
    }

}

  imagePath = (avatar) => {
    var str = avatar;
    var n = str.includes("uploads/");
    if (n) {
      avatar = window.APIURL + avatar;
      return <img src={avatar} alt="profile picture" />;
    } else {
      return <img src={avatar} alt="profile picture" />;
    }
  };

  render() {
    var th = this;
    return (
      <div>
        <NotificationSystem ref={this._notificationSystem} />
        <main>
          <div class="main-wrapper welcome">
            <Header />
            <div class="container">
              <div class="row">
                <div class="users-list">
                  <h5 className="h3New">You’re all signed up...</h5>
                  <h1 className="h1New">
                    Welcome to <span className="text-primary">bake.</span> Now
                    let’s see who you know...
                  </h1>

                  <div class="mt-5 mb-4">
                    <div disabled="true" class="search-form d-flex">
                      <input
                        id="email"
                        name="search"
                        class="form-control share-text-field"
                        type="text"
                        placeholder="Search Your Friends"
                        value={this.state.search}
                        onChange={this.onChange}
                        onKeyPress={this.searchForUser}
                      />
                      <span
                        className="search-icon"
                        onClick={(e) => {
                          this.searchForUser(e);
                        }}
                      >
                        <img
                          src="images/search-icon.png"
                          alt="Search Icon"
                          className="img-fluid"
                        />
                      </span>
                    </div>
                  </div>

                  {this.state.addList.map(function (item, i) {
                    return (
                      <div key={i} class="card card-small">
                        <div class="share-box-inner">
                          <div class="profile-thumb">
                            <a href="#">
                              <figure class="profile-thumb-middle">
                                {th.imagePath(item.avatar)}
                              </figure>
                            </a>
                          </div>

                          <div
                            style={{
                              "text-align": "initial",
                              "margin-top": "0",
                              "padding-left":"15px",
                              "display":"flex",
                              "justifyContent":"space-between",
                              "alignItems": "center",
                            }}
                            class="share-content-box w-100"
                          >
                              <h6 class="author">
                                  {item.firstName} {item.lastName}
                                {/* <span>CTO and Co-Founder</span> */}
                               
                              </h6>
                              <button class="plus-btn ml-2 right" type="button" onClick={(e) => {
                                                                    th.addFriend(item.id, i, "list");
                                                                }} ><i class="fa fa-plus" aria-hidden="true"></i></button>
                              {/* <p>Favorite Movie: The Dark Knight, Favorite TV Show: Game of Thrones</p>                       */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {this.state.addList.length === 0 ? "No Record Found!" : ""}
                  <div class="lets-Go">
                    <div class="lets-inter">
                      <Link to="/showSelection" className="letgoAtag ">
                        {" "}
                        Continue{" "}
                      </Link>
                    </div>
                  </div>
                  <div class="lets-OR">
                    <div class="share-box-inner lets-inter-or">
                      <Link to="" className="letgoAtag">
                        {" "}
                        Skip, and go to feed{" "}
                      </Link>
                    </div>
                  </div>

                  {this.state.searchList.map(function (item, i) {
                    return (
                      <div key={i} class="card card-small">
                        <div class="share-box-inner">
                          <div class="profile-thumb">
                              <figure class="profile-thumb-middle">
                                {th.imagePath(item.avatar)}
                              </figure>
                          </div>

                          <div
                            style={{
                              "text-align": "initial",
                              "margin-top": "16px",
                            }}
                            class="share-content-box w-100"
                          >
                              <h6 class="author">
                                  {item.firstName} {item.lastName}
                                {/* <span>CTO and Co-Founder</span> */}
                              </h6>
                              {/* <p>Favorite Movie: The Dark Knight, Favorite TV Show: Game of Thrones</p> */}
                         
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </main>

        {this.state.loader === true ? (
          <div class="Loader">
            <img src={imageLoader} alt="profile picture" />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Footer;
