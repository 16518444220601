
import React, { Component } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import "./percentage.css";
import 'react-circular-progressbar/dist/styles.css';

export default class Welcome extends React.Component {
    render() {
      let per = (parseInt(this.props.per)/10)*100;
      return  <CircularProgressbar  value={per} text={`${per}%`} />;
    }
  }