import React, { Component } from "react";
import Notifications, { notify } from "react-notify-toast";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import "./edit.css";
import imageLoader from "../../Image/loader.gif";
import $ from "jquery";
window.jQuery = $;

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: "",
      loader: false,
      templatesArray: [],
      role: "",
      selectType: "About",
    };
  }
  componentDidMount() { }

  SelectSection = (selection) => {
    var th = this;
    if (selection === "Watched") {
      this.setState({ selectType: "Watched", loader: true });
      $("#1A").addClass("active");
      $("#2A").removeClass("active");
      $("#3A").removeClass("active");
      setTimeout(function () {
        th.setState({ loader: false });
      }, 3000);
    } else if (selection === "WatchWatchlisted") {
      this.setState({ selectType: "Watch List", loader: true });
      $("#2A").addClass("active");
      $("#1A").removeClass("active");
      $("#3A").removeClass("active");
      setTimeout(function () {
        th.setState({ loader: false });
      }, 3000);
    } else if (selection === "FriendSuggestion") {
      this.setState({ selectType: "Friend Suggestion", loader: true });
      $("#3A").addClass("active");
      $("#2A").removeClass("active");
      $("#1A").removeClass("active");
      setTimeout(function () {
        th.setState({ loader: false });
      }, 3000);
    }
  };

  render() {
    return (
      <main>
        <div class="main-wrapper">
          <div class="page-title-bg" >
            <div class="container">
              <div className="page-title">
                <h2>About Us</h2>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-lg-12" style={{ "margin-bottom": "26px" }}  >
                <div className="content">
                  <h3 >
                  bake.
                  </h3>
                  <h5>Great taste in every Byte.</h5>
                  <p>
                    Struggling to find a new show? Too many streaming services? Just want a trusted list of friend recommendations? Meet Bake. An interactive social media site created to solve the age old question “What show should I watch?” Bake offers a way for users to stay engaged with their favorite shows, track personal progress, and access a network of video entertainment. Structured as a social media site, this multiservice platform provides users with endless recommendations, video entertainment content, and social connectivity.
                  </p>
                </div>
              </div>
              {/* <div class="col-lg-3 order-3">
               
                </div> */}
            </div>
          </div>
        </div>

        {this.state.loader === true ? (
          <div class="Loader">
            <img src={imageLoader} alt="profile picture" />
          </div>
        ) : (
          ""
        )}
      </main>
    );
  }
}
