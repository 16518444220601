import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { apiHelper } from "../../Helper/apiHelper";
import imageLoader from '../../Image/loader.gif';
import AddTVClub from './AddTVClub';
import EditTvClub from './EditClub';
import Notification from '../notification/tvCLubNotification';
import CreateTvClubPost from './CreateTvClubPost';
import AddMessage from './Addmessage';
import NotificationSystem from "react-notification-system";
import PostListing from '../post/postListing';
import { socket } from "../notification/create-notification";
import Chat from '../Chat/chat';
import './tvclub.css';
import $ from 'jquery';

window.jQuery = $;
class TvClub extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: '',
            loader: false,
            role: "",
            selectType: true,
            tvModel: false,
            messageModel: false,
            editModel: false,
            tvClubData: [],
            messageData: [],
            myTvClub: [],
            notSelectedClub: [],
            SelectedClub: [],
            selectedTvClub: {},
            postModel: false,
            currentTvClub: {},
            TvClubId: '',
            search: '',
            tvClubCheck: false,
            chatCheck: false,
            userId: '',
            friendsList: [],
            currentChat: {}
        }
        this._notificationSystem = React.createRef();
    }
    componentDidMount() {
        $("body").removeClass("transparent-header");
        $("html, body").animate({ scrollTop: 0 }, "slow");
        var user = JSON.parse(localStorage.getItem('accessToken'));
        let userId = user.id;
        socket.on("new-message-status-output", data => {
            for (var i = 0; i < data.length; i++) {
                if (data[i].read == false && userId == data[i].receiverId) {
                    this.setState({
                        chatCheck: true
                    })
                    let friendsList = this.state.friendsList;
                    if (friendsList.length != 0) {
                        this.setUserFriendListNotification(friendsList);
                    }

                    return
                }
            }

        })
        socket.on("new-tv-club-notification-output", data => {
            for (var i = 0; i < data.length; i++) {
                if (data[i].read == false && userId == data[i].notifyUser) {
                    this.setState({
                        tvClubCheck: true
                    })
                    return
                }
            }

        })
        this.setState({ userId: user.id })

        let id = localStorage.getItem('tvClub')
        this.getTvClubeData();
        this.GetMyTvClub();
        this.getOldNotification();
        this.getOldChatNotification();
        this.SelectedClub();
        this.NotSelectedClub();
        if (id) {
            this.SelectClub(id);
        }
    }


    async getOldChatNotification() {
        const user = JSON.parse(localStorage.getItem("accessToken"));
        let userId = user.id;
        var data1 = await apiHelper(
            "get",
            `api/Chats?filter[where][receiverId]=${userId}`,
            null
        );
        for (var i = 0; i < data1.length; i++) {

            if (data1[i].read == false && userId == data1[i].receiverId) {
                this.setState({
                    chatCheck: true
                })
                return
            } else {
                this.setState({
                    chatCheck: false
                })
            }
        }
    }

    async getOldNotification() {
        const user = JSON.parse(localStorage.getItem("accessToken"));
        let userId = user.id;
        var data1 = await apiHelper(
            "get",
            `api/tvClubNotifications`,
            null
        );

        for (var i = 0; i < data1.length; i++) {

            if (data1[i].read == false && userId == data1[i].notifyUser) {
                this.setState({
                    tvClubCheck: true
                })
                return
            }
        }
    }

    componentWillReceiveProps(nextProps) {

       
        const urlParams = new URLSearchParams(nextProps.location.search);
        const myParam = urlParams.get('type');
        if (myParam == "chat") {
            this.SelectSection2();
        } else {
            this.SelectSection();
        }
        this.setState(nextProps);
    }

    async getUserFriend() {
        var user = JSON.parse(localStorage.getItem('accessToken'));
        const notification = this._notificationSystem.current;
        this.setState({ loader: true });
        var result = await apiHelper("get", `api/friendLists?filter[include]=friend&filter[where][userId]=${user.id}&filter[where][status]=true`, {}, null);
        this.setState({ loader: false });
        if (result.length > 0) {
            this.setState({
                friendsList: result
            })
            this.setUserFriendListNotification(result);
        } else {
            notification.addNotification({
                message: "No Record Find",
                level: "warning",
                tr: "top , right",
            });
        }
    }

    async setUserFriendListNotification(data1) {
        let friendsList = data1;
        const user = JSON.parse(localStorage.getItem("accessToken"));
        let userId = user.id;
        for (var i = 0; i < data1.length; i++) {

            let data = {
                "read": true,
                reciverId: userId,
                senderId: data1[i].friendId
            }
            var result = await apiHelper(
                "post",
                `api/friend/notification`,
                data
            );

            if (result.chat.length > 0) {
                data1[i].show = true
            } else {
                data1[i].show = false
            }
        }


        this.setState({
            friendsList: data1
        })
    }

    async getTvClubeData() {
        var user = JSON.parse(localStorage.getItem('accessToken'));
        const notification = this._notificationSystem.current;
        this.setState({ loader: true });
        var data = {
            search: this.state.search,
            TvClubId: this.state.TvClubId
        }
        var result = await apiHelper("post", `api/getLatest/tvCLub`, data, null);
        this.setState({ loader: false });
        if (result.status === 200) {
            this.setState({
                tvClubData: result.posts
            })
            // notification.addNotification({
            //     message: "",
            //     level: "success",
            //     tr: "top , right",
            // });
        } else {
            notification.addNotification({
                message: "No Record Find",
                level: "warning",
                tr: "top , right",
            });
        }
    }
    SelectSection = () => {
        var th = this;
        this.setState({ selectType: true, loader: true });
        $(".active11").addClass("active1")
        $(".active12").removeClass("active1")
        $("#1A").addClass(" active1");
        $("#2A").removeClass(" ractive1");
        this.getTvClubeData();

    }
    SelectSection2 = () => {
        var th = this;
        this.setState({ selectType: false, loader: true });
        $(".active12").addClass("active1")
        $(".active11").removeClass("active1")

        $(".active12").removeClass("myClass noClass").addClass("yourClass")
        $("#2A").addClass(" active1");
        $("#1A").removeClass(" active1");
        this.getUserFriend();
    }


    async NotSelectedClub() {
        var user = JSON.parse(localStorage.getItem('accessToken'));
        const notification = this._notificationSystem.current;
        this.setState({ loader: true });
        var data = {
            userId: user.id
        }
        var result = await apiHelper("post", `api/Notselected/tvClub`, data, null);
        this.setState({ loader: false });
        if (result.status === 200) {
            this.setState({
                notSelectedClub: result.tvClub
            })
        } else {
            notification.addNotification({
                message: "No Record Find",
                level: "warning",
                tr: "top , right",
            });
        }
    }

    async SelectedClub() {

        var user = JSON.parse(localStorage.getItem('accessToken'));
        const notification = this._notificationSystem.current;
        this.setState({ loader: true });
        var result = await apiHelper("get", `api/userTvClubs?filter[include]=tvClub&filter[where][userId]=${user.id}`, {}, null);
        this.setState({ loader: false });
        if (result) {
            this.setState({
                SelectedClub: result
            })
            // notification.addNotification({
            //     message: "",
            //     level: "success",
            //     tr: "top , right",
            // });
        } else {
            notification.addNotification({
                message: "No Record Find",
                level: "warning",
                tr: "top , right",
            });
        }
    }

    async GetMyTvClub() {
        var user = JSON.parse(localStorage.getItem('accessToken'));
        const notification = this._notificationSystem.current;
        this.setState({ loader: true });
        var result = await apiHelper("get", `api/tvClubs?filter[where][userId]=${user.id}`, {}, null);
        this.setState({ loader: false });
        if (result) {
            this.setState({
                myTvClub: result
            })
        } else {
            notification.addNotification({
                message: "No Record Find",
                level: "warning",
                tr: "top , right",
            });
        }
    }

    async getMessage() {
        var user = JSON.parse(localStorage.getItem('accessToken'));
        const notification = this._notificationSystem.current;
        this.setState({ loader: true });
        var data = {
            search: this.state.search
        }
        var result = await apiHelper("post", `api/getLatest/message`, data, null);
        this.setState({ loader: false });
        if (result.posts) {
            this.setState({
                messageData: result.posts
            })
            // notification.addNotification({
            //     message: "",
            //     level: "success",
            //     tr: "top , right",
            // });
        } else {
            notification.addNotification({
                message: "No Record Find",
                level: "warning",
                tr: "top , right",
            });
        }
    }


    async SeatTvClub() {
        var user = JSON.parse(localStorage.getItem('accessToken'));
        const notification = this._notificationSystem.current;
        this.setState({ loader: true });

        var result = await apiHelper("get", `api/tvClubs?filter[where][id]=${this.state.TvClubId}`, {}, null);
        this.setState({ loader: false });
        if (result) {
            this.setState({
                selectedTvClub: result[0]
            })
            // notification.addNotification({
            //     message: "",
            //     level: "success",
            //     tr: "top , right",
            // });
        } else {
            notification.addNotification({
                message: "No Record Find",
                level: "warning",
                tr: "top , right",
            });
        }
    }

    postClose = (e) => {
        this.setState({ postModel: false });
        this.SelectClub(this.state.TvClubId);
    }
    postOpen = (e) => {

        this.setState({ postModel: true });
    }

    EditTvClub = (e) => {
        this.setState({ editModel: true });

    }

    EditTvClubClose = (e) => {

        this.setState({ editModel: false });
        this.GetMyTvClub();
        this.SelectClub(this.state.TvClubId);
    }

    AddTvClose = (e) => {
        this.setState({ tvModel: false });
        this.componentDidMount();
    }

    AddTvOpen = (e) => {
        this.setState({ tvModel: true });
    }

    AddMessageClose = (e) => {
        this.setState({ messageModel: false });
        this.componentDidMount();
    }

    AddMessageOpen = (e) => {
        this.setState({ messageModel: true });
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    SelectClub = (id) => {
        var th = this;
        localStorage.setItem("tvClub", id);
        this.setState({ TvClubId: id, selectedTvClub: {} }, () => {
            this.SeatTvClub();
            this.getTvClubeData();
        })
    };

    AddClub = async (id) => {
        var user = JSON.parse(localStorage.getItem('accessToken'));
        const notification = this._notificationSystem.current;
        this.setState({ loader: true });
        var data = {
            userId: user.id,
            tvClubId: id
        }
        var result = await apiHelper("post", `api/userTvClubs`, data, null);
        this.setState({ loader: false });
        if (result) {
            this.componentDidMount();

        } else {
            notification.addNotification({
                message: "No Record Find",
                level: "warning",
                tr: "top , right",
            });
        }
    };






    async setChatUser(value) {
        this.setState({ currentChat: value });
        const user = JSON.parse(localStorage.getItem("accessToken"));
        let userId = user.id;
        let data = {
            "read": true,
            userId: userId,
            value: value
        }
        var data1 = await apiHelper(
            "post",
            `api/chatRead/update`,
            data
        );
        this.getOldChatNotification();
        this.setUserFriendListNotification(this.state.friendsList);

    }

    imageUrl = (avatar) => {
        var str = avatar;
        var n = str.includes("uploads/");
        if (n) {
            avatar = window.APIURL + avatar;
            return avatar
        } else {
            return avatar
        }
    }

    TvClubNotification = () => {
        this.setState({
            tvClubCheck: false
        })
    }

    render() {
        var th = this;
        var user = JSON.parse(localStorage.getItem('accessToken'));
        if (user) {
            var avatar = user.avatar;
            var str = avatar;
            var n = str.includes("uploads/");
            if (n) {
                avatar = window.APIURL + avatar;
            }
        } else {
            return <Redirect to="/login" />;
        }

        return (

            <main>
                {this.state.tvClubId}
                {this.state.postModel ?
                    <CreateTvClubPost PostClose={this.postClose} tvClubId={this.state.selectedTvClub} />
                    : ""}
                <NotificationSystem ref={this._notificationSystem} />
                <div class="main-wrapper pt-80">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 order-2 order-lg-1">
                                <div class="sidebar"> {/*<!--widget-area club_sidebar-->*/}
                                    {this.state.selectType ? <>
                                        <h2 class="sidebar-title">Owner <button class="plus-btn mr-2 left" onClick={(e) => this.AddTvOpen(e)} type="button"><i class="fa fa-plus" aria-hidden="true"></i></button></h2>

                                        <div>


                                            {this.state.myTvClub.map(function (item, i) {
                                                return (

                                                    <div class="media bg-white align-items-center">
                                                        <figure class="profile-thumb-middle mr-2 ">
                                                            <img src={th.imageUrl(item.avatar)} alt="" />
                                                        </figure>
                                                        <div class="media-body" style={{ "text-align": "right" }}>
                                                            <h6 class="author mr-2 pt-2" style={{ "display": "inline-block", "float": "left" }}><a >{item.title} </a></h6>
                                                            <button class="btn-share" type="button" onClick={(e) => th.SelectClub(item.id)}  >Select</button>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                        <hr />
                                        <h2 class="sidebar-title">Member</h2>
                                        <div className="club-you-have custom-scrollbar">
                                            {this.state.SelectedClub.map(function (item, i) {
                                                return (
                                                    <div class="media bg-white align-items-center">
                                                        <figure class="profile-thumb-middle mr-2 ">
                                                            <img src={th.imageUrl(item.tvClub.avatar)} alt="" />
                                                        </figure>
                                                        <div class="media-body" style={{ "text-align": "right" }}>
                                                            <h6 class="author mr-2 pt-2" style={{ "display": "inline-block", "float": "left" }}><a >{item.tvClub.title}</a></h6>

                                                            <button class="btn-share" type="button" onClick={(e) => th.SelectClub(item.tvClub.id)}  >Select</button>

                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <hr />
                                        
                                        <h2 class="sidebar-title">New Clubs</h2>

                                        <div className="new-club-wrap custom-scrollbar">
                                            {this.state.notSelectedClub.map(function (item, i) {
                                                return (
                                                    <div class="media bg-white align-items-center">
                                                        <figure class="profile-thumb-middle mr-2 ">
                                                            <img src={th.imageUrl(item.avatar)} alt="" />
                                                        </figure>
                                                        <div class="media-body" style={{ "text-align": "right" }}>
                                                            <h6 class="author mr-2 pt-2" style={{ "display": "inline-block", "float": "left" }}><a >{item.title} </a></h6>
                                                            <button class="plus-btn" type="button" onClick={(e) => th.AddClub(item.id)}><i class="fa fa-plus" aria-hidden="true"></i></button>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                    </> :
                                        <div class="card widget-item">
                                            <h4 class="widget-title">Friends</h4>
                                            <div class="widget-body custom-scrollbar">
                                                <ul class="like-page-list-wrapper">
                                                    {this.state.friendsList.map(function (item, i) {
                                                        return (
                                                            <li key={i} onClick={(e) => th.setChatUser(item)} class="unorder-list" key={i}>

                                                                <div onClick={(e) => th.setChatUser(item)} class="profile-thumb">
                                                                    <a >
                                                                        <figure onClick={(e) => th.setChatUser(item)} class="profile-thumb-small">
                                                                            <img src={th.imageUrl(item.friend.avatar)} alt="profile picture" />
                                                                        </figure>
                                                                    </a>
                                                                </div>


                                                                <div onClick={(e) => th.setChatUser(item)} class="unorder-list-info">
                                                                    <h3 class="list-title" onClick={(e) => th.setChatUser(item)}><a onClick={(e) => th.setChatUser(item)} >{item.friend.firstName} {item.friend.lastName}

                                                                        {item.show ? <span class="badge badge-danger"> </span> : ""}
                                                                    </a></h3>
                                                                </div>
                                                                <button class="like-button active">
                                                                </button>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>

                                    }
                                </div>
                            </div>

                            <div class="col-lg-6 order-1 order-lg-2">
                                <div>
                                    <div class="tvclub-icon">
                                        <span style={{ "margin-right": "15px" }} onClick={(e) => this.SelectSection()}>
                                            <a><i id="1A" class="fa fa-television fav active11 active1" ></i>{this.state.tvClubCheck ? <span class="badge badge-danger"> </span> : ""} </a>
                                        </span>
                                        <span onClick={(e) => this.SelectSection2()}>
                                            <a><i id="2A" class="fa fa-commenting-o fav active12 " ></i>  {this.state.chatCheck ? <span class="badge badge-danger"> </span> : ""}   </a>
                                        </span>
                                    </div>

                                    {this.state.selectType ?
                                        this.state.TvClubId ?
                                            <div>
                                                <div class="card card-small">
                                                    {this.state.selectedTvClub.title ?
                                                        <div class="media bg-white  p-0 align-items-center">
                                                            <figure class="profile-thumb-middle mr-2 ">
                                                                <img src={th.imageUrl(this.state.selectedTvClub.avatar)} alt="profile picture" />
                                                            </figure>
                                                            <div class="media-body d-flex align-items-center justify-content-between" style={{ "text-align": "right", "     margin-top": "5px" }}>
                                                                <p class="mr-2 pt-2" style={{ "display": "inline-block", "float": "left" }}><a >{this.state.selectedTvClub.title}</a></p>

                                                                {this.state.userId == this.state.selectedTvClub.userId ?
                                                                    <button class="" onClick={(e) => this.EditTvClub(e)} type="button"><span className="fa fa-edit"></span></button>
                                                                    : ""}
                                                            </div>
                                                        </div>
                                                        : ""}

                                                </div>

                                                <div class="card card-small">
                                                    <div class="share-box-inner">
                                                        <div class="share-content-box w-100 find-club-with-search">
                                                            <form class="share-text-box">
                                                                <textarea name="share" onClick={(e) => this.postOpen(e)} class="share-text-field" placeholder="Create Post" ></textarea>
                                                                <button class="btn-share" onClick={(e) => this.postOpen(e)} type="button">Create Post</button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <PostListing postList={this.state.tvClubData} />
                                            </div> : <p class="author"> Select Group</p>
                                        :
                                        <Chat currentChat={this.state.currentChat} ></Chat>
                                    }

                                </div>
                            </div>
                            {this.state.selectType ?
                                <div class="col-lg-3 order-3 mobileNotif">
                                    <Notification TvClubNotification={this.TvClubNotification}></Notification>
                                </div> : ""}
                        </div>
                    </div>
                </div>
                {this.state.editModel ? <EditTvClub AddTvClose={this.EditTvClubClose} selectedTvClub={this.state.selectedTvClub} /> : ""}
                {this.state.tvModel ? <AddTVClub AddTvClose={this.AddTvClose} /> : ""}
                {this.state.messageModel ? <AddMessage AddMessageClose={this.AddMessageClose} /> : ""}
                {this.state.loader === true ?
                    <div class="Loader">
                        <img src={imageLoader} alt="profile picture" />
                    </div> : ""
                }
            </main>
        );
    }
}

export default TvClub;
