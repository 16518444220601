import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import NotificationSystem from "react-notification-system";
import imageLoader from '../../Image/loader.gif';
import { socket } from "../notification/create-notification";

import $ from 'jquery';
var moment = require('moment');

window.jQuery = $;
class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: '',
            loader: false,
            receiverId: "",
            senderId: "",
            userName: '',
            avatar: '',
            message: '',
            chatArray: [],
        }
        this._notificationSystem = React.createRef();
    }
    componentDidMount() {
        var endPoint = window.APPURL;
        socket.on("output", data => {
           
            this.setState({ chatArray: data })
            var element = document.getElementById("yourDivID");
            element.scrollTop = element.scrollHeight;
        })

        socket.on("output-new", data => {
           
            var chatArray = this.state.chatArray;
            if(data.receiverId === this.state.senderId && data.senderId === this.state.receiverId ){
                chatArray.push(data);
                this.setState({ chatArray: chatArray })
            }
            if(data.receiverId === this.state.receiverId && data.senderId === this.state.senderId){
                chatArray.push(data);
                this.setState({ chatArray: chatArray })
            }
            var element = document.getElementById("yourDivID");
           element.scrollTop = element.scrollHeight;
        })
    }

    componentWillReceiveProps() {
        var endPoint = window.APPURL;
        var currentUser = this.props.currentChat;
        if (Object.keys(currentUser).length !== 0) {
            
            this.setState({
                receiverId: currentUser.friendId,
                senderId: currentUser.userId,
                userName: currentUser.friend.firstName ,
                avatar: currentUser.friend.avatar
            })
            var data = {
                receiverId: currentUser.friendId,
                senderId: currentUser.userId
            }
           
            socket.emit("old-messages", data );
        }
        var element = document.getElementById("yourDivID");
        element.scrollTop = element.scrollHeight;
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    sendMessage = (e) => {
        const notification = this._notificationSystem.current;
        if (this.state.receiverId !== '') {
            if (this.state.message === "") {
                notification.addNotification({
                    message: "Type Message!",
                    level: "warning",
                    tr: "top , right",
                });
                return
            } else {

                var data = {
                    receiverId: this.state.receiverId,
                    senderId: this.state.senderId,
                    timestamp: new Date(),
                    message: this.state.message
                }
                this.setState({ message :''})
               
                socket.emit('new-message', data);
                socket.emit('new-message-status', data);
            }

        } else {
            notification.addNotification({
                message: "Select Friend",
                level: "warning",
                tr: "top , right",
            });
        }
    }


    imageUrl = (avatar) => {
        var str = avatar;
        var n = str.includes("uploads/");
        if (n) {
            avatar = window.APIURL + avatar;
            return avatar
        } else {
            return avatar
        }
    }
    dateCheck = (date) => {
        date = moment(date).fromNow();
        return date
    }




    onChangeType = (event) => {
        if (event.key === 'Enter') {
            this.setState({
                    [event.target.name]: event.target.value,
            }, () => {  
                this.sendMessage(event);                 
            })
        }
    };

    render() {
        var th = this;
        var user = JSON.parse(localStorage.getItem('accessToken'));
        if (user) {
            var firstName = user.firstName ? user.firstName : "" + user.lastName ? user.lastName : "";
            var description = user.description ? user.description : ""
            var avatar = user.avatar;
            var str = avatar;
            var n = str.includes("uploads/");
            if (n) {
                avatar = window.APIURL + avatar;
            }
        } else {
            return <Redirect to="/login" />;
        }



        return (

            <main>

                <NotificationSystem ref={this._notificationSystem} />
                <div class=" show live_chating">
                    <div class="live-chat-title">
                        {/* <!-- profile picture end --> */}
                        <div class="profile-thumb active">
                            <a href={`#/friend/${this.state.receiverId}`}>
                                <figure class="profile-thumb-small">
                                  {this.state.avatar !== "" ? 
                                  <img src={th.imageUrl(this.state.avatar)} alt="profile picture" />
                                   : ""}
                                  </figure>
                            </a>
                        </div>
                        <div class="posted-author">
                          {this.state.userName !== "" ? 
                          <h6 class="author"><a  href={`#/friend/${this.state.receiverId}`}>{this.state.userName}</a></h6> : ""}
                          
                        </div>
                       
                    </div>
                    <div class="message-list-inner">
                        <ul class="message-list custom-scroll" id="yourDivID">
                            {this.state.chatArray.map(function (item, i) {
                                return (
                                    <div key={i}>

                                        {th.state.senderId == item.senderId ?
                                           <li class="text-author">
                                           <p>{item.message}</p>
                                           <div class="message-time">{th.dateCheck(item.timestamp)}</div>
                                       </li>:  th.state.receiverId == item.senderId ?
                                            <li class="text-friends">
                                             <p>{item.message}</p>
                                            <div class="message-time">{th.dateCheck(item.timestamp)}</div>
                                        </li> : ''
                                            }
                                        {/* {th.state.receiverId == item.receiverId  ?
                                            <li class="text-author">
                                                <p>Many desktop publishing packages and web page editors</p>
                                                <div class="message-time">{th.dateCheck(item.timestamp)}</div>
                                            </li> : null} */}
                                    </div>
                                )
                            })}
                        </ul>
                        <div class="message-reply">
                            <input

                                type="text"
                                placeholder="Your Message"
                                name="message"
                                value={this.state.message}
                                onChange={this.onChange}
                                onKeyPress={this.onChangeType}
                            />
                            <button class="button" onClick={(e) => this.sendMessage(e)}>Send Message</button></div>
                    </div>
                </div>
                {this.state.loader === true ?
                    <div class="Loader">
                        <img src={imageLoader} alt="profile picture" />
                    </div> : ""
                }
            </main>
        );
    }
}

export default Search;
