import React, { Component } from "react";
import imageLoader from "../Image/loader.gif";
import { Redirect } from "react-router-dom";
import { apiHelper } from "./../Helper/apiHelper";
import NotificationSystem from "react-notification-system";
import PostModel from "./postMOdel";
import Post from "../DashBoard/post/postListing";
import ShowMoviesPost from "../DashBoard/post/showMovies";
import Notification from "./Notification";
import $ from "jquery";
import Genres from "./geners/Geners";
import "./home.css";

var moment = require("moment");
window.jQuery = $;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainArray: [],
      postList: [],
      movieList: [],
      showList: [],

      postCheck: 0,

      commentCount: 0,
      postModel: false,
      genresType: "home",
      genresCheck: false,
      apiType: "both",

      currentChat: "home",

      postCount: 0,
      showCount: 0,
      movieCount: 0,

      postLimit: 0,
      showLimit: 0,
      movieLimit: 0,

      scroll: 0,
    };
    this._notificationSystem = React.createRef();
  }
  async componentDidMount() {
    $("body").removeClass("transparent-header");
    $("html, body").animate({ scrollTop: 0 }, "slow");
    // show  movie
    this.post(10);
    this.postType("show");
    this.postType("movie");
    // this.getGenresData("");
    const user = JSON.parse(localStorage.getItem("accessToken"));
    if (user) {
      var result = await apiHelper(
        "get",
        `api/WebUsers?filter[where][id]=${user.id}`,
        null
      );
      if (result.length == 0) {
        this.setState({ redirect: true });
      }
    }
  }

  async getGenresData(genres) {
    var th = this;
    var user = JSON.parse(localStorage.getItem("accessToken"));
    const notification = th._notificationSystem.current;
    th.setState({ loader: true, mainArray: [] });
    var data = {
      genres: genres,
    };
    this.closeGeners();
    var result = await apiHelper("post", `api/home/genres/show`, data, null);

    this.setState({ loader: false });
    if (result.status === 200) {
      if (result.posts.length !== 0) {
        this.setState({
          mainArray: result.posts,
        });
      }
      this.closeGeners();
    } else {
      notification.addNotification({
        message: "No Record Find",
        level: "warning",
        tr: "top , right",
      });
    }
  }

  //User Post Query
  async post(limit, checkLatest = "") {
    var { postLimit, postCheck, mainArray, postCount, scroll } = this.state;
    postLimit = limit + postLimit;

    if (scroll == 0) {
      var hi = 0;
    } else {
      var hi = $(window).height();
    }

    if (limit == 20) {
      postLimit = limit + 0;
      mainArray = [];
    }

    const notification = this._notificationSystem.current;
    this.setState({ loader: true, currentChat: "type" });
    var data = {
      limit: limit,
      skip: postLimit - limit,
    };
    var result = await apiHelper("post", `api/getLatest/Post`, data, null);
    if (scroll != 0 && checkLatest == "")
      $("html, body").animate({ scrollTop: hi }, "slow");
    if (checkLatest != "") $("html, body").animate({ scrollTop: 0 }, "slow");
    this.setState({ loader: false });
    if (result.posts) {
      if (postCheck == 0) {
        $("body").removeClass("transparent-header");
        $("html, body").animate({ scrollTop: 0 }, "slow");
      }

      let arr = result.posts;
      for (var i = 0; arr.length > i; i++) {
        mainArray.push(arr[i]);
      }

      this.setState({
        mainArray: mainArray,
        postLimit: postLimit,
        postCheck: 1,
      });
    } else {
      notification.addNotification({
        message: "No Record Find",
        level: "warning",
        tr: "top , right",
      });
    }

    var result1 = await apiHelper(
      "post",
      `api/getLatest/Post/count`,
      data,
      null
    );
    this.setState({
      postCount: result1.count,
    });
  }

  async postType(type) {
    // show  movie
    var { showLimit, movieLimit, mainArray, scroll } = this.state;
    showLimit = 10 + showLimit;
    movieLimit = 10 + movieLimit;

    if (scroll == 0) {
      var hi = 0;
    } else {
      var hi = $(window).height();
    }

    const notification = this._notificationSystem.current;
    this.setState({ loader: true });
    if (type == "movie") {
      var data = {
        limit: 10,
        skip: movieLimit - 10,
        type: type,
      };
    } else {
      var data = {
        limit: 10,
        skip: showLimit - 10,
        type: type,
      };
    }

    var result = await apiHelper("post", `api/get/movie-show`, data, null);

    if (scroll != 0) $("html, body").animate({ scrollTop: hi }, "slow");

    this.setState({ loader: false });
    if (result.posts) {
      let arr = result.posts;
      for (var i = 0; arr.length > i; i++) {
        mainArray.push(arr[i]);
      }

      if (type == "movie") {
        this.setState({
          scroll: 1,
          mainArray: mainArray,
          movieLimit: movieLimit,
        });
      } else {
        this.setState({
          mainArray: mainArray,
          showLimit: showLimit,
        });
      }
    } else {
      notification.addNotification({
        message: "No Record Find",
        level: "warning",
        tr: "top , right",
      });
    }

    var result1 = await apiHelper(
      "post",
      `api/get/movie-show/count`,
      data,
      null
    );
    if (type == "movie") {
      this.setState({
        movieCount: result1.count,
      });
    } else {
      this.setState({
        showCount: result1.count,
      });
    }
  }

  postClose = (e) => {
    this.setState(
      { postModel: false, mainArray: [], postLimit: 0, scroll: 0 },
      () => {
        this.post(10, "");
      }
    );
  };
  postOpen = (e) => {
    this.setState({ postModel: true });
  };

  openGeners() {
    this.setState({
      genresCheck: true,
      apiType: "show",
    });
  }
  closeGeners(e) {
    //this is just getting the value that is selected
    this.setState({
      genresCheck: false,
    });
  }

  imageUrl = (avatar) => {
    var str = avatar;
    var n = str.includes("uploads/");
    if (n) {
      avatar = window.APIURL + avatar;
      return avatar;
    } else {
      return avatar;
    }
  };

  dateCheck = (date) => {
    date = moment(date).fromNow();
    return date;
  };

  async searchForUser(value) {
    this.setState({
      apiType: value,
    });
    if (value === "post") {
      this.post();
    } else if (value === "friPost") {
      this.friendPost();
    } else if (value === "treShow") {
      // this.post(value);
    }
  }

  async friendPost() {
    var user = JSON.parse(localStorage.getItem("accessToken"));
    const notification = this._notificationSystem.current;
    this.setState({ loader: true, mainArray: [] });
    var data = {
      userId: user.id,
    };
    var result = await apiHelper("post", `api/get/firendPost`, data, null);
    this.setState({ loader: false });
    if (result.posts) {
      this.setState({
        mainArray: result.posts,
      });
      // notification.addNotification({
      // 	message: "Select shows you have seen!",
      // 	level: "success",
      // 	tr: "top , right",
      // });
    } else {
      notification.addNotification({
        message: "No Record Find",
        level: "warning",
        tr: "top , right",
      });
    }
  }

  async trendingShow() {
    var user = JSON.parse(localStorage.getItem("accessToken"));
    const notification = this._notificationSystem.current;
    this.setState({ loader: true, mainArray: [] });
    var data = {
      userId: user.id,
    };
    var result = await apiHelper("post", `api/get/trendingShow`, data, null);
    this.setState({ loader: false });
    if (result.posts) {
      this.setState({
        mainArray: result.posts,
      });
      // notification.addNotification({
      // 	message: "Select shows you have seen!",
      // 	level: "success",
      // 	tr: "top , right",
      // });
    } else {
      notification.addNotification({
        message: "No Record Find",
        level: "warning",
        tr: "top , right",
      });
    }
  }

  removeIndex = (index) => {
    const array = [...this.state.mainArray];
    array.splice(index, 1);
    this.setState({ mainArray: array });
  };

  render() {
    var th = this;
    var user = JSON.parse(localStorage.getItem("accessToken"));
    if (user) {
      var firstName = user.firstName
        ? user.firstName
        : "" + user.lastName
        ? user.lastName
        : "";
      var description = user.description ? user.description : "";
      var avatar = user.avatar;
      var str = avatar;
      var n = str.includes("uploads/");
      if (n) {
        avatar = window.APIURL + avatar;
      }
    } else {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <main>
          <NotificationSystem ref={this._notificationSystem} />
          <div class="main-wrapper pt-80">
            <div class="container">
              <div class="row">
                <div class="col-lg-3 order-1 order-lg-1">
                  {/* <div className="sidebar">
										<h2 className="sidebar-title">
											TV Clubs <a className="view-all">View All</a>
										</h2>
										<div className="item">
											<div>
												<h3>Tarantino Fans</h3>
												<p>2 New Posts</p>
											</div>
											
										</div>
										<div className="item">
											<div>
												<h3>Tarantino Fans</h3>
												<p>2 New Posts</p>
											</div>
											
										</div>
										<div className="item">
											<div>
												<h3>Tarantino Fans</h3>
												<p>2 New Posts</p>
											</div>
											
										</div>
										<hr/>
										<h2 className="sidebar-title">
										Recommended Clubs
										</h2>
										<div className="item">
											<div>
												<h3>Tarantino Fans</h3>
												<p>2 New Posts</p>
											</div>
											<div>
												<a className="add-icon">+</a>
											</div>
										</div>
										<div className="item">
											<div>
												<h3>Tarantino Fans</h3>
												<p>2 New Posts</p>
											</div>
											<div>
												<a className="add-icon">+</a>
											</div>
										</div>
										<div className="item">
											<div>
												<h3>Tarantino Fans</h3>
												<p>2 New Posts</p>
											</div>
											<div>
												<a className="add-icon">+</a>
											</div>
										</div>
									</div> */}
                  <aside class="widget-area">
                    <div class="card card-profile widget-item p-0">
                      <div class="profile-banner">
                        <figure class="profile-banner-small">
                          <a>
                            <img
                              src="assets/images/banner/banner-small.jpg"
                              alt=""
                            />
                          </a>
                          <a href="#/editProfile" class="profile-thumb-2">
                            <img src={`${avatar}`} alt="" />
                          </a>
                        </figure>
                        <div class="profile-desc text-center">
                          <h6 class="author">
                            <a>{`${firstName}`}</a>
                          </h6>
                          <p>{`${description}`}</p>
                        </div>
                      </div>
                    </div>
                    <div class="card card-profile widget-item p-0 hideOn_sm">
                      <div class="share-box-inner box-custom">
                        <div
                          class="share-content-box box-content-custom"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            type="button"
                            class="post-share-btn"
                            onClick={() => {
                              this.post(20, "checkLatest");
                            }}
                          >
                            Newest Posts
                          </button>
                        </div>

                        <div
                          class="share-content-box box-content-custom"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            type="button"
                            class="post-share-btn"
                            onClick={() => {
                              this.friendPost();
                            }}
                          >
                            Friends Posts
                          </button>
                        </div>
                        <div
                          class="share-content-box box-content-custom"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            type="button"
                            class="post-share-btn"
                            onClick={() => {
                              this.trendingShow("treShow");
                            }}
                          >
                            Bake Recs
                          </button>
                        </div>
                        <div
                          class="share-content-box box-content-custom"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            type="button"
                            onClick={(e) => this.openGeners()}
                            class="post-share-btn"
                          >
                            Genres
                          </button>
                        </div>
                      </div>
                    </div>

                  </aside>
 
                  

                </div>

                <div class="col-lg-6 order-2 order-lg-2">
                  <div class="card card-profile widget-item p-0 showOn_sm">
                    <div class="share-box-inner box-custom">
                      <div
                        class="share-content-box box-content-custom"
                        style={{ textAlign: "center" }}
                      >
                        <button
                          type="button"
                          onClick={() => {
                            this.searchForUser("post");
                          }}
                          class="post-share-btn"
                          data-dismiss="modal"
                        >
                          Newest Posts
                        </button>
                      </div>

                      <div
                        class="share-content-box box-content-custom"
                        style={{ textAlign: "center" }}
                      >
                        <button
                          type="button"
                          class="post-share-btn"
                          onClick={() => {
                            this.searchForUser("friPost");
                          }}
                          data-dismiss="modal"
                        >
                          Friends Posts
                        </button>
                      </div>
                      <div
                        class="share-content-box box-content-custom"
                        style={{ textAlign: "center" }}
                      >
                        <button
                          type="button"
                          class="post-share-btn"
                          onClick={() => {
                            this.trendingShow("treShow");
                          }}
                          data-dismiss="modal"
                        >
                          Bake Recs
                        </button>
                      </div>
                      <div
                        class="share-content-box box-content-custom"
                        style={{ textAlign: "center" }}
                      >
                        <button
                          type="button"
                          onClick={(e) => this.openGeners()}
                          class="post-share-btn"
                        >
                          Genres
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card card-small">
                    <div class="share-box-inner">
                      <div class="profile-thumb">
                        <a href="#">
                          <figure class="profile-thumb-middle">
                            <img src={`${avatar}`} alt="profile picture" />
                          </figure>
                        </a>
                      </div>
                      <div class="share-content-box w-100">
                        <form class="share-text-box">
                          <input
                            name="share"
                            onClick={(e) => th.postOpen(e)}
                            class="share-text-field"
                            placeholder="Everybody’s a Critic"
                          />
                          <button
                            class="btn-share"
                            onClick={(e) => th.postOpen(e)}
                            type="button"
                          >
                            Post
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>

                  {/* {this.state.postList.length > 0 ?       
									<Post postList={this.state.postList} /> : ""
	                                } */}
                  {this.state.mainArray.length > 0 ? (
                    <ShowMoviesPost
                      postList={this.state.mainArray}
                      removeIndex={this.removeIndex}
                    />
                  ) : (
                    ""
                  )}
                  {/* {this.state.movieList.length > 0 ?  
										<ShowMoviesPost postList={this.state.movieList} />: ""
									} */}
                  {/* {this.state.apiType === "friPost" ?
										<Post postList={this.state.postList} />
										: ""}
									{this.state.apiType === "post" ?
										<Post postList={this.state.postList} />
										: ""}
									{this.state.apiType === "show" ?
										<ShowMoviesPost postList={this.state.showList} />
										: ""
									} */}

                  <div
                    class="share-content-box box-content-custom d-flex justify-content-between"
                    style={{ textAlign: "center" }}
                  >
                    {this.state.postCount > this.state.postLimit ? (
                      <button
                        type="button"
                        onClick={() => {
                          this.post(10);
                        }}
                        class="post-share-btn"
                      >
                        {" "}
                        More Post{" "}
                      </button>
                    ) : (
                      ""
                    )}

                    {this.state.showCount > this.state.showLimit ? (
                      <button
                        type="button"
                        onClick={() => {
                          this.postType("show");
                        }}
                        class="post-share-btn"
                      >
                        More Show
                      </button>
                    ) : (
                      ""
                    )}

                    {this.state.movieCount > this.state.movieLimit ? (
                      <button
                        type="button"
                        onClick={() => {
                          this.postType("movie");
                        }}
                        class="post-share-btn"
                      >
                        More Movies
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div class="col-lg-3 order-3 mobileNotif">
                  {/* <div className="sidebar friends-list">
										<h2 className="sidebar-title">
										Friend Activity
										</h2>
										<div className="item">
											<div className="image">
												<img src={UserImage} alt="" className="img-fluid"/>
											</div>
											<div>
												<h3>Jake Belitsky</h3>
												<p>Game Of Thrones</p>
												<p className="sub-text">Added Movie/Show</p>
											</div>
											
										</div>
										<div className="item">
											<div className="image">
												<img src={UserImage} alt="" className="img-fluid"/>
											</div>
											<div>
												<h3>Jake Belitsky</h3>
												<p>Game Of Thrones</p>
												<p className="sub-text">Added Movie/Show</p>
											</div>
											
										</div>
										<div className="item">
											<div className="image">
												<img src={UserImage} alt="" className="img-fluid"/>
											</div>
											<div>
												<h3>Jake Belitsky</h3>
												<p>Game Of Thrones</p>
												<p className="sub-text">Added Movie/Show</p>
											</div>
											
										</div>
										<div className="item">
											<div className="image">
												<img src={UserImage} alt="" className="img-fluid"/>
											</div>
											<div>
												<h3>Jake Belitsky</h3>
												<p>Game Of Thrones</p>
												<p className="sub-text">Added Movie/Show</p>
											</div>
											
										</div>
									</div> */}
                  <Notification
                    currentChat={this.state.currentChat}
                  ></Notification>
                </div>
              </div>
            </div>
          </div>
        </main>

        {this.state.genresCheck ? (
          <Genres
            genresType={this.state.genresType}
            getGenresData={(e) => this.getGenresData(e)}
            closeGeners={(e) => this.closeGeners(e)}
          ></Genres>
        ) : (
          ""
        )}
        {this.state.postModel ? <PostModel postClose={this.postClose} /> : ""}

        {this.state.loader === true ? (
          <div class="Loader">
            <img src={imageLoader} alt="profile picture" />
          </div>
        ) : (
          ""
        )}
        <div></div>
      </div>
    );
  }
}

export default Home;
