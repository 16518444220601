import React, { Component } from 'react';
import Header from '../staticPages/Header';
import Body from './body';
import { socket } from "./notification/create-notification";
import $ from 'jquery';
window.jQuery = $;

class Main extends Component {

  async componentDidMount() {
    
      socket.on("new-tv-club-notification-output", data => {
         
      })
  
  }

  render() {
    return (
      <div id="wrapper" >
        <Header/>
        <Body/>
      </div>
    );
  }
}

export default Main;