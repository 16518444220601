import React, { Component } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import "./edit.css";
import { Link, Redirect } from "react-router-dom";
import { apiHelper, apiMultipart } from "../../Helper/apiHelper";
import NotificationSystem from "react-notification-system";
import $ from "jquery";
window.jQuery = $;
class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.options = countryList().getData();
    this.state = {
      firstName: "",
      lastName: "",
      description: "",
      gender: "",
      age: "",
      country: "",
      email:"",
      password: "",
      confirmPassword: "",
      countryCode: "",
      options: this.options,
      value: null,
      redirect: false,
      userId: "",
      avatar: "",
      favShow: "",
      favMovie:""

    };
    this._notificationSystem = React.createRef();
  }
 
  async componentDidMount() {
    $("body").removeClass("transparent-header");
    $("html, body").animate({ scrollTop: 0 }, "slow");
    const user = JSON.parse(localStorage.getItem("accessToken"));

    if (user) {
      var result = await apiHelper(
        "get",
        `api/WebUsers?filter[where][id]=${user.id}`,
        null
      );
      if (result.length > 0) {
        let user = result[0];
        var data = { value: user.countryCode, label: user.country };
        let avatar = user.avatar;
        this.setState({
          firstName: user.firstName,
          lastName: user.lastName,
          description: user.description,
          gender: user.gender,
          age: user.age,
          email:user.email,
          country: user.country,
          avatar: user.avatar,
          country: user.country,
          countryCode: user.countryCode,
          value: data,
          userId: user.id,
          favShow: user.favShow,
          favMovie: user.favMovie
        });
        localStorage.setItem("accessToken", JSON.stringify(user));
       
      }
    } else {
      this.setState({ redirect: true });
    }
  }

  changeHandler = (value) => {
    this.setState({
      value: value,
      country: value.label,

      countryCode: value.value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onSubmit = async (e) => {
    const {
      firstName,
      lastName,
      email,
      description,
      gender,
      age,
      country,
      avatar,
      userId,
      countryCode,
      favShow,
      favMovie
    } = this.state;

    e.preventDefault();
    const notification = this._notificationSystem.current;
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (firstName === "") {
      notification.addNotification({
        message: "FirstName is Required",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else if (lastName === "") {
      notification.addNotification({
        message: "LastName is Required",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else  if (email === "") {
      notification.addNotification({
        message: "Email is Required",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else if (!email.match(regexp)) {
      notification.addNotification({
        message: "Email is Invalid",
        level: "warning",
        tr: "top , right",
      });

      return;
    } else if (description === "") {
      notification.addNotification({
        message: "Description is Required",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else if (gender === "") {
      notification.addNotification({
        message: "Gender is Required",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else if (age === "") {
      notification.addNotification({
        message: "Age is Required",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else if (country === "") {
      notification.addNotification({
        message: "Country is Required",
        level: "warning",
        tr: "top , right",
      });
      return;
    }else if (favShow === "") {
      notification.addNotification({
        message: "Fav Show is Required",
        level: "warning",
        tr: "top , right",
      });
      return;
    }else if (favMovie === "") {
      notification.addNotification({
        message: "Fav Movie is Required",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else {
      this.setState({ loaderActive: true });
      this.setState({ errors: "" });
      var data = {
        firstName: firstName,
        lastName: lastName,
        description: description,
        gender: gender,
        age: age,
        country: country,
        countryCode: countryCode,
        avatar: avatar,
        favShow,
        favMovie
      };
     
     

      var result = await apiHelper(
        "post",
        `api/WebUsers/update?where=%7B%22id%22%3A%20%22${userId}%22%20%7D`,
        data,
        null
      );
      if (result.count == 1) {
        this.componentDidMount();
        notification.addNotification({
          message: "Update Successfully !",
          level: "success",
          tr: "top , right",
        });
      } else {
        notification.addNotification({
          message: "Something went wrong Try again Later!",
          level: "warning",
          tr: "top , right",
        });
      }
    }
  };

  onSubmitEmail = async (e) => {
    const {
      email,
      userId,
    } = this.state;

    e.preventDefault();
    const notification = this._notificationSystem.current;
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
     if (email === "") {
      notification.addNotification({
        message: "Email is Required",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else if (!email.match(regexp)) {
      notification.addNotification({
        message: "Email is Invalid",
        level: "warning",
        tr: "top , right",
      });

      return;
    } else {
      this.setState({ loaderActive: true });
      this.setState({ errors: "" });
      var data = {
        email:email
      };
     
      var Email = await apiHelper(
        "get",
        `api/WebUsers?filter[where][email]=${email}`,
        data,
        null
      );
      if(Email.length !==0 ){
        notification.addNotification({
          message: "Email Must be Unique !",
          level: "warning",
          tr: "top , right",
        });
        return
      }
      var result = await apiHelper(
        "post",
        `api/WebUsers/update?where=%7B%22id%22%3A%20%22${userId}%22%20%7D`,
        data,
        null
      );
      if (result.count == 1) {
        notification.addNotification({
          message: "Update Successfully !",
          level: "success",
          tr: "top , right",
        });
      } else {
        notification.addNotification({
          message: "Something went wrong Try again Later!",
          level: "warning",
          tr: "top , right",
        });
      }
    }


  }

  onSubmitPassword = async (e) => {
    const {
      password,
      confirmPassword,
      userId,
    } = this.state;

    e.preventDefault();
    const notification = this._notificationSystem.current;
    if (password === "") {
      notification.addNotification({
        message: "Password is Required",
        level: "warning",
        tr: "top , right",
      });

      return;
    } else if (password.length < 8) {
      notification.addNotification({
        message: "Password should Greater than 8 characters",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else if (password !== confirmPassword) {
      notification.addNotification({
        message: "Password doesn't match",
        level: "warning",
        tr: "top , right",
      });

      return;
    } else if (confirmPassword === "") {
      notification.addNotification({
        message: "Password Confirmation is Required",
        level: "warning",
        tr: "top , right",
      });

      return;
    }else {
      this.setState({ loaderActive: true });
      this.setState({ errors: "" });
      var data = {
        password,
        confirmPassword,
        userId,
      };
      
      var result = await apiHelper(
        "post",
        `reset-password/${userId}`,
        data,
        null
      );
      if (result.status == 200) {
        notification.addNotification({
          message: "Update Successfully !",
          level: "success",
          tr: "top , right",
        });
      } else {
        notification.addNotification({
          message: "Something went wrong Try again Later!",
          level: "warning",
          tr: "top , right",
        });
      }
    }


  }
  onChangeImage = async (e) => {
    const notification = this._notificationSystem.current;
    const files = e.target.files;
    var formData = new FormData();
    formData.append("avatar", files[0], files[0].name);
    formData.append("currentName", this.state.avatar);
   
    var result = await apiMultipart(
      "post",
      `update/profile/img/${this.state.userId}`,
      formData,
      null
    );

    if (result.status === 200) {
      this.setState({
        avatar: result.data.avatar,
      });
      localStorage.setItem("accessToken", JSON.stringify(result.data));
      notification.addNotification({
        message: "Profile Image Uploaded Successfully!",
        level: "success",
        tr: "top , right",
      });
    } else {
      notification.addNotification({
        message: "Something went wrong Try again later!",
        level: "warning",
        tr: "top , right",
      });
      this.setState({ logout: true });
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
    if (this.state.avatar != "") {
      var avatar = this.state.avatar;
      var str = avatar;
      var n = str.includes("uploads/");
      if (n) {
        avatar = window.APIURL + avatar;
      }
    }

    return (
      <main>
        <div class="main-wrapper pb-0 mb-0">
        <div class="page-title-bg" >
            <div class="container">
              <div className="page-title">
                <h2>Edit Profile</h2>
              </div>
            </div>
          </div>
          <div class="timeline-wrapper">
            <NotificationSystem ref={this._notificationSystem} />
            <div class="timeline-header"></div>
            <div class="timeline-page-wrapper">
              <div  class="container">
                <div class="row">
                  <div class="col-lg-4 order-1 order-lg-2 d-flex align-items-center justify-content-center">
                    <div class="signup-form-wrapper editProfile_wrapper">
                      <div class="signup-inner text-center">
                        <form class="signup-inner--form">
                          <div>
                            {this.state.avatar == "" ? (
                              <img
                                src={`${window.APIURL}uploads/download.png`}
                                alt="profile picture"
                                style={{ width: "200px", height: "200px" }}
                              />
                            ) : (
                              <img
                                src={`${avatar}`}
                                alt="profile picture"
                                style={{ width: "200px", height: "200px" }}
                              />
                            )}
                          </div>

                          <center>
                            <br />
                            <div class="fileUpload blue-btn btn width100">
                              <span>Upload profile Img</span>
                              <input
                                type="file"
                                class="uploadlogo"
                                accept="avatar/*"
                                onChange={this.onChangeImage}
                              />
                            </div>
                          </center>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8 order-1 order-lg-2 d-flex align-items-center justify-content-center">
                    <div class="signup-form-wrapper editProfile_wrapper">
                      <div class="signup-inner text-center">
                        <form class="signup-inner--form">
                          {/* <h5 style={{ "text-align": "center" }}>
                            Edit Profile
                          </h5> */}
                          <div class="row">
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder="First Name"
                                class="single-field"
                                name="firstName"
                                value={this.state.firstName}
                                onChange={this.onChange}
                              />
                            </div>
                            <div class="col-md-6">
                              <input
                                type="text"
                                class="single-field"
                                placeholder="Last Name"
                                name="lastName"
                                value={this.state.lastName}
                                onChange={this.onChange}
                              />
                            </div>
                            {/* <div class="col-md-12">
															<input type="email" class="single-field"      value={this.state.email}  name="email" onChange={this.onChange} placeholder="email " />
														</div> */}
                            <div class="col-12">
                              <textarea
                                placeholder="Enter Description"
                                class="single-field"
                                name="description"
                                value={this.state.description}
                                onChange={this.onChange}
                              ></textarea>
                            </div>
                            <div class="col-md-6">
                              <select
                                class="nice-select"
                                name="gender"
                                value={this.state.gender}
                                onChange={this.onChange}
                              >
                                <option value="">Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </select>
                            </div>
                            <div class="col-md-6">
                              <select
                                class="nice-select"
                                name="age"
                                value={this.state.age}
                                onChange={this.onChange}
                              >
                                <option value="">Age</option>
                                <option value="18+">18+</option>
                                <option value="18-">18-</option>
                                <option value="other">other</option>
                              </select>
                            </div>
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder="Fav Movie"
                                class="single-field"
                                name="favMovie"
                                value={this.state.favMovie}
                                onChange={this.onChange}
                              />
                            </div>
                            <div class="col-md-6">
                              <input
                                type="text"
                                class="single-field"
                                placeholder="Fav Show"
                                name="favShow"
                                value={this.state.favShow}
                                onChange={this.onChange}
                              />
                            </div>
                            {/* <div class="col-12">
                              <Select
                                className="county_slect"
                                options={this.state.options}
                                value={this.state.value}
                                onChange={this.changeHandler}
                              />
                            </div> */}
                            <div class="col-12">
                              <button
                                class="submit-btn"
                                type="button"
                                onClick={(e) => {
                                  this.onSubmit(e);
                                }}
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      {/* <div class="signup-inner text-center">
                        <form class="signup-inner--form">
                          <h5 style={{ "text-align": "center" }}>
                            Update Email
                          </h5>
                          <div class="row">
                            <div class="col-md-12">
															<input type="email" class="single-field"      value={this.state.email}  name="email" onChange={this.onChange} placeholder="email " />
														</div>
                            <div class="col-12">
                              <button
                                class="submit-btn"
                                type="button"
                                onClick={(e) => {
                                  this.onSubmitEmail(e);
                                }}
                              >
                                Update Email
                              </button>
                            </div>
                          </div>
                        </form>
                      </div> */}
                      {/* <div class="signup-inner text-center">
                        <form class="signup-inner--form">
                          <h5 style={{ "text-align": "center" }}>
                            Update Email
                          </h5>
                          <div class="row">
                          <div class="col-12">
                              <input
                                name="password"
                                value={this.state.password}
                                onChange={this.onChange}
                                type="password"
                                class="single-field"
                                placeholder="Password"
                              />
                            </div>

                            <div class="col-12">
                              <input
                                name="confirmPassword"
                                value={this.state.confirmPassword}
                                onChange={this.onChange}
                                type="password"
                                class="single-field"
                                placeholder="Confirm Password"
                              />
                            </div>
                            <div class="col-12">
                              <button
                                class="submit-btn"
                                type="button"
                                onClick={(e) => {
                                  this.onSubmitPassword(e);
                                }}
                              >
                                Update Password
                              </button>
                            </div>
                          </div>
                        </form>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default EditProfile;
