import React, { Component } from 'react';
import { apiHelper, apiMultipart } from "../../Helper/apiHelper";
import NotificationSystem from "react-notification-system";
import "./edit.css"
class EditProfile extends Component {

	constructor(props) {
		super(props);
		this.state = {
			firstName: "",
			lastName: "",
			description: "",
			email: "",
			userId: ""
		};
		this._notificationSystem = React.createRef();
	}


	async componentDidMount() {
		const user = JSON.parse(localStorage.getItem("accessToken"));
		if (user) {
			this.setState({ userId: user.id })
		}

	}

	onSubmit = async (e) => {
		const {
			firstName,
			lastName,
			description,
			email,
			userId
		} = this.state;

		e.preventDefault();
		const notification = this._notificationSystem.current;
		if (firstName === "") {
			notification.addNotification({
				message: "FirstName is Required",
				level: "warning",
				tr: "top , right",
			});
			return;
		} else if (lastName === "") {
			notification.addNotification({
				message: "LastName is Required",
				level: "warning",
				tr: "top , right",
			});
			return;
		} else if (email === "") {
			notification.addNotification({
				message: "Email is Required",
				level: "warning",
				tr: "top , right",
			});
			return;
		} else if (description === "") {
			notification.addNotification({
				message: "Description is Required",
				level: "warning",
				tr: "top , right",
			});
			return;
		} else {
			this.setState({ loaderActive: true });
			var data = {
				firstName: firstName,
				lastName: lastName,
				email: email,
				description: description,
				userId: userId
			};
			var result = await apiHelper("post", `api/contact`, data, null);
			if (result) {
				notification.addNotification({
					message: " Send Successfully !",
					level: "success",
					tr: "top , right",
				});
			} else {
				notification.addNotification({
					message: "Something went wrong Try again Later!",
					level: "warning",
					tr: "top , right",
				});
			}
		}
	};

	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	render() {
		return (
			<main>
				<div class="main-wrapper pb-0 mb-0">
					<div class="page-title-bg" >
						<div class="container">
							<div className="page-title">
								<h2>Contact Us</h2>
							</div>
						</div>
					</div>
					<div class="timeline-wrapper contact">
						<div class="timeline-header">
							<NotificationSystem ref={this._notificationSystem} />
						</div>
						<div class="timeline-page-wrapper">
							<div class="container">
								<div class="row ">
									<div class="col-lg-12">
										<div class="signup-form-wrapper editProfile_wrapper content">
											<h3>Bake</h3>
											<h5>We are Ready to Support You!</h5>
											<hr/>
											<div class="signup-inner text-center">
												<form class="signup-inner--form">
													<p style={{ "margin-bottom": "20px","text-align": "left","padding-left": "15px" }} >Contact Us</p>
													<div class="row">
														<div class="col-md-6">
															<input type="text" placeholder="First Name" value={this.state.firstName} name="firstName" onChange={this.onChange} class="single-field" />
														</div>
														<div class="col-md-6">
															<input type="text" class="single-field" value={this.state.lastName} name="lastName" onChange={this.onChange} placeholder="Last Name" />
														</div>
														<div class="col-md-12">
															<input type="email" class="single-field" value={this.state.email} name="email" onChange={this.onChange} placeholder="Email " />
														</div>
														<div class="col-12">
															<textarea placeholder="Enter Description" name="description" value={this.state.description} onChange={this.onChange} class="single-field" >

															</textarea>
														</div>

														<div class="col-12">
															<button onClick={(e) => {
																this.onSubmit(e);
															}} class="submit-btn">Submit</button>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		);
	}
}

export default EditProfile;
