import React, { Component } from 'react';
import LikeComponent from './LikePostComponent';
import PostComponent from './PostComponent';
import CommentCount from './commentCount';
var moment = require('moment');
class PostListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    async componentDidMount() {
        const user = JSON.parse(localStorage.getItem("accessToken"));
        if (user) {
            this.setState({ userId: user.id })
        }
    }
    imageUrl = (avatar) => {
        var str = avatar;
        var n = str.includes("uploads/");
        if (n) {
            avatar = window.APIURL + avatar;
            return avatar
        } else {
            return avatar
        }
    }

    dateCheck = (date) => {
        date = moment(date).fromNow();
        return date
    }


    render() {
        var th = this;
        var postList = [];
        postList = this.props.postList;
        return (

            postList.map(function (item, i) {
                return (
                    item.user ?
                        <div  key={item.id} class="card simple-post" key={i}>

                            <div class="post-title d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center ">
                                    <div class="profile-thumb">
                                        <a href={`#/user-profile/${item.user.id}`}  target="_blank" >
                                            <figure class="profile-thumb-middle">
                                                {item.user.avatar ? <img src={th.imageUrl(item.user.avatar)} alt="profile picture" /> : ''}
                                            </figure>
                                        </a>
                                    </div>

                                    <div class="posted-author">
                                        <h6 class="author">
                                            {item.user ? <a>{item.user.firstName}  {item.user.lastName}</a> : ""}
                                        </h6>
                                        <span class="post-time"> {th.dateCheck(item.createdAt)} </span>
                                    </div>
                                </div>
                                <div className="likes">
                                    <LikeComponent postId={item.id} />
                                </div>

                            </div>
                            <div class="post-content">
                                <p class="post-desc">
                                    {item.description}
                                </p>
                                <div class="post-thumb-gallery">
                                    <figure class="post-thumb img-popup">
                                        <a >
                                            {item.avatar != "" && item.avatar != undefined ?
                                                <img src={th.imageUrl(item.avatar)} alt="post image" /> : ""
                                            }

                                        </a>
                                    </figure>
                                </div>
                                <div class="post-meta">
                                    {/* <LikeComponent postId={item.id} /> */}
                                    <ul class="comment-share-meta">
                                        <CommentCount postId={item.id}></CommentCount>
                                    </ul>
                                </div>
                                <PostComponent postId={item.id}  ></PostComponent>
                            </div>
                        </div>
                        : ""
                )
            })
        );
    }
}

export default PostListing;

