import React, { Component } from "react";
import ReactStars from "react-rating-stars-component";
import LikeComponent from "./LikePostComponent";
import PostComponent from "./PostComponent";
import CommentCount from "./commentCount";
import swal from 'sweetalert';
import FriendSuggestion from './FriendSuggestion';
import { AddFriendMovieShowSuggestion } from "../notification/create-notification";

import { apiHelper } from "../../Helper/apiHelper";
var moment = require("moment");

var selectRating = 0;
const ratingChanged = (newRating) => {
  selectRating = newRating;
};

class PopUpMovieShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ratingModel: true,
      ratingId: "",
      userId: "",
      showFriendList: false
    };
    this._notificationSystem = React.createRef();
  }
  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem("accessToken"));
    if (user) {
      this.setState({ userId: user.id });
    }
  }
  imageUrl = (avatar) => {
    var str = avatar;
    var n = str.includes("uploads/");
    if (n) {
      avatar = window.APIURL + avatar;
      return avatar;
    } else {
      return avatar;
    }
  };
  dateCheck = (date) => {
    date = moment(date).fromNow();
    return date;
  };

  ChangePostStatus = async (postId, postStatus) => {
    var th = this;
    var data = {
      postStatus: postStatus,
    };
    var result = await apiHelper(
      "post",
      `api/showLists/update?where=%7B%22postId%22%3A%20%22${postId}%22%7D`,
      data,
      null
    );
    th.props.closeModel();
  };

  Close = (e) => {
    this.props.closeModel();
    this.setState({
      ratingModel: false,
      showFriendList: false
    });
  };

  onSubmit = async (e) => {
    const { ratingId, userId } = this.state;
    e.preventDefault();
    const notification = this._notificationSystem.current;

    if (selectRating == 0) {
      notification.addNotification({
        message: "Rating is Required",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else {
      this.setState({ loaderActive: true, errors: "" });
      let data = {
        ratingId: ratingId,
        selectRating: selectRating,
        userId: userId,
      };

      var result = await apiHelper("post", `api/bake/rating`, data, null);
      if (result.status == 200) {
        notification.addNotification({
          message: result.title,
          level: "success",
          tr: "top , right",
        });
        this.Close();
        this.componentDidMount();
      } else if (result.status == 302) {
        notification.addNotification({
          message: result.title,
          level: "warning",
          tr: "top , right",
        });
        this.Close();
        this.componentDidMount();
      } else {
        notification.addNotification({
          message: "Something went wrong Try again Later!",
          level: "warning",
          tr: "top , right",
        });
        this.Close();
      }
    }
  };

  reviewRating = (e, id) => {
    this.setState({
      ratingModel: true,
      ratingId: id,
    });
  };

  sendFriendSuggestion = () => {
    this.setState({showFriendList : true})
  };
  

  closeShowFriendList = () => {
    this.setState({showFriendList : false})
  }

  CloseAll = () => {
    this.Close();
  }

  render() {
    var th = this;
    var item = this.props.item;

    return item.post ? (
      <>
        <div
          class="modal fade show"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          style={{ "padding-right": "15px", display: "block" }}
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <span
                className="close-icon fa fa-close"
                onClick={(e) => this.Close(e)}
              ></span>
              <div
                class="col-lg-12 order-1 order-lg-2"
                style={{ "margin-bottom": "10px", padding: "0" }}
              >
                <div class="card">
                  <div class="post-title d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-start ">
                      <div class="profile-thumb">
                        <a>
                          <figure class="profile-thumb-middle">
                            {item.post ? (
                              <img
                                src={th.imageUrl(item.post.avatar)}
                                alt="profile picture"
                              />
                            ) : (
                              ""
                            )}
                          </figure>
                        </a>
                      </div>

                      <div
                        style={{ "text-align": "initial", "margin-top": "0" }}
                        class="share-content-box w-100"
                      >
                        <form class="share-text-box">
                          <h6 class="author">
                            <a href={`#/moviedetail/${item.post.id}`}>
                              {item.post.title}{" "}
                            </a>
                          </h6>
                          <a
                            href={`#/moviedetail/${item.post.id}`}
                            class="date"
                          >
                            {item.post.release_date}
                          </a>
                        </form>
                      </div>
                      <div class="post-title d-flex align-items-start ml-2">
                        <a
                          href={`#/moviedetail/${item.post.id}`}
                          class="badge badge-primary mr-1"
                        >
                          <span>{item.post.type}</span>
                        </a>
                        <div class="badge badge-primary percentage  mr-1">
                          Rating:   {(parseInt(item.post.vote_average)/10)*100}%
                        </div>
                        <div
                          class="badge badge-success  success "
                          onClick={() => this.sendFriendSuggestion()}
                        >
                          Send Suggestions
                        </div>
                        {/* {item.post.last_air_date  ?  <div class="mr-1"> Last Air Date : <a href="#" class="badge badge-primary mr-1">{item.post.last_air_date}</a></div> : ""} */}
                        {/* {item.post.homepage ? <div> Click: <a href={item.post.homepage} target="_blank" class="badge badge-primary mr-1"> Watch </a></div> : ""} */}
                      </div>
                    </div>
                    <div className="likes">
                      <LikeComponent postId={item.id} />
                    </div>
                    {/* <div class="post-settings-bar">
                                         <span></span>
                                         <span></span>
                                         <span></span>
                                         <div class="post-settings arrow-shape">
                                             <ul>
                                                 <li><button onClick={(e) => th.ChangePostStatus(item.post.id, "binged")}>Add to Binged</button></li>
                                                 <li><button onClick={(e) => th.ChangePostStatus(item.post.id, "binging")}>Add to Binging</button></li>
                                                 <li><button onClick={(e) => th.ChangePostStatus(item.post.id, "upnext")}>Add to Up Next</button></li>

                                             </ul>
                                         </div>
                                     </div> */}
                  </div>

                  <div class="post-content">
                    <div class="post-thumb-gallery">
                      <figure class="post-thumb img-popup">
                        <a>
                          {item.post.avatar != "" &&
                          item.post.avatar != undefined ? (
                            <img
                              src={th.imageUrl(item.post.avatar)}
                              alt="post image"
                            />
                          ) : (
                            ""
                          )}
                        </a>
                      </figure>
                      <div className="post-btn-list">
                        <button
                          onClick={(e) =>
                            th.ChangePostStatus(item.post.id, "binging")
                          }
                          className="btn btn-primary-outline"
                        >
                          Add to Binging
                        </button>

                        <button
                          onClick={(e) =>
                            th.ChangePostStatus(item.post.id, "upnext")
                          }
                          className="btn btn-primary"
                        >
                          Add to Up Next
                        </button>
                        <button
                          onClick={(e) =>
                            th.ChangePostStatus(item.post.id, "binged")
                          }
                          className="btn btn-secondary"
                        >
                          Add to Binged
                        </button>
                      </div>
                    </div>
                    <div className="post-info">
                      <p class="post-desc">{item.post.description}</p>

                      <div class="post-meta">
                        {/* <LikeComponent postId={item.post.id} /> */}
                        <ul class="comment-share-meta">
                          <CommentCount postId={item.post.id}></CommentCount>
                        </ul>
                      </div>
                      <PostComponent postId={item.post.id}></PostComponent>
                    </div>
                  </div>
                </div>
                <div className="card">
                  {/* {th.props.selectType != "Up Next" ?
                                                    <div class="text-center">
                                                        <button type="button" class="edit-btn" onClick={(e) => {
                                                            th.reviewRating(e, item.post.id);
                                                        }} >  Give Your Review  </button>
                                                    </div>
                                                    : ""} */}
                  {th.state.ratingModel ? (
                    <>
                      <div className="reviews-wrapper text-center">
                        <h5 class="reviews-title">Give a Review</h5>

                        <div class="reviews-content">
                          <ReactStars
                            count={10}
                            value={item.post.vote_average}
                            // onChange={ratingChanged}
                            size={24}
                            activeColor="#ffd700"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showFriendList ? <FriendSuggestion  showFriendList={this.state.showFriendList} closeShowFriendList={this.closeShowFriendList} CloseAll={this.CloseAll}    post={item.post}/> : "" }
      </>
    ) : (
      ""
    );
  }
}

export default PopUpMovieShow;
