import React, { Component } from 'react';
import { apiHelper, apiMultipart } from "./../../Helper/apiHelper";
import ShowMoviesPost from '../post/showMovies';
import $ from 'jquery';
import imageLoader from '../../Image/loader.gif';
window.jQuery = $;

export default class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            avatar: "",
            category: "",
            createdAt: "",
            description: "",
            genres: "",
            id: "",
            popularity: "",
            release_date: "",
            siteId: "",
            title: "",
            type: "",
            tagline: "",
            errors: '',
            cast: [],
            production_companies: [],
            postList: [],
            loader: false,
            seasons: [],
            mainArray:[]
        }
        this._notificationSystem = React.createRef();
    }


    async componentDidMount() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        var dataId = this.props.match.params;
        this.getData(dataId.id);
        this.post(10 ,dataId.id );

    }


    	//User Post Query
	async post(limit , userId) {

		var { postLimit , postCheck , mainArray , postCount , scroll} = this.state;
		postLimit = limit + postLimit;
		
		if(scroll == 0){
            var hi = 0;
        }else{
            var hi = $(window).height();
        }

		if(limit == 20){
			postLimit = limit + 0;
			mainArray = [];
		}
		
		const notification = this._notificationSystem.current;
		this.setState({ loader: true, });
		var data = {
			limit: limit,
            skip : postLimit - limit,
            userId: userId
		}
		var result = await apiHelper("post", `api/getLatest/Post/user`, data, null);
		this.setState({ loader: false });
		if (result.posts) {
			if(postCheck == 0){
				$("body").removeClass("transparent-header");
				$("html, body").animate({ scrollTop: 0 }, "slow");
			}
			
			let arr = result.posts
			for(var i = 0; arr.length > i ; i++){
				mainArray.push(arr[i]);
			}
		 	
			this.setState({
				mainArray: mainArray,
				postLimit: postLimit,
				postCheck: 1
			})
		} else {
			notification.addNotification({
				message: "No Record Find",
				level: "warning",
				tr: "top , right",
			});
		}

		var result1 = await apiHelper("post", `api/getLatest/Post/count/user`, data, null);
		this.setState({
			postCount: result1.count
		})

	}
	 

    async getData(dataId) {
   
        var result = await apiHelper("get", `api/WebUsers?filter[where][id]=${dataId}`, "", null);
        var resultdata = result[0];
     
        
        if (resultdata) {
            this.setState({
                loader: true,
                avatar: resultdata.avatar,
                firstName: resultdata.firstName,
                lastName: resultdata.lastName,
                description: resultdata.description,
            })

        } else {

        }
    }

    imageUrl = (avatar) => {
		var str = avatar;
		var n = str.includes("uploads/");
		if (n) {
			avatar = window.APIURL + avatar;
			return avatar
		} else {
			return avatar
		}
	}


    render() {
        return (
            <main>
                <div class="main-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="movie-bg" 
                                // style={{ "background-image": `url(${this.state.avatar})` }}
                                 >
                                    <div class="movie-bg-overlay"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="profile-menu-area bg-white mb-5">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-3 col-md-3">
                                    <div class="profile-picture-box">
                                        <figure class="profile-picture">
                                            <a >
                                                <img src={this.imageUrl(this.state.avatar)} alt="profile picture" />
                                            </a>
                                        </figure>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 offset-lg-1">
                                    <div class="profile-menu-wrapper">
                                        <div class="main-menu-inner header-top-navigation">
                                            <nav>
                                                <ul class="main-menu">
                                                    <li><a class="main-menu-link"> Name : {this.state.firstName} {this.state.lastName}</a></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container" style={{"margin-top":"70px"}}>
							<div class="row">
								<div class="col-lg-5 order-1 order-lg-1">
									<aside class="widget-area">
										<div class="card card-profile widget-item p-2">
                                            <h5>About Friend</h5>
                                            <p>{this.state.description}</p>
										</div>
									</aside>
								</div>

								<div class="col-lg-7 order-2 order-lg-2">
									{/* {this.state.postList.length > 0 ?       
									<Post postList={this.state.postList} /> : ""
	                                } */}
									{this.state.mainArray.length > 0 ?  
										<ShowMoviesPost postList={this.state.mainArray} />: " No Record Found !"
									}

									{/* <div class="share-content-box box-content-custom d-flex justify-content-between" style={{ "textAlign": "center" }}>
									
									  {  this.state.postCount > this.state.postLimit?
										  <button type="button" onClick={() => {
											this.post(10);
										}} class="post-share-btn" > More Post  </button> : ""
									   }

									    {  this.state.showCount > this.state.showLimit ?
										<button type="button" onClick={() => {
											this.postType("show");
										}} class="post-share-btn" >More Show</button> 
										: ""}

									     {  this.state.movieCount > this.state.movieLimit ?
									   <button type="button" onClick={() => {
											this.postType("movie");
										}} class="post-share-btn" >More Movies</button>
										: ""}
									</div> */}

								</div>
							</div>
						</div>
                     </div>

            </main>
        );
    }
}

