import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Dashboard from './dashboard';
import EditProfile from './Peofile-Setting/editProfile';
// import Notification from './Notification/notification';
import Search from './Search/search';
import TvClub from './TvClub/tvClub';
import Chat from './Chat/chat';
import Moviedetail from './Movie/detail';
import UserFriend from './Friends/List';
import UserDetail from './Friends/Detail';
import Security from './security/security';
import Account from './Account/account';
import Help from './help/help';
import About from './About/about';
import Contact from './Contact/contact';
import Home from '../staticPages/Home';
import { HashRouter, Route, Switch } from 'react-router-dom';
import userProfile from './userProfile';
import PublicUser from './Friends/publicUser';




class Body extends Component {


  constructor(props) {
    super(props);
    this.state = {
      role: "",
      redirect: false,
    }
  }

  componentWillMount() {
    // var session = JSON.parse(localStorage.getItem('LoginSession'));
    // if (session) {
    //   this.setState({ role: session.user.role })
    // } else {
    //   this.setState({ redirect: true })
    // }
    // console.log(session);


  }
  componentDidMount() {

  }
  onLogOut = (e) => {
    localStorage.removeItem('LoginSession');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('loginTime');
    this.setState({ logout: true });
  }

  render() {
    // if (this.state.logout) {
    //   return <Redirect to='/login' />;
    //  }
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/dashboard" name="Use Jobs" component={Dashboard} />
          <Route exact path="/user-profile/:id" name="Use Jobs" component={userProfile} />

          <Route exact path="/user-friendList/:id" name="Use Jobs" component={PublicUser} />

          <Route path="/editProfile" name="BackMain Page" component={EditProfile} />
          {/* <Route path="/notification" name="BackMain Page" component={Notification} /> */}
          <Route path="/search" name="BackMain Page" component={Search} />
          <Route exact path="/" name="Home" component={Home} />
          <Route path="/tvClub" name="BackMain Page" component={TvClub} />

          <Route  path="/tvClub/:id" name="BackMain Page" component={TvClub} />
          <Route path="/chat" name="BackMain Page" component={Chat} />

          <Route  path="/moviedetail/:id" name="BackMain Page" component={Moviedetail} />
      

          <Route  path="/userfriend" name="BackMain Page" component={UserFriend} />
          <Route  path="/friend/:id" name="BackMain Page" component={UserDetail} />

          <Route path="/security" name="BackMain Page" component={Security} />
          <Route path="/account" name="BackMain Page" component={Account} />
          <Route path="/help" name="BackMain Page" component={Help} />
          <Route path="/about" name="BackMain Page" component={About} />
          <Route path="/contact" name="BackMain Page" component={Contact} />

        </Switch>
      </HashRouter>
    );
  }
}
export default Body;





