import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { apiHelper, apiMultipart } from "../../Helper/apiHelper";
import NotificationSystem from "react-notification-system";


class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            description: '',
            avatarName: '',
            avatarFile:'',
            userId:'',
            title:'',
        }
        this._notificationSystem = React.createRef();
    }
    async componentDidMount() {
        const user = JSON.parse(localStorage.getItem("accessToken"));
        if (user) {
           this.setState({userId : user.id})
        }
       
    }

    onSubmit = async (e) => {
        const {
            title,
            description,
            userId,
            avatarName,
            avatarFile
        
        } = this.state;
        e.preventDefault();
        const notification = this._notificationSystem.current;
        if (title === "") {
          notification.addNotification({
            message: "Title is Required",
            level: "warning",
            tr: "top , right",
          });
        } else  if (description === ""){
            notification.addNotification({
                message: "Description is Required",
                level: "warning",
                tr: "top , right",
              });
        }  else  if (avatarFile === ""){
          notification.addNotification({
            message: "Image is Required",
            level: "warning",
            tr: "top , right",
          });

        } else {
          this.setState({ loaderActive: true });
          this.setState({ errors: "" });
          var formData = new FormData();
          
          if(avatarFile != ""){
            formData.append("avatar", avatarFile, avatarName);
          }
          formData.append("description", description);
          formData.append("title", title);
          formData.append("userId", userId);
          var result = await apiMultipart(
            "post",
            `api/create/tvClub`,
            formData,
            null
          );
          if (result.status == 200) {
            notification.addNotification({
              message: "TvClub Created Successfully !",
              level: "success",
              tr: "top , right",
            });
            this.props.AddTvClose();
          } else {
            notification.addNotification({
              message: "Something went wrong Try again Later!",
              level: "warning",
              tr: "top , right",
            });
          }
        }
      };
    
      onChange = (e) => {
        this.setState({
          [e.target.name]: e.target.value,
        });
      };

    onChangeImage = async (e) => {
        const files = e.target.files;
        this.setState({
            avatarFile: files[0], 
            avatarName: files[0].name,
        })
      };


    Close = (e) => {
        this.props.AddTvClose();
    }

    render() {
        return (

            <div class="modal fade show" id="textbox12" aria-labelledby="textbox" style={{ "padding-right": "15px", "display": "block" }} aria-modal="true" >
               <NotificationSystem ref={this._notificationSystem} />
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Create TV Club </h5>
                            <button type="button" class="close" onClick={(e) => this.Close(e)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body custom-scroll">
                            <div class="row">
                                <div className="col-md-12">
                                <div class="input-group" style={{"margin-bottom":"12px"}}>
                                <input name="title" onChange={this.onChange} class="title-iput" placeholder="   Title"/>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                <div class="input-group">
                                    <textarea name="description" onChange={this.onChange} class="share-field-big custom-scroll" placeholder="Post"></textarea>
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div class="fileUpload postImage blue-btn btn width100">
                              <span>Upload  Img</span>
                              <input
                                type="file"
                                class="uploadlogo "
                                accept="image/*"
                                onChange={this.onChangeImage}
                              />
                            </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" onClick={(e) => this.Close(e)} class="post-share-btn" >cancel</button>
                            <button type="button"   onClick={(e) => {
                                  this.onSubmit(e);
                                }} class="post-share-btn">Add</button>
                        </div>
                    </div>
                </div>
            </div>



        );
    }
}

export default Footer;

