import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
window.jQuery = $;
class About extends Component {
    
    constructor(props) {
		super(props);
		this.state = {
		}
	}
    
    SubmitGenres(geners) {            
       if(this.props.genresType === "dash"){
            this.props.getGenresData(geners);
       }else{
            this.props.getGenresData(geners);
       }
    }

    Close(e) {            
        this.props.closeGeners();
    }

    render() {
        return (
            <div class="modal fade fadeBlock show" id="textbox1" aria-labelledby="textbox">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Geners</h5>

                        </div>
                        <div class="modal-body custom-scroll">
                            <ul class="Genres_listing">
                                <li>
                                    <a  onClick={(e) => this.SubmitGenres("Action")}>Action & Adventure</a>
                                </li>
                                <li>
                                    <a  onClick={(e) => this.SubmitGenres("Animation")}>Animation</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Anime")}>Anime</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Beauty")}>Beauty & Fashion</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Classic TV")}>Classic TV</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Comedy")}>Comedy</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Crime")}>Crime</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Documentary")}>Documentary</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Drama")}>Drama</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Entertainment")}>Entertainment</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Family")}>Family</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Gaming")}>Gaming</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Health")}>Health & Fitness</a>
                                </li>
                                <li>
                                    <a   onClick={(e) => this.SubmitGenres("Home & Garden")} >Home & Garden</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Horror")}>Horror</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("International TV")}>International TV</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Learning & Education")}>Learning & Education</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Music")}>Music</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Nature")}>Nature</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("News")}>News</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Reality & Game Shows")}>Reality & Game Shows</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Science Fiction")}>Science Fiction</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Soaps")}>Soaps</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Special Events")}>Special Events</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Special Programs")}>Special Programs</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Sports")}>Sports</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Travel")}>Travel</a>
                                </li>
                                <li>
                                    <a onClick={(e) => this.SubmitGenres("Web Originals")}>Web Originals</a>
                                </li>
                            </ul>
                        </div>
                        <div class="modal-footer">
                            <button type="button" onClick={(e) => this.Close(e)} class="post-share-btn" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}

export default About;

