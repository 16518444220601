import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import imageLoader from '../../Image/loader.gif';
import Select from "react-select";
import countryList from "react-select-country-list";
import { apiHelper, apiMultipart } from "../../Helper/apiHelper";
import NotificationSystem from "react-notification-system";
import $ from 'jquery';
window.jQuery = $;


export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.options = countryList().getData();
    this.state = {
      firstName: "",
      lastName: "",
      description: "",
      gender: "",
      age: "",
      country: "",
      email: "",
      password: "",
      confirmPassword: "",
      countryCode: "",
      options: this.options,
      value: null,
      redirect: false,
      userId: "",
      avatar: "",
    };
    this._notificationSystem = React.createRef();
  }

  async componentDidMount() {
    $("body").removeClass("transparent-header");
    $("html, body").animate({ scrollTop: 0 }, "slow");
    const user = JSON.parse(localStorage.getItem("accessToken"));
    if (user) {
      var result = await apiHelper(
        "get",
        `api/WebUsers?filter[where][id]=${user.id}`,
        null
      );
      if (result.length > 0) {
        let user = result[0];
        var data = { value: user.countryCode, label: user.country };
        let avatar = user.avatar;
        this.setState({
          firstName: user.firstName,
          lastName: user.lastName,
          description: user.description,
          gender: user.gender,
          age: user.age,
          email: user.email,
          country: user.country,
          avatar: user.avatar,
          country: user.country,
          countryCode: user.countryCode,
          value: data,
          userId: user.id,
        });
      }
    } else {
      this.setState({ redirect: true });
    }
  }

  changeHandler = (value) => {
    this.setState({
      value: value,
      country: value.label,

      countryCode: value.value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onSubmit = async (e) => {
    const {
      firstName,
      lastName,
      email,
      description,
      gender,
      age,
      country,
      avatar,
      userId,
      countryCode,
    } = this.state;

    e.preventDefault();
    const notification = this._notificationSystem.current;
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (firstName === "") {
      notification.addNotification({
        message: "FirstName is Required",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else if (lastName === "") {
      notification.addNotification({
        message: "LastName is Required",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else if (email === "") {
      notification.addNotification({
        message: "Email is Required",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else if (!email.match(regexp)) {
      notification.addNotification({
        message: "Email is Invalid",
        level: "warning",
        tr: "top , right",
      });

      return;
    } else if (description === "") {
      notification.addNotification({
        message: "Description is Required",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else if (gender === "") {
      notification.addNotification({
        message: "Gender is Required",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else if (age === "") {
      notification.addNotification({
        message: "Age is Required",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else if (country === "") {
      notification.addNotification({
        message: "Country is Required",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else {
      this.setState({ loaderActive: true });
      this.setState({ errors: "" });
      var data = {
        firstName: firstName,
        lastName: lastName,
        description: description,
        gender: gender,
        age: age,
        country: country,
        countryCode: countryCode,
        avatar: avatar,
      };



      var result = await apiHelper(
        "post",
        `api/WebUsers/update?where=%7B%22id%22%3A%20%22${userId}%22%20%7D`,
        data,
        null
      );
      
      if (result.count == 1) {
        notification.addNotification({
          message: "Update Successfully !",
          level: "success",
          tr: "top , right",
        });
      } else {
        notification.addNotification({
          message: "Something went wrong Try again Later!",
          level: "warning",
          tr: "top , right",
        });
      }
    }
  };

  onSubmitEmail = async (e) => {
    const {
      email,
      userId,
    } = this.state;

    e.preventDefault();
    const notification = this._notificationSystem.current;
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === "") {
      notification.addNotification({
        message: "Email is Required",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else if (!email.match(regexp)) {
      notification.addNotification({
        message: "Email is Invalid",
        level: "warning",
        tr: "top , right",
      });

      return;
    } else {
      this.setState({ loaderActive: true });
      this.setState({ errors: "" });
      var data = {
        email: email
      };

      var Email = await apiHelper(
        "get",
        `api/WebUsers?filter[where][email]=${email}`,
        data,
        null
      );
      if (Email.length !== 0) {
        notification.addNotification({
          message: "Email Must be Unique !",
          level: "warning",
          tr: "top , right",
        });
        return
      }
      var result = await apiHelper(
        "post",
        `api/WebUsers/update?where=%7B%22id%22%3A%20%22${userId}%22%20%7D`,
        data,
        null
      );
      if (result.count == 1) {
        notification.addNotification({
          message: "Update Successfully !",
          level: "success",
          tr: "top , right",
        });
      } else {
        notification.addNotification({
          message: "Something went wrong Try again Later!",
          level: "warning",
          tr: "top , right",
        });
      }
    }


  }

  onSubmitPassword = async (e) => {
    const {
      password,
      confirmPassword,
      userId,
    } = this.state;

    e.preventDefault();
    const notification = this._notificationSystem.current;
    if (password === "") {
      notification.addNotification({
        message: "Password is Required",
        level: "warning",
        tr: "top , right",
      });

      return;
    } else if (password.length < 8) {
      notification.addNotification({
        message: "Password should Greater than 8 characters",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else if (password !== confirmPassword) {
      notification.addNotification({
        message: "Password doesn't match",
        level: "warning",
        tr: "top , right",
      });

      return;
    } else if (confirmPassword === "") {
      notification.addNotification({
        message: "Password Confirmation is Required",
        level: "warning",
        tr: "top , right",
      });

      return;
    } else {
      this.setState({ loaderActive: true });
      this.setState({ errors: "" });
      var data = {
        password,
        confirmPassword,
        userId,
      };

      var result = await apiHelper(
        "post",
        `reset-password/${userId}`,
        data,
        null
      );
      if (result.status == 200) {
        notification.addNotification({
          message: "Update Successfully !",
          level: "success",
          tr: "top , right",
        });
      } else {
        notification.addNotification({
          message: "Something went wrong Try again Later!",
          level: "warning",
          tr: "top , right",
        });
      }
    }


  }
  onChangeImage = async (e) => {
    const notification = this._notificationSystem.current;
    const files = e.target.files;
    var formData = new FormData();
    formData.append("avatar", files[0], files[0].name);
    formData.append("currentName", this.state.avatar);

    var result = await apiMultipart(
      "post",
      `update/profile/img/${this.state.userId}`,
      formData,
      null
    );

    if (result.status === 200) {
      this.setState({
        avatar: result.data.avatar,
      });
      localStorage.setItem("accessToken", JSON.stringify(result.data));
      notification.addNotification({
        message: "Profile Image Uploaded Successfully!",
        level: "success",
        tr: "top , right",
      });
    } else {
      notification.addNotification({
        message: "Something went wrong Try again later!",
        level: "warning",
        tr: "top , right",
      });
      this.setState({ logout: true });
    }
  };




  SelectSection = (selection) => {
    var th = this;
    if (selection === "Watched") {
      this.setState({ selectType: "Watched", loader: true });
      $("#1A").addClass("active");
      $("#2A").removeClass("active");
      $("#3A").removeClass("active");
      setTimeout(function () { th.setState({ loader: false }); }, 3000);

    } else if (selection === "WatchWatchlisted") {
      this.setState({ selectType: "Watch List", loader: true });
      $("#2A").addClass("active");
      $("#1A").removeClass("active");
      $("#3A").removeClass("active");
      setTimeout(function () { th.setState({ loader: false }); }, 3000);

    } else if (selection === "FriendSuggestion") {
      this.setState({ selectType: "Friend Suggestion", loader: true });
      $("#3A").addClass("active");
      $("#2A").removeClass("active");
      $("#1A").removeClass("active");
      setTimeout(function () { th.setState({ loader: false }); }, 3000);
    }
  }


  render() {
    var th = this;
    
    var user = JSON.parse(localStorage.getItem("accessToken"));
    if (user) {
      var firstName = user.firstName ? user.firstName : "" + user.lastName ? user.lastName : "";
      var description = user.description ? user.description : ""
      var avatar = user.avatar;
      var str = avatar;
      var n = str.includes("uploads/");
      if (n) {
        avatar = window.APIURL + avatar;
      }
    } else {
      return <Redirect to="/login" />;
    }
    return (
      <main>
        <div class="main-wrapper">

          <div class="page-title-bg" >
            <div class="container">
              <div className="page-title">
                <h2>Security</h2>
              </div>
            </div>
          </div>
          <div class="timeline-wrapper contact">
            <div class="timeline-header">
              <NotificationSystem ref={this._notificationSystem} />
            </div>
            <div class="timeline-page-wrapper">
              <div class="container">
                <div class="row">
                  
                  <div class="col-lg-12">
                    <div class="signup-form-wrapper editProfile_wrapper content">

                      <div class="signup-inner text-center">
                        <form class="signup-inner--form">
                          <p style={{ "margin-bottom": "20px","text-align": "left","padding-left": "15px" }}>
                            Update Email
                          </p>
                          <div class="row">
                            <div class="col-md-12">
                              <input type="email" class="single-field" value={this.state.email} name="email" onChange={this.onChange} placeholder="email " />
                            </div>
                            <div class="col-12">
                              <button
                                class="submit-btn"
                                type="button"
                                onClick={(e) => {
                                  this.onSubmitEmail(e);
                                }}
                              >
                                Update Email
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <hr/>
                      <div class="signup-inner text-center">
                        <form class="signup-inner--form">
                          <p style={{ "margin-bottom": "20px","text-align": "left","padding-left": "15px" }}>
                            Change Password
                          </p>
                          <div class="row">
                            <div class="col-12">
                              <input
                                name="password"
                                value={this.state.password}
                                onChange={this.onChange}
                                type="password"
                                class="single-field"
                                placeholder="Password"
                              />
                            </div>

                            <div class="col-12">
                              <input
                                name="confirmPassword"
                                value={this.state.confirmPassword}
                                onChange={this.onChange}
                                type="password"
                                class="single-field"
                                placeholder="Confirm Password"
                              />
                            </div>
                            <div class="col-12">
                              <button
                                class="submit-btn"
                                type="button"
                                onClick={(e) => {
                                  this.onSubmitPassword(e);
                                }}
                              >
                                Update Password
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>



                  {/* <div class="col-lg-3 order-3">
               
                </div> */}
                </div>
              </div>
            </div>
          </div>

        </div>

        {this.state.loader === true ?
          <div class="Loader">
            <img src={imageLoader} alt="profile picture" />
          </div> : ""
        }


      </main>
    );

  }
}