import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Home from './Home';
import { apiHelper, apiMultipart } from "./../Helper/apiHelper";

class Body extends Component {



  render() {
    return (
      <HashRouter>
        <Switch>


          <Route exact path="/" name="Home" component={Home} />
          {/* <Route exact path="/dashboard" name="Dashboard" component={Dashboard} /> */}

          {/* <Route exact path="*" name="404" component={Notfound} /> */}
        </Switch>
      </HashRouter>
    );
  }
}

export default Body;
