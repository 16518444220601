
import React, { Component } from 'react';
import { apiHelper, apiMultipart } from "../../Helper/apiHelper";
import { postNotification } from "../notification/create-notification";
var moment = require('moment');
class LikeComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            postList: [],
            postModel: false,
            LikeCount: 0,
            comment: '',
            commentList: []
        }

    }
    async componentDidMount() {
        var result = await apiHelper("get", `api/comments?filter[include]=user&filter[where][postId]=${this.props.postId}&filter[order]=id%20DESC`, {}, null);
        if (result.lenght !== 0) {
            this.setState({ commentList: result })
        }


    }


    CommentPost = async () => {
        
        var user = JSON.parse(localStorage.getItem("accessToken"));
        var userId = user.id;
        var postId = this.props.postId;
        var comment = this.state.comment;
        var createdAt = new Date();
        var data = {
            userId: userId,
            postId: postId,
            comment: comment,
            createdAt: createdAt
        }
        await apiHelper("post", `api/comments`, data, null);
        data.type = "comment";
        postNotification(data);
        this.setState({ comment: "" })
        this.componentDidMount();
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    dateCheck = (date) => {
        date = moment(date).fromNow();
        return date
    }

    imageUrl = (avatar) => {
        var str = avatar;
        var n = str.includes("uploads/");
        if (n) {
            avatar = window.APIURL + avatar;
            return avatar
        } else {
            return avatar
        }
    }

    render() {
        var th = this;
        return (
            <div>
                <div class="people-comment-wrap">
                    {this.state.commentList.map(function (item, i) {
                        return (
                            item.user ?
                                <div key={item.id} class="post-title d-flex align-items-center">
                                    <div class="profile-thumb">
                                        <figure class="profile-thumb-middle">
                                            <img src={th.imageUrl(item.user.avatar)} alt="profile picture" />
                                        </figure>
                                    </div>
                                    <div class="posted-author">
                                        <h6 class="author"><a href="">{item.user.firstName}  {item.user.lastName}</a> </h6>
                                        <span>{item.comment}</span>
                                        {/* <span class="post-time">{th.dateCheck(item.createdAt)} </span> */}
                                    </div>
                                    <div class="post-settings-bar"></div>
                                </div>
                                : "")
                    })}
                </div>
                <div class="commenting share-text-box">
                    <input name="comment" value={this.state.comment} onChange={this.onChange} class="share-text-field" placeholder="Write a comment"/>
                    <button type="button" disabled={this.state.comment === "" ? true : false} class="btn-share" onClick={(e) => {
                        this.CommentPost(e);
                    }} >Post</button>
                </div>
            </div>
        );
    }
}

export default LikeComponent;
