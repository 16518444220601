import React, { Component } from 'react';
import { apiHelper, apiMultipart } from "../../Helper/apiHelper";
class LikeComponent extends Component {
    
    constructor(props) {
		super(props);
		this.state = {
			postList: [],
            postModel: false,
            commentCount: 0
		}
	
    }
    async componentDidMount() {
        this.getCommentCount();
		
	}

    async componentWillReceiveProps() {
		this.getCommentCount();
		
	}
   
    async getCommentCount(){
		var result = await apiHelper("get", `api/comments?filter[where][postId]=${this.props.postId}`, {} , null);
        this.setState({   commentCount: result.length})
	}

    render() {
        return (
            <li>
            <button class="post-comment">
                <i class="bi bi-chat-bubble"></i>
            <span> COMMENTS  {this.state.commentCount} </span>
            </button>
        </li>
        );
    }
}

export default LikeComponent;
