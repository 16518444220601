import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { apiHelper } from "./../Helper/apiHelper";
import { socket } from "../DashBoard/notification/create-notification";
import Notification from "./Notification";
import $ from "jquery";

window.jQuery = $;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logout: false,
      dropDown: false,
      redirect: false,
      tvClubCheck: false,
      search: ""
    };
  }
  componentDidMount() {

    var token = JSON.parse(localStorage.getItem("accessToken"));
    if (token) {
      let userId = token.id;
      this.getOldNotification();
      this.getOldChatNotification();
      socket.on("new-tv-club-notification-output", (data) => {
        for (var i = 0; i < data.length; i++) {
          if (data[i].read == false && userId == data[i].notifyUser) {
            this.setState({
              tvClubCheck: true,
            });
            return;
          }
        }
      });

      socket.on("new-message-status-output", (data) => {
        for (var i = 0; i < data.length; i++) {
          if (data[i].read == false && userId == data[i].receiverId) {
            this.setState({
              tvClubCheck: true,
            });
            return;
          }
        }
      });
    } else {
      this.setState({ logout: true });
    }
  }

  async getOldNotification() {
    const user = JSON.parse(localStorage.getItem("accessToken"));
    let userId = user.id;
    var data1 = await apiHelper("get", `api/tvClubNotifications`, null);
    for (var i = 0; i < data1.length; i++) {
      if (data1[i].read == false && userId == data1[i].notifyUser) {
        this.setState({
          tvClubCheck: true,
        });
        return;
      }
    }
  }
  async getOldChatNotification() {
    const user = JSON.parse(localStorage.getItem("accessToken"));
    let userId = user.id;
    var data1 = await apiHelper(
      "get",
      `api/Chats?filter[where][receiverId]=${userId}`,
      null
    );
    for (var i = 0; i < data1.length; i++) {
      if (data1[i].read == false && userId == data1[i].receiverId) {
        this.setState({
          tvClubCheck: true,
        });
        return;
      }
    }
  }

  onLogOut = (e) => {
    localStorage.clear();
    localStorage.removeItem("LoginSession");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("loginTime");
    this.setState({ logout: true });
  };
  Notifications = () => {
    $(".noti-icon").toggleClass("active-pro");
    $(".noti-icon").removeClass("active-close");
  }
  NotificationsClose = () => {
    $(".noti-icon").toggleClass("active-close");
    $(".noti-icon").removeClass("active-pro");
  }
  ProfileViewDrop = (check) => {
    if (check == "screen") {
      if (this.state.dropDown == true) {
        this.setState({ dropDown: false });
        $("#profile").addClass("profile-body1");
        $("#profile").removeClass("profile-dropdown1");
      } else {
        this.setState({ dropDown: true });
        $("#profile").addClass("profile-dropdown1");
        $("#profile").removeClass("profile-body1");
      }
    } else {
      if (this.state.dropDown == true) {
        this.setState({ dropDown: false });
        $("#profile1").addClass("profile-body1");
        $("#profile1").removeClass("profile-dropdown1");
      } else {
        this.setState({ dropDown: true });
        $("#profile1").addClass("profile-dropdown1");
        $("#profile1").removeClass("profile-body1");
      }
    }
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };


  searchForUser = async (e) => {
    if (e.key === "Enter") {
      window.location.href = window.APPURL + `#/search?search=${this.state.search}`;
    }
  };

  render() {
    const pathName = window.location.hash;
    var user = JSON.parse(localStorage.getItem("accessToken"));
    if (user) {

      var firstName = user.firstName
        ? user.firstName
        : "" + user.lastName
          ? user.lastName
          : "";
      var description = user.description ? user.description : "";
      var avatar = user.avatar;
      var str = avatar;
      var n = str.includes("uploads/");
      if (n) {
        avatar = window.APIURL + avatar;
      }
    } else {
      return <Redirect to="/login" />;
    }

    if (this.state.logout) {
      return <Redirect to="/login" />;
    } else if (!user) {
      return <Redirect to="/login" />;
    } else {
      return (
        <div>
          <header>
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container">
                <Link class="logo navbar-brand" to="/">
                  bake.{" "}
                </Link>
                <div class="search-form d-flex">
                  <input
                    class="form-control mr-2"
                    type="search"
                    name="search"
                    placeholder="Search your next binge..."
                    value={this.state.search}
                    onKeyPress={this.searchForUser}
                    onChange={this.onChange}
                  />
                  <span className="search-icon">
                    <img
                      src="images/search-icon.png"
                      alt="Search Icon"
                      className="img-fluid"
                    />
                  </span>
                </div>
                <div class="collapse navbar-collapse" id="navbarScroll">
                  <ul class="navbar-nav mx-auto my-2 my-lg-0 ">
                    <li class={`nav-item ${pathName == "#/search" ? "active" : ""}`}>
                      <Link class="nav-link" to="/search"  >
                        Explore{" "}
                      </Link>
                    </li>
                    <li class={`nav-item ${pathName == "#/" ? "active" : ""}`}>
                      <Link class="nav-link" to="/">
                        Rec Room
                      </Link>
                    </li>

                    <li class={`nav-item ${pathName == "#/tvClub?type=tvclub" ? "active" : ""}`}>
                      <Link class="nav-link" to="/tvClub?type=tvclub">
                        TV Club
                      </Link>
                    </li>
                  </ul>
                </div>
                <div class="profile-setting-box dropdown">

                  <div class="profile-thumb-small">
                    <a
                      class="profile-triger  dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    >
                      <span className="user-name">{firstName}</span>
                      <figure>
                        <img src={`${avatar}`} alt="profile picture" />
                      </figure>
                    </a>
                    <div id="profile" class="dropdown-menu profile-dropdown" aria-labelledby="dropdownMenuButton">
                      <div class="profile-head">
                        <h5 class="name">
                          <Link to="/dashboard">{firstName} </Link>
                        </h5>
                        <a class="mail" href="#">
                          {user.email}
                        </a>
                      </div>
                      <div class="profile-body">
                        <ul>
                          <li>
                            <Link to="/editProfile">
                              <i class="flaticon-user"></i>Edit Profile
                            </Link>
                          </li>
                          {/* <li><Link to="/notification"><i class="flaticon-user"></i>Notification</Link></li> */}
                          <li>
                            <Link to="/security">
                              <i class="flaticon-document"></i>Security
                            </Link>
                          </li>
                          <li>
                            <Link to="/about">
                              <i class="flaticon-document"></i>About
                            </Link>
                          </li>
                          {/* <li><Link to="/help"><i class="flaticon-document"></i>Help</Link></li> */}
                        </ul>
                        <ul>
                          <li>
                            <Link to="/contact">
                              <i class="flaticon-settings"></i>Contact
                            </Link>
                          </li>
                          <li>
                            <a onClick={(e) => this.onLogOut()}>
                              <i class="flaticon-unlock"></i>Sign Out
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </header>
            
          <div className="noti-icon mobile-notification-wrapper"><span  onClick={(e) => this.NotificationsClose()} className="fa fa-close close-icon"></span>
                      <Notification/></div>
          <header>
            <div class="mobile-header-wrapper sticky d-block d-lg-none">
              <div class="mobile-header position-relative ">
                <div class="mobile-logo">
                  <Link to="">
                    <img src="assets/images/logo/logo-white.png" alt="logo" />
                  </Link>
                </div>
                <div class="mobile-menu w-100">
                  <ul>
                    <li>
                      <Link to="/search">
                        <i class="fa fa-search"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/dashboard">
                        {" "}
                        <i class="fa fa-users"></i>
                      </Link>
                    </li>

                    <li>
                      <Link to="/tvClub?type=tvclub">
                        {" "}
                        <i
                          id="1A"
                          class="fa fa-television  "
                          // style={{ "margin-top": "8px" }}
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/tvClub?type=chat">
                        {" "}
                        <i
                          id="2A"
                          class="fa fa-commenting-o"
                          // style={{ "margin-top": "8px" }}
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </li>
                    <li className="" onClick={(e) => this.Notifications()}>
                        <i class="fa fa-bell" ></i>
                    
                      
                    </li>
                  </ul>
                
                </div>
                <div class="mobile-header-profile">
                  <div class="profile-thumb profile-setting-box">
                    {/* <a onClick={(e)=> this.ProfileViewDrop(e)} class="profile-triger"> */}
                    <figure
                      onClick={(e) => this.ProfileViewDrop("modile")}
                      class="profile-thumb-middle"
                    >
                      {user ? (
                        <img src={`${avatar}`} alt="profile picture" />
                      ) : (
                        <img
                          src={`${window.APIURL}uploads/download.png`}
                          alt="profile picture"
                        />
                      )}
                    </figure>
                    {/* </a> */}
                    <div id="profile1" class="profile-dropdown1 profile-dropdown text-left">
                      <div class="profile-head">
                        <h5 class="name">
                          <Link to="/dashboard">{firstName}</Link>
                        </h5>
                        <a class="mail" href="#">
                          {user.email}
                        </a>
                      </div>
                      <div class="profile-body">
                        <ul>
                          <li>
                            <Link to="/editProfile">
                              <i class="flaticon-user"></i>Edit Profile
                            </Link>
                          </li>
                          <li>
                            <Link to="/notification">
                              <i class="flaticon-user"></i>Notification
                            </Link>
                          </li>
                          <li>
                            <Link to="/security">
                              <i class="flaticon-document"></i>Security
                            </Link>
                          </li>
                          <li>
                            <Link to="/about">
                              <i class="flaticon-document"></i>About
                            </Link>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <Link to="/contact">
                              <i class="flaticon-settings"></i>Contact
                            </Link>
                          </li>
                          <li>
                            <a onClick={(e) => this.onLogOut()}>
                              <i class="flaticon-unlock"></i>Sign Out
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
         
        </div>
      );
    }
  }
}

export default Header;
