import React, { Component } from 'react';
import Notifications, { notify } from 'react-notify-toast';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
// import "./edit.css"
import imageLoader from '../../Image/loader.gif';
import $ from 'jquery';
window.jQuery = $;


export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: '',
            loader: false,
            templatesArray: [],
            role: "",
            selectType: "About"
        }
    }
    componentDidMount() {

    }




    SelectSection = (selection) => {
        var th = this;
        if (selection === "Watched") {
            this.setState({ selectType: "Watched" ,  loader: true});
            $("#1A").addClass("active");
            $("#2A").removeClass("active");
            $("#3A").removeClass("active");
            setTimeout(function(){   th.setState({ loader: false}); }, 3000);

        } else if (selection === "WatchWatchlisted") {
            this.setState({ selectType: "Watch List", loader: true });
            $("#2A").addClass("active");
            $("#1A").removeClass("active");
            $("#3A").removeClass("active");
            setTimeout(function(){ th.setState({ loader: false}); }, 3000);

        } else if (selection === "FriendSuggestion") {
            this.setState({ selectType: "Friend Suggestion" ,   loader: true});
            $("#3A").addClass("active");
            $("#2A").removeClass("active");
            $("#1A").removeClass("active");
            setTimeout(function(){ th.setState({ loader: false}); }, 3000);
        }
    }


    render() {
        return (
            <main>
                <div class="main-wrapper">
                    <div class="profile-menu-area bg-white" style={{ "margin-top": "145px" }}>
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-3 col-md-3">
                                    <div class="profile-picture-box">
                                        <figure class="profile-picture">
                                            <a href="">
                                                <img style={{"width": "150px"}} src="assets/images/profile/profile-1.jpg" alt="profile picture" />
                                            </a>
                                        </figure>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 offset-lg-1">
                                    <div class="profile-menu-wrapper">
                                        <div class="main-menu-inner header-top-navigation">
                                            <nav>
                                                <ul class="main-menu" style={{"text-align": "center"}}>
                                                    <li id="1A" onClick={(e) => this.SelectSection("Watched")} class="active"><a >Help</a></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 order-2 order-lg-1">
                            </div>
                            <div class="col-lg-9 order-1 order-lg-2" style={{ "margin-bottom": "26px" }}>
                                <div class="card card-small">
                                    <div class="share-box-inner">
                                        {/* <h3 style={{ "text-align": "center", "margin": "auto" }}>{this.state.selectType}</h3> */}
                                    </div>
                                    <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.</p>

                                </div>
                            </div>
                          
                         

                            {/* <div class="col-lg-3 order-3">
               
                </div> */}
                        </div>
                    </div>
                </div>

                { this.state.loader === true ?
                    <div class="Loader">
                        <img src={imageLoader} alt="profile picture" />
                    </div> : ""
                }


            </main>
        );

    }
}