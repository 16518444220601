import React, { Component } from 'react';
import { apiHelper, apiMultipart } from "../../Helper/apiHelper";
class LikeComponent extends Component {
    
    constructor(props) {
		super(props);
		this.state = {
			postList: [],
            postModel: false,
            LikeCount: 0
		}
	
    }

    async componentDidMount() {
        var result = await apiHelper("get", `api/likePosts?filter[where][postId]=${this.props.postId}`, {}, null);
		this.setState({ LikeCount : result.length })
		
	}

    async componentWillReceiveProps() {
        var result = await apiHelper("get", `api/likePosts?filter[where][postId]=${this.props.postId}`, {}, null);
		this.setState({ LikeCount : result.length })
		
	}
   

     LikePost =  async () =>{
        var user = JSON.parse(localStorage.getItem("accessToken"));
        var userId = user.id;
        var postId = this.props.postId 
        var data = {
            userId :userId  ,
            postId :postId
        }
        
        var result = await apiHelper("get", `api/likePosts?filter[where][userId]=${userId}&[where][postId]=${postId}`, {}, null);
       
        if(result.length === 0){
             await apiHelper("post", `api/likePosts`, data , null);
             this.componentDidMount();
        }else{
            var data = result[0];
            
             await apiHelper("delete", `api/likePosts/${data.id}`, {} , null);
             this.componentDidMount();
        }
	}

    kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
    }


    render() {
        return (
            <button class="post-meta-like d-flex align-items-center" onClick={(e) => this.LikePost(e)}  >
            <span>{this.kFormatter(this.state.LikeCount)}</span>
            <div className="d-flex flex-column ml-2 align-items-center justify-content-center">
            <span className="fa fa-chevron-up p-0" > </span> 
            <img src="images/Popcorn.png" alt="" className="icon my-1" />
            </div> 
        </button>
        );
    }
}

export default LikeComponent;
