import React, { Component } from 'react';
import { apiHelper } from "../../Helper/apiHelper";

class AddFriendComponent extends Component {
    constructor(props) {
        super(props);
            this.state = {
                exist: false,
    
            };
        }
    
    async componentDidMount() {

        const user = JSON.parse(localStorage.getItem('accessToken'));
      
        const item = this.props.item;

        var resultData = await apiHelper("get", `api/friendLists?filter[where][friendId]=${item.id}&filter[where][userId]=${user.id}`, {}, null);

        if (resultData.length === 0) {
                this.setState({exist : true})
        }
        
    }
    

    render() {
        const item = this.props.item;
        const i = this.props.i;
        return (
            <div key={item.id} class="card card-small">
            <div class="share-box-inner">
                <div class="profile-thumb">
                    <a href={`#/user-profile/${item.id}`}>
                        <figure class="profile-thumb-middle">
                            {this.props.imagePath(item.avatar)}

                        </figure>
                    </a>
                </div>

                <div style={{ "text-align": "initial", "margin-top": "16px" }} class="share-content-box w-100">
                    <form class="share-text-box">
                        <h6 class="author"><a href={`#/user-profile/${item.id}`}>{item.firstName} {item.lastName}</a></h6>
                       {this.state.exist ?
                        <button class="btn-share" type="button" onClick={(e) => {
                            this.props.addFriend(item.id, i, "search");
                        }} >Add</button> : ""}

                    </form>
                </div>
            </div>
        </div>
        );
    }
}

export default AddFriendComponent;
