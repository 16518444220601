import React, { Component } from 'react';
import { apiHelper, apiMultipart } from "../../Helper/apiHelper";
import "./list.css";
import NotificationSystem from "react-notification-system";
import imageLoader from '../../Image/loader.gif';
import $ from "jquery";
window.jQuery = $;
export default class List extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            data: []
        }
    }


    async componentDidMount() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        let data = this.props.match.params;
        this.getData(data.id);
    }

    async getData(userId) {
        console.log("userId")
        this.setState({ loader: true });
        var result = await apiHelper("get", `api/friendLists?filter[where][userId]=${userId}&filter[where][status]=true&filter[include]=friend`, "", null);
        this.setState({ loader: false });
        if (result) {
            this.setState({
                data: result
            })
        } else {

        }
    }

    imageUrl = (avatar) => {
		var str = avatar;
		var n = str.includes("uploads/");
		if (n) {
			avatar = window.APIURL + avatar;
			return avatar
		} else {
			return avatar
		}
	}

    render() {
        let that = this;
        return (
            <main>
                <div class="">
					<div class="profile-menu-area friend" >
						<div class="container">
							<div class="row align-items-center p-2">
								<div class="col-lg-6">
                                    <h3 className="friend-list">Friend List</h3>
								</div>
								<div class="col-lg-6 text-right">
                                    <a href={`#/dashboard`} class="btn back-btn" >Back</a>
								</div>
							</div>
						</div>
					</div>
				</div>

                <div class="container">
                           <div class="row text-center">
                          
                              {this.state.data.map(function (item, i) {
                                        return (     
                                            item.friend ?
                                            <div class="friend-card">  
                                             <div class="card">  
                                                 {item.friend.avatar != null ?
                                                 <div class="profile-image text-center">
                                                      <a>
                                                    <img class="profile lazyload lazyloaded" src={that.imageUrl(item.friend.avatar)} data-loaded="true" />
                                                     </a>
                                                </div>
                                                 
                                                 :  <a>
                                                    <img class="profile lazyload lazyloaded" src="assets/images/blank.png" data-loaded="true" />
                                                   </a>}
                                                   <p class="episode_count text-center"><a href={`#/user-profile/${item.friend.id}`}>{item.friend.firstName}  {item.friend.lastName} </a></p>
                                                   </div>
                                            </div>
                                            : ""
                                            ) 

                                    })}
                        
                      </div>
                 </div>
            </main>
        );
    }
}

