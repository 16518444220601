import React, { Component } from 'react';
import ReactStars from "react-rating-stars-component";
import LikeComponent from './LikePostComponent';
import PostComponent from './PostComponent';
import CommentCount from './commentCount';
import Percentage from '../percentage/percentage';
import PopUpMovieShow from './PopUpMovieShow';
import NotificationSystem from "react-notification-system";
import { apiHelper, apiMultipart } from "../../Helper/apiHelper";
var moment = require('moment');

var selectRating = 0;
const ratingChanged = (newRating) => {
    selectRating = newRating;
};

class PostListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ratingModel: true,
            ratingId: '',
            userId: '',
            selectedObj : '',
            showPopUpView : false,
            
        }
        this._notificationSystem = React.createRef();
    }
    async componentDidMount() {
        const user = JSON.parse(localStorage.getItem("accessToken"));
        if (user) {
            this.setState({ userId: user.id })
        }
    }
    imageUrl = (avatar) => {
        var str = avatar;
        var n = str.includes("uploads/");
        if (n) {
            avatar = window.APIURL + avatar;
            return avatar
        } else {
            return avatar
        }
    }
    dateCheck = (date) => {
        date = moment(date).fromNow();
        return date
    }

    ChangePostStatus = async (postId, postStatus) => {
        var th = this;
        var data = {
            postStatus: postStatus
        }
        var result = await apiHelper("post", `api/showLists/update?where=%7B%22postId%22%3A%20%22${postId}%22%7D`, data, null);
        th.props.refreshCall();
    }


    Close = (e) => {
        this.setState({
            ratingModel: false
        })
    }



    onSubmit = async (e) => {
        const {
            ratingId, userId
        } = this.state;
        e.preventDefault();
        const notification = this._notificationSystem.current;

        if (selectRating == 0) {
            notification.addNotification({
                message: "Rating is Required",
                level: "warning",
                tr: "top , right",
            });
            return;
        }
        else {
            this.setState({ loaderActive: true, errors: "" });
            let data = {
                ratingId: ratingId,
                selectRating: selectRating,
                userId: userId
            }

            var result = await apiHelper(
                "post",
                `api/bake/rating`,
                data,
                null
            );
            if (result.status == 200) {
                notification.addNotification({
                    message: result.title,
                    level: "success",
                    tr: "top , right",
                });
                this.Close();
                this.componentDidMount();
            } else if (result.status == 302) {
                notification.addNotification({
                    message: result.title,
                    level: "warning",
                    tr: "top , right",
                });
                this.Close();
                this.componentDidMount();
            } else {
                notification.addNotification({
                    message: "Something went wrong Try again Later!",
                    level: "warning",
                    tr: "top , right",
                });
            }
        }
    };

    reviewRating = (e, id) => {
        this.setState({
            ratingModel: true,
            ratingId: id
        })
    }

    selectPost = (item) => {
        this.setState({selectedObj :  item,  showPopUpView :  true})
    }


    closePopUPView = () => {
        this.setState({ showPopUpView :  false})
        this.props.refreshCall();
    }

    render() {
        const th = this;
        let postList = [];
        postList = this.props.postList;
        const  popupCheck = this.props.popupCheck;
        return (
            <>
            { postList.map(function (item, i) {
                return (
                    item.post ?
                        <>
                            <li key={item.id} class="image">
                                <a   onClick={(e) => th.selectPost(item)}
                                // data-toggle="modal"
                                //  data-target="#exampleModalCenter"
                                // href={`#/moviedetail/${item.post.id}`}
                                >
                                    {item.post.avatar != "" && item.post.avatar != undefined ?
                                        <img src={th.imageUrl(item.post.avatar)} alt="post image" /> : ""
                                    }

                                </a>
                            </li>
                        </>
                        : ""
                )
            })
            }
            {this.state.showPopUpView && popupCheck ?<PopUpMovieShow item={th.state.selectedObj}  closeModel={th.closePopUPView}></PopUpMovieShow> : ""}
            </>
        );
    }
}

export default PostListing;

