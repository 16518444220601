import React, { Component } from 'react';
import { apiHelper, apiMultipart } from "./../../Helper/apiHelper";
import PostComponent from '../post/PostDetailComponent';
import "./detail.css";
import $ from 'jquery';
import Percentage from '../percentage/percentage';
import imageLoader from '../../Image/loader.gif';
window.jQuery = $;

export default class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            avatar: "",
            category: "",
            createdAt: "",
            description: "",
            genres: "",
            id: "",
            popularity: "",
            release_date: "",
            last_air_date: "",
            siteId: "",
            title: "",
            type: "",
            tagline: "",
            errors: '',
            cast: [],
            production_companies: [],
            postList: [],
            seasons: [],
            homepage: "",
            networks: [],
            genresList: []
        }
    }


    async componentDidMount() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        var dataId = this.props.match.params;
        this.getData(dataId.id);
    }

    async getData(dataId) {
        this.setState({ loader: true });
        var result = await apiHelper("get", `api/posts?filter[where][id]=${dataId}`, "", null);
        var resultdata = result[0];
        
        this.setState({ loader: false });

        if (resultdata) {
            // 
            this.setState({

                avatar: resultdata.avatar,
                category: resultdata.category,
                createdAt: resultdata.createdAt,
                description: resultdata.description,
                genres: resultdata.genres,
                id: resultdata.id,
                vote_average: resultdata.vote_average,
                tagline: resultdata.tagline,
                production_companies: resultdata.production_companies,
                cast: resultdata.cast,
                popularity: resultdata.popularity,
                release_date: resultdata.release_date,
                siteId: resultdata.siteId,
                title: resultdata.title,
                type: resultdata.type,
                seasons: resultdata.seasons,
                release_date: resultdata.release_date,
                last_air_date: resultdata.last_air_date,
                networks: resultdata.networks,
                homepage: resultdata.homepage,
                genresList: resultdata.genresList
            })

        } else {

        }
        
    }


    link = (path) => {
        window.open(
            path,
            '_blank' // <- This is what makes it open in a new window.
        );

        // window.location = path;

    }

    render() {

        return (
            <main>

                <div class="main-wrapper">
                    {/* <div class="container">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="movie-bg" >
                                    <div class="movie-bg-overlay"></div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div class="profile-menu-area " style={{ "padding-top": "70px" }}>
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-md-12">
                                    <div class="profile-picture">

                                        <a >
                                            <img style={{ "width": "200px", "height": "200px" }} src={this.state.avatar} alt="profile picture" />
                                        </a>
                                    </div>
                                    <h3>{this.state.title} {this.state.genresList.map(function (item, i) {
                                        return (<span onClick={() => {
                                            this.link(this.state.homepage);
                                        }} style={{ 'cursor': 'context-menu', 'font-size': '12px' }} class="badge badge-primary mr-5">{item.name}</span>
                                        )
                                    })}
                                    </h3>
                                    <h6>{this.state.tagline}</h6>
                                    <p>{this.state.description}</p>
                                </div>

                                <div class="col-lg-9 col-md-9">
                                    <div class="profile-menu-wrapper">
                                        <div class="main-menu-inner header-top-navigation">
                                            <nav>
                                                <ul>
                                                    {this.state.release_date ? <li>Release Date  : <span class="badge badge-primary mr-5">{this.state.release_date}</span></li> : ""}
                                                    {/* { this.state.last_air_date ?  <li>Last Air Date  : <span href="#" class="badge badge-primary mr-5">{this.state.last_air_date}</span></li> : "" } */}
                                                    {this.state.homepage ? <li>Click Here  : <span onClick={() => {
                                                        this.link(this.state.homepage);
                                                    }} style={{ 'cursor': 'context-menu' }} class="badge badge-primary mr-5">Watch </span></li> : ""}
                                                    <li>Vote Average : <li style={{ 'width': 40, 'height': 40 }}><Percentage per={this.state.vote_average} /></li></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="container">
                        <div class="row mt-3">
                            <div class="col-lg-12 order-1 order-lg-2" style={{ "margin-bottom": "26px" }}>
                                <div class="card card-small">
                                    <div class="mb-3">
                                        <h3>{this.state.title} {this.state.genresList.map(function (item, i) {
                                        return ( <span  onClick={() => {
                                                this.link(this.state.homepage);
                                        }}     style = {{'cursor': 'context-menu' , 'font-size': '12px'}}   class="badge badge-primary mr-5">{item.name}</span>
                                        )})}
                                            </h3>
                                        <h6>{this.state.tagline}</h6>
                                        <p>{this.state.description}</p>
                                    </div>
                                    <div class="media">
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div class="container">
                        <div class="row bg-white">
                            <div class="col-sm-12">
                                <h3 class="cast-heading m-3">{this.state.type} Cast</h3>
                            </div>

                        </div> <div class="row bg-white">
                            {this.state.cast.map(function (item, i) {
                                return (
                                    <div class="cast-card ">
                                        {item.profile_path != null ?
                                            <a >
                                                <img class="profile lazyload lazyloaded" src={`https://image.tmdb.org/t/p/w220_and_h330_face/${item.profile_path}`} data-loaded="true" />
                                            </a>
                                            : <a >
                                                <img class="profile lazyload lazyloaded" src="assets/images/blank.png" data-loaded="true" />
                                            </a>}
                                        <div class="card-content">
                                            <h5 class="character">{item.character}</h5>
                                            <p class="episode_count">{item.name}</p>
                                        </div>
                                    </div>
                                    // <li class="cast-card " style={{color: "red"}}>

                                    // </li>

                                )
                            })}




                        </div>
                    </div>
                    <div class="container">
                        {this.state.type == "show" ?
                            <div class="row bg-white mt-5">
                                {this.state.seasons.map(function (item, i) {
                                    return (
                                        <div class="cast-card" >

                                            {item.poster_path != null ?
                                                <a >
                                                    <img class="profile lazyload lazyloaded" src={`https://image.tmdb.org/t/p/w220_and_h330_face/${item.poster_path}`} data-loaded="true" />
                                                </a>
                                                : <a >
                                                    <img class="profile lazyload lazyloaded" src="assets/images/blank.png" data-loaded="true" />
                                                </a>}
                                            <p class="character">Episode Count {item.episode_count}</p>
                                            <p class="episode_count">{item.name}</p>
                                        </div>)
                                })}



                            </div>
                            : ""}
                    </div>


                    {this.state.id != "" ?

                        <div class="container">
                            <div class="row mt-5 bg-white">
                                <div class="col-sm-12">
                                    <h4 class="m-3">Comments</h4>
                                    <PostComponent postId={this.state.id}  ></PostComponent>
                                </div>
                            </div>
                        </div>
                        : ""}

                </div>
                {this.state.loader === true ?
                    <div class="Loader">
                        <img src={imageLoader} alt="profile picture" />
                    </div> : ""
                }
            </main>
        );
    }
}

