import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

window.loginExpiresAfter = 1440;

// window.APIURL = 'http://localhost:8080/';
// window.APPURL = 'http://localhost:3000/';
// window.Endpoint = "http://localhost:8080";


// window.APIURL = "http://bake.website/";
// window.APPURL = "http://bake.website/";
// window.Endpoint = "http://bake.website/";

window.APIURL = 'https://bake.website/';
window.APPURL = 'https://bake.website/';
window.Endpoint = 'https://bake.website/';


ReactDOM.render(<App />, document.getElementById('root'));


serviceWorker.unregister();
