import React, { Component } from "react";
import swal from "sweetalert";
import { AddFriendMovieShowSuggestion } from "../notification/create-notification";

import { apiHelper } from "../../Helper/apiHelper";
var moment = require("moment");

class PopUpMovieShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ratingModel: true,
      ratingId: "",
      userId: "",
      friendList: [],
    };
    this._notificationSystem = React.createRef();
  }

  async componentDidMount() {
    this.getData();
  }

  async getData() {
    const user = JSON.parse(localStorage.getItem("accessToken"));
    this.setState({ loader: true });
    var result = await apiHelper(
      "get",
      `api/friendLists?filter[where][userId]=${user.id}&filter[where][status]=true&filter[include]=friend`,
      "",
      null
    );
    this.setState({ loader: false });
    if (result) {
      this.setState({
        friendList: result,
      });
    } else {
    }
  }

  imageUrl = (avatar) => {
    var str = avatar;
    var n = str.includes("uploads/");
    if (n) {
      avatar = window.APIURL + avatar;
      return avatar;
    } else {
      return avatar;
    }
  };

  dateCheck = (date) => {
    date = moment(date).fromNow();
    return date;
  };

  ChangePostStatus = async (postId, postStatus) => {
    var th = this;
    var data = {
      postStatus: postStatus,
    };
    var result = await apiHelper(
      "post",
      `api/showLists/update?where=%7B%22postId%22%3A%20%22${postId}%22%7D`,
      data,
      null
    );
    th.props.closeModel();
  };

  Close = (e) => {
    this.props.closeShowFriendList();
  };


  CloseAll = (e) => {
    this.props.CloseAll();
  };

  sendFriendSuggestion = (data) => {
    const user = JSON.parse(localStorage.getItem("accessToken"));
    if (user) {
      
      const friendList = this.state.friendList;
      const selectedFriend = [];
      for (let x = 0; friendList.length > x; x++) {
        const obj = friendList[x];
        if (obj.selected) {
          selectedFriend.push(obj.friendId);
        }
      }

      if (selectedFriend.length === 0) { return this.setState({ ERROR: "Select One Friend Sending Suggestion!" }) }

      const fina_obj = {
        userId: user.id,
        type: data.type,
        postId: data.id,
        selectedFriend
      };
      AddFriendMovieShowSuggestion(fina_obj);
      swal({
        title: "Good job!",
        text: "Your Suggestion Send Successfully !",
        icon: "success",
        buttons: false,
      });
      setTimeout(this.CloseAll(), 2000);
    }
  };

  SelectFriend = (index) => {
    ;
    const arrayFriend = this.state.friendList;

    const selectedFriend = arrayFriend[index];

    if (selectedFriend.selected === true) {
      selectedFriend.selected = false;
    } else if (selectedFriend.selected === false) {
      selectedFriend.selected = true;
    } else {
      selectedFriend.selected = true;
    }

    arrayFriend[index] = selectedFriend;

    this.setState({
      friendList: arrayFriend,
    });
  };

  render() {
    const th = this;
    return (
      <>
        <div
          class="modal fade show"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          style={{ "padding-right": "15px", display: "block" }}
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <span
                className="close-icon fa fa-close"
                onClick={(e) => this.Close(e)}
              ></span>
              <div
                class="col-lg-12 order-1 order-lg-2"
                style={{ "margin-bottom": "10px", padding: "0" }}
              >
                <div class="card">
                  <h4 className="friends modal-title">
                    Select Friend To Send Suggestion</h4>
                  <div class="container">
                    <div className="friends-card-list">
                      <div class="row text-center ">
                        {this.state.friendList.length === 0 ? (
                          <h3>There is no friend in your List!</h3>
                        ) : (
                          ""
                        )}

                        {this.state.friendList.map(function (item, i) {
                          return item.friend ? (
                            <div
                              key={item.id}
                              class="friend-card"
                              onClick={() => th.SelectFriend(i)}
                            >
                              <div className="actions-btn">
                                {/* <span className="fa fa-check"></span>
                                <span className="fa fa-close"></span> */}

                                {item.selected ?  <span className="fa fa-check"></span>
                                : <span className="fa fa-close"></span> }

                              </div>
                              <div class="card">
                                {item.friend.avatar != null ? (
                                  <div class="profile-image text-center">
                                    <a>
                                      <img
                                        class="profile lazyload lazyloaded"
                                        src={th.imageUrl(item.friend.avatar)}
                                        data-loaded="true"
                                      />
                                    </a>
                                  </div>
                                ) : (
                                  <a>
                                    <img
                                      class="profile lazyload lazyloaded"
                                      src="assets/images/blank.png"
                                      data-loaded="true"
                                    />
                                  </a>
                                )}
                                <p class="episode_count text-center">
                                  <a>
                                    {item.friend.firstName} {item.friend.lastName}
                                  </a>
                                </p>
                              </div>
                            </div>
                          ) : (
                            ""
                          );
                        })}
                      </div>
                    </div>
                    {this.state.ERROR ? <div class="alert alert-danger" > {this.state.ERROR}  </div>: ""}
                    {this.state.friendList.length != 0 ? (
                      <div>

                        <button
                          onClick={(e) =>
                            th.sendFriendSuggestion(th.props.post)
                          }
                          className="btn btn-primary"
                        >
                          Send Suggestion
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PopUpMovieShow;
