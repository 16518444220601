import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import Body from './Body';
import { socket } from "../DashBoard/notification/create-notification";
import $ from 'jquery';
window.jQuery = $;

// import './styles/bootstrap/css/bootstrap.css';
// import './styles/font-awesome/css/font-awesome.min.css';

class FrontMain extends Component {

  constructor(props) {
    super(props);
    this.state = {
        notification: [],
    }
}
async componentDidMount() {

    socket.on("new-tv-club-notification-output", data => {
    })
  
}
  

  render() {
    return (
      <div id="wrapper" >
        <Header/>
        <Body/>
        <Footer/>
      </div>
    );
  }
}

export default FrontMain;
