import React, { Component } from "react";
import FrontMain from "./staticPages/FrontMain";
import BackMain from "./DashBoard/main";
import Login from "./staticPages/Login";
import Landing from "./staticPages/Landing";
import Register from "./staticPages/Register";
import FirstPage from "./staticPages/fisrtRegPage";
import ShowSelection from "./staticPages/firstSelectMovie";
import Showvideos from "./staticPages/Showvideos";

import { HashRouter, Route, Switch } from "react-router-dom";

class App extends Component {
  render() {
    return (
       <HashRouter>
          <Switch>
            <Route exact path="/" name="Landing Page" component={Landing} />
            <Route exact path="/login" name="Login" component={Login} />
            <Route exact path="/register" name="Login" component={Register} />
            <Route  path="/dashboard" name="BackMain Page" component={BackMain} />

            <Route  path="/user-profile" name="BackMain Page" component={BackMain} />
            <Route  path="/user-friendList" name="BackMain Page" component={BackMain} />

            <Route  path="/setting" name="BackMain Page" component={BackMain} />
            <Route  path="/editProfile" name="BackMain Page" component={BackMain} />
            <Route  path="/notification" name="BackMain Page" component={BackMain} />
            <Route  path="/search" name="BackMain Page" component={BackMain} />
            <Route  path="/tvClub" name="BackMain Page" component={BackMain} />

            <Route  path="/tvClub/:id" name="BackMain Page" component={BackMain} />
            <Route  path="/chat" name="BackMain Page" component={BackMain} />
            <Route  path="/moviedetail/:id" name="BackMain Page" component={BackMain} />

            {/* Friend List */}
            <Route  path="/userfriend" name="BackMain Page" component={BackMain} />
            <Route  path="/friend/:id" name="BackMain Page" component={BackMain} />

            <Route  path="/security" name="BackMain Page" component={BackMain} />
            <Route  path="/account" name="BackMain Page" component={BackMain} />
            <Route  path="/help" name="BackMain Page" component={BackMain} />
            <Route  path="/about" name="BackMain Page" component={BackMain} />
            <Route  path="/contact" name="BackMain Page" component={BackMain} />
            <Route  path="/friendSelection" name="BackMain Page" component={FirstPage} />
            <Route  path="/showSelection" name="BackMain Page" component={ShowSelection} />
            <Route  path="/showvideos" name="BackMain Page" component={Showvideos} />
            {/* <Route exact path="/" name="Home" component={BackMain} /> */}
            {/* <Route exact path="*" name="FrontMain Page" component={FrontMain} /> */}
          </Switch>
      </HashRouter>
    );
  }
}

export default App;
