import React, { Component } from "react";
import LikePostComponent from "./LikePostComponent";
import LikeShowsComponent from "./LikeShowsComponent";
import PostComponent from "./PostComponent";
import CommentCount from "./commentCount";
import { apiHelper } from "../../Helper/apiHelper";
import { postNotification } from "../notification/create-notification";
import Percentage from "../percentage/percentage";
var moment = require("moment");
class PostListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
        length : 0
    };
  }
  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem("accessToken"));
    if (user) {
      this.setState({ userId: user.id });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.postList !== this.props.postList) {
        return true;
    } else {
        if(nextProps.postList.length !== this.state.length){
            return true;
        }else{
            return false;
        }
      
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
     this.setState({ length : this.props.postList.length});
    }
   }

  imageUrl = (avatar) => {
    var str = avatar;
    var n = str.includes("uploads/");
    if (n) {
      avatar = window.APIURL + avatar;
      return avatar;
    } else {
      return avatar;
    }
  };

  dateCheck = (date) => {
    date = moment(date).fromNow();
    return date;
  };

  async addShow(id, index, type, postStatus) {
    var data = {
      userId: this.state.userId,
      postId: id,
      postStatus: postStatus,
    };
    if (type === "list") {
      this.props.removeIndex(index);
      // var list = this.props.postList;
      // list.splice(index, 1);
      // this.setState({ addList: list })
    }
    var resultData = await apiHelper(
      "get",
      `api/showLists?filter[where][userId]=${this.state.userId}&filter[where][postId]=${id}`,
      {},
      null
    );
    if (resultData.length == 0) {
      var result = await apiHelper("post", `api/showLists`, data, null);
      data.type = "addShow";
      postNotification(data);
    }
  }

  render() {
    var th = this;
    var postList = [];
    postList = this.props.postList;
    return postList.map(function (item, i) {
      return item.user ? (
        <div key={item.id} class="card simple-post">
          <div class="post-title d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center flex-wrap">
              <div class="profile-thumb">
                <a href={`#/user-profile/${item.user.id}`} target="_blank">
                  <figure class="profile-thumb-middle">
                    {item.user.avatar ? (
                      <img
                        src={th.imageUrl(item.user.avatar)}
                        alt="profile picture"
                      />
                    ) : (
                      ""
                    )}
                  </figure>
                </a>
              </div>
              <div class="posted-author">
                <h6 class="author">
                  {item.user ? (
                    <a>
                      {item.user.firstName} {item.user.lastName}
                    </a>
                  ) : (
                    ""
                  )}
                </h6>
                <span class="post-time"> {th.dateCheck(item.createdAt)} </span>
              </div>
            </div>
            <div className="likes">
              <LikePostComponent postId={item.id} />
            </div>
          </div>
          <div class="post-content">
            {item.avatar != "" && item.avatar != undefined ? (
              <div class="post-thumb-gallery">
                <figure class="post-thumb img-popup">
                  <a>
                    <img src={th.imageUrl(item.avatar)} alt="post image" />
                  </a>
                </figure>
              </div>
            ) : (
              ""
            )}
            <div className="post-info">
              <p class="post-desc">{item.description}</p>
              <div class="post-meta">
                <ul class="comment-share-meta">
                  <CommentCount postId={item.id}></CommentCount>
                </ul>
              </div>
              <PostComponent postId={item.id}></PostComponent>
            </div>
          </div>
        </div>
      ) : (
        <div class="card" key={i}>
          <div class="post-title d-flex align-items-start justify-content-between">
            <div className="d-flex align-items-start flex-wrap">
              <div class="profile-thumb">
                <a href={`#/moviedetail/${item.id}`}>
                  <figure class="profile-thumb-middle">
                    {item.avatar ? (
                      <img
                        src={th.imageUrl(item.avatar)}
                        alt="profile picture"
                      />
                    ) : (
                      ""
                    )}
                  </figure>
                </a>
              </div>

              <div
                style={{ "text-align": "initial", "margin-right": "10px" }}
                class="share-content-box"
              >
                <form class="share-text-box">
                  <h6 class="author">
                    <a href={`#/moviedetail/${item.id}`}>{item.title} </a>
                  </h6>
                  <a href={`#/moviedetail/${item.id}`} class="date">
                    {item.release_date}
                  </a>
                </form>
              </div>
              <div class="post-title d-flex align-items-start ml-3">
                <a
                  href={`#/moviedetail/${item.id}`}
                  class="badge badge-primary "
                >
                  <span>{item.type}</span>
                </a>
                <div class="badge badge-primary percentage mx-2">
                  Rating: {(parseInt(item.vote_average)/10)*100}% 
                  {/* <Percentage per={item.vote_average} /> */}
                </div>
                <a
                  href={`${item.homepage}`} target="_blank"
                  class="watch badge badge-primary "
                >
                  <span>WATCH NOW </span>
                </a>

                {/* {item.last_air_date  ?  <div class="mr-1"> Last Air Date : <a href="#" class="badge badge-primary mr-1">{item.last_air_date}</a></div> : ""} */}
                {/* {item.homepage ? <div> Click: <a href={item.homepage} target="_blank" class="badge badge-primary mr-1"> Watch</a></div> : ""} */}
              </div>
            </div>
            {/* <div class="post-settings-bar">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <div class="post-settings arrow-shape">
                                        <ul>
                                            <li><button onClick={(e) => th.addShow(item.id, i, "list", "binged")}>Add to Binged</button></li>
                                            <li><button onClick={(e) => th.addShow(item.id, i, "list", "binging")}>Add to Binging</button></li>
                                            <li><button onClick={(e) => th.addShow(item.id, i, "list", "upnext")}>Add to Up Next</button></li>

                                           
                                        </ul>
                                    </div>
                                </div> */}
            <div className="likes">
              <LikeShowsComponent postId={item.id} />
            </div>
          </div>

          <div class="post-content">
            {item.avatar != "" && item.avatar != undefined ? (
              <div class="post-thumb-gallery">
                <figure class="post-thumb img-popup">
                  <a href={`#/moviedetail/${item.id}`}>
                    <img src={th.imageUrl(item.avatar)} alt="post image" />
                  </a>
                </figure>
                <div className="post-btn-list">
                  <button
                    className="btn btn-primary-outline"
                    onClick={(e) => th.addShow(item.id, i, "list", "binging")}
                  >
                    Add to Binging
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={(e) => th.addShow(item.id, i, "list", "upnext")}
                  >
                    Add to Up Next
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={(e) => th.addShow(item.id, i, "list", "binged")}
                  >
                    Add to Binged
                  </button>

                  {/* <li><button onClick={(e) => th.addShow(item.id  ,  i,  "list", "Add More Title")}>Add to Up Next</button></li>
                   */}
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="post-info">
              <p class="post-desc">{item.description}</p>
              <div class="post-meta">
                {/* <LikeComponent postId={item.id} /> */}
                <ul class="comment-share-meta">
                  <CommentCount postId={item.id}></CommentCount>
                </ul>
              </div>
              <PostComponent postId={item.id}></PostComponent>
            </div>
          </div>
        </div>
      );
    });
  }
}

export default PostListing;
