import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class Footer extends Component {
    render() {
        return (

            <footer class="main-footer">
            
			</footer>
        );
    }
}

export default Footer;
