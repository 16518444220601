function apiHelper(apiType, path, data, token) {
  if (apiType == "post") {
    return new Promise(function (resolve, reject) {
      fetch(window.APIURL + path, {
        method: apiType,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve(false);
          }
        );
    });
  } else {
    return new Promise(function (resolve, reject) {
      fetch(window.APIURL + path, {
        method: apiType,
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve(false);
          }
        );
    });
  }
}

function apiMultipart(apiType, path, data, token) {
  return new Promise(function (resolve, reject) {
    fetch(window.APIURL + path, {
      method: apiType,
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(false);
        }
      );
  });
}

export { apiHelper, apiMultipart };
