import React, { Component } from "react";
import { apiHelper } from "./../Helper/apiHelper";
import mp3 from "../Image/pristine-609.mp3";
import { socket } from "../DashBoard/notification/create-notification";
import NotificationSystem from "react-notification-system";
import $ from "jquery";
var moment = require("moment");
window.jQuery = $;

class LikeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: "",
      loader: false,
      userId: "",
      postList: [],
      templatesArray: [],
      readCount: 0,
      role: "",
    };
    this._notificationSystem = React.createRef();
  }
  async componentDidMount() {
    var x = document.getElementById("myAudio");

    $("body").removeClass("transparent-header");
    $("html, body").animate({ scrollTop: 0 }, "slow");
    const user = JSON.parse(localStorage.getItem("accessToken"));

    let userId = user.id;
    this.setState({ userId });
    socket.on("new-notification-output", (data) => {
      // x.play();
      var postList = this.state.postList;
      this.setState({
        postList: data,
      });
      var temp = [];
      for (var i = 0; i < data.length; i++) {
        if (data[i].read == false && userId == data[i].notifyUser) {
          temp.push("");
        }
      }
      this.setState({
        readCount: temp.length,
      });
    });
    this.getOldNotification();
  }

  async getOldNotification() {
    const user = JSON.parse(localStorage.getItem("accessToken"));

    let userId = user.id;
    var x = document.getElementById("myAudio");
    var data1 = await apiHelper(
      "get",
      `api/notifications?filter[include]=post&filter[include]=user`,
      null
    );
    this.setState({
      postList: data1,
    });
    var temp = [];
    var play = 0;
    for (var i = 0; i < data1.length; i++) {
      if (data1[i].read == false && userId == data1[i].notifyUser) {
        if (play == 0) {
          // x.play();
        }
        play = 1;
        temp.push("");
      }
    }
    this.setState({
      readCount: temp.length,
    });
  }
  componentWillReceiveProps() {
    // console.log("ttttttttttttttttttttttttttttttttttttttttttttttttttt")
    var currentUser = this.props.currentChat;
    // setTimeout(function(){
    // socket.emit('old-notification', "data");
    // }, 1000);
  }
  imageUrl = (avatar) => {
    var str = avatar;
    var n = str.includes("uploads/");
    if (n) {
      avatar = window.APIURL + avatar;
      return avatar;
    } else {
      return avatar;
    }
  };

  dateCheck = (date) => {
    date = moment(date).fromNow();
    return date;
  };

  toggleHover = async () => {
    const user = JSON.parse(localStorage.getItem("accessToken"));

    let userId = user.id;
    let data = {
      read: true,
    };
    var data1 = await apiHelper(
      "post",
      `api/notifications/update?where=%7B%22notifyUser%22%3A%20%22${userId}%22%7D`,
      data
    );
    this.getOldNotification();
    // https://www.bake.website/api/notifications/update?where=%7B%22notifyUser%22%3A%20%225f4176a5f54a1e6a2e961c83%22%7D
  };


  Reject = async (data) => {
    const result = await apiHelper(
      "post",
      `api/reject/friend`,
      data
    );

    if(result.status == 200){
       this.getOldNotification();
    }

  }

  Accept = async (data) => {

    const result = await apiHelper(
      "post",
      `api/accept/friend`,
      data
    );
    
    if(result.status == 200){
      this.getOldNotification();
    }

  }


  render() {
    var th = this;
    return (
      <aside class="widget-area" onMouseEnter={this.toggleHover}>
        <NotificationSystem ref={this._notificationSystem} />
        <div class="card widget-item">
          <h4 class="widget-title">
          Notifications
            <span class="badge badge-danger mr-1"> {this.state.readCount}</span>
          </h4>
          <div class="widget-body all_notific_body">
            <ul class="like-page-list-wrapper">
              {this.state.postList
                .slice(0)
                .reverse()
                .map(function (item, i) {
                  return (
                    <>
                      {item.user &&
                      item.type == "post" &&
                      th.state.userId == item.notifyUser ? (
                        <li key={item.id} class="unorder-list">
                          <div class="profile-thumb">
                            <a>
                              <figure class="profile-thumb-small">
                                {item.user.avatar ? (
                                  <img
                                    src={th.imageUrl(item.user.avatar)}
                                    alt="profile picture"
                                  />
                                ) : (
                                  ""
                                )}
                              </figure>
                            </a>
                          </div>
                          <div class="unorder-list-info">
                            <h5 class="list-title">
                              <a> New Post </a>
                            </h5>
                            <h3 class="list-title">
                              <a>{item.post.description}</a>
                            </h3>
                            <p class="list-subtitle">
                              {th.dateCheck(item.createdAt)}
                            </p>
                          </div>
                        </li>
                      ) : (
                        ""
                      )}
                      {item.user &&
                      item.type == "comment" &&
                      th.state.userId == item.notifyUser ? (
                        <li class="unorder-list">
                          <div class="profile-thumb">
                            <a>
                              <figure class="profile-thumb-small">
                                {item.user.avatar ? (
                                  <img
                                    src={th.imageUrl(item.user.avatar)}
                                    alt="profile picture"
                                  />
                                ) : (
                                  ""
                                )}
                              </figure>
                            </a>
                          </div>
                          <div class="unorder-list-info">
                            <h5 class="list-title">
                              <a> New Comment </a>
                            </h5>
                            <h3 class="list-title">
                              <a>
                                {item.post.type == "post"
                                  ? item.post.description
                                  : item.post.title}
                              </a>
                            </h3>
                            <p class="list-subtitle">
                              {th.dateCheck(item.createdAt)}
                            </p>
                          </div>
                        </li>
                      ) : (
                        ""
                      )}
                      {item.user &&
                      item.type == "addShow" &&
                      th.state.userId == item.notifyUser ? (
                        <li class="unorder-list">
                          <div class="profile-thumb">
                            <a>
                              <figure class="profile-thumb-small">
                                {item.user.avatar ? (
                                  <img
                                    src={th.imageUrl(item.user.avatar)}
                                    alt="profile picture"
                                  />
                                ) : (
                                  ""
                                )}
                              </figure>
                            </a>
                          </div>
                          <div class="unorder-list-info">
                            <h5 class="list-title">
                              <a> Add Show/Movie </a>
                            </h5>
                            <h3 class="list-title">
                              <a>{item.post.title}</a>
                            </h3>
                            <p class="list-subtitle">
                              {th.dateCheck(item.createdAt)}
                            </p>
                          </div>
                        </li>
                      ) : (
                        ""
                      )}

                      {item.user &&
                      item.type == "movie" &&
                      th.state.userId == item.notifyUser ? (
                        <li key={item.id} class="unorder-list">
                          <div class="profile-thumb">
                            <a>
                              <figure class="profile-thumb-small">
                                {item.user.avatar ? (
                                  <a
                                    href={`#/user-profile/${item.user.id}`}
                                    target="_blank"
                                  >
                                    <img
                                      src={th.imageUrl(item.user.avatar)}
                                      alt="profile picture"
                                    />
                                  </a>
                                ) : (
                                  ""
                                )}
                              </figure>
                            </a>
                          </div>
                          <div class="unorder-list-info">
                            <h5 class="list-title">
                              <a> Friend Suggestions </a>
                            </h5>
                            <h3 class="list-title">
                              <a
                                href={`/#/moviedetail/${item.post.id}`}
                                target="_blank"
                              >
                                {item.post.title}
                              </a>
                            </h3>
                            <p class="list-subtitle">
                              {th.dateCheck(item.createdAt)}
                            </p>
                          </div>
                        </li>
                      ) : (
                        ""
                      )}

                      {item.user &&
                      item.type == "show" &&
                      th.state.userId == item.notifyUser ? (
                        <li key={item.id} class="unorder-list">
                          <div class="profile-thumb">
                            <a>
                              <figure class="profile-thumb-small">
                                {item.user.avatar ? (
                                  <a
                                    href={`#/user-profile/${item.user.id}`}
                                    target="_blank"
                                  >
                                    <img
                                      src={th.imageUrl(item.user.avatar)}
                                      alt="profile picture"
                                    />
                                  </a>
                                ) : (
                                  ""
                                )}
                              </figure>
                            </a>
                          </div>
                          <div class="unorder-list-info">
                            <h5 class="list-title">
                              <a> Friend Suggestions </a>
                            </h5>
                            <h3 class="list-title">
                              <a
                                href={`/#/moviedetail/${item.post.id}`}
                                target="_blank"
                              >
                                {item.post.title}
                              </a>
                            </h3>
                            <p class="list-subtitle">
                              {th.dateCheck(item.createdAt)}
                            </p>
                          </div>
                        </li>
                      ) : (
                        ""
                      )}

                      {item.user &&
                      item.type == "friendReq" &&
                      th.state.userId == item.notifyUser ? (
                        <li key={item.id} class="unorder-list">
                          <div class="profile-thumb">
                            <a>
                              <figure class="profile-thumb-small">
                                {item.user.avatar ? (
                                  <a
                                    href={`#/user-profile/${item.user.id}`}
                                    target="_blank"
                                  >
                                    <img
                                      src={th.imageUrl(item.user.avatar)}
                                      alt="profile picture"
                                    />
                                  </a>
                                ) : (
                                  ""
                                )}
                              </figure>
                            </a>
                          </div>
                          <div class="unorder-list-info">
                            <p class="list-title">
                              Friend Request 
                            </p>
                            <h6 class="list-title"> {item.user.firstName} {item.user.lastName} </h6>
                              <button type="button" className="req-button-succes"  onClick={() => {
                                            th.Accept(item);
                                        }} >
                                Accept 
                              </button>
                              <button type="button" className="req-button-decline"  onClick={() => {
                                            th.Reject(item);
                                        }}>
                                Reject 
                              </button>
                          
                            <p class="list-subtitle">
                              {th.dateCheck(item.createdAt)}
                            </p>
                          </div>
                        </li>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
            </ul>
          </div>
          {/* <audio controls  id="myAudio" style={{"visibility": "hidden"}}>
                    <source src={mp3} type="audio/ogg" />
                    <source src={mp3} type="audio/mp3" />
                </audio> */}
        </div>
      </aside>
    );
  }
}

export default LikeComponent;
