import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { apiHelper } from "./../Helper/apiHelper";
import NotificationSystem from "react-notification-system";
import Header from './Header';
import imageLoader from '../Image/loader.gif';
import './home.css';
import $ from 'jquery';
window.jQuery = $;

class Showvideos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addList: [],
            searchList: [],
            loader: false,
            search: ""
        };
        this._notificationSystem = React.createRef();
    }

    componentDidMount() {
        $("body").removeClass("transparent-header");
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.getRandomUser();
    }



    async getRandomUser() {
        var user = JSON.parse(localStorage.getItem('accessToken'));
        const notification = this._notificationSystem.current;
        this.setState({ loader: true });
        var result = await apiHelper("get", `api/posts?filter[where][type]=show&filter[limit]=10`, {}, null);
        this.setState({ loader: false });
        if (result.length > 0) {
            this.setState({
                addList: result
            })
            notification.addNotification({
                message: "Select shows you have seen!",
                level: "success",
                tr: "top , right",
            });
        } else {
            notification.addNotification({
                message: result.message,
                level: "warning",
                tr: "top , right",
            });
        }
    }


    async addFriend(id, index, type) {
        var user = JSON.parse(localStorage.getItem('accessToken'));
        var data = {
            userId: user.id,
            postId: id,
            postStatus: "binged"
        }
        if (type === "list") {
            var list = this.state.addList;
            list.splice(index, 1);
            this.setState({ addList: list })
        } else {
            var list = this.state.searchList;
            list.splice(index, 1);
            this.setState({ searchList: list })
        }

        const notification = this._notificationSystem.current;

        var resultData = await apiHelper("get", `api/showLists?filter[where][userId]=${user.id}&filter[where][postId]=${id}`, {}, null);
       
        if (resultData.length == 0) {
            var result = await apiHelper("post", `api/showLists`, data, null);
            if (result) {
                notification.addNotification({
                    message: "Add Show Successfully",
                    level: "success",
                    tr: "top , right",
                });
            } else {
                notification.addNotification({
                    message: "Something went wrong !",
                    level: "warning",
                    tr: "top , right",
                });
            }
        }
    }

    // http://localhost:8080/api/WebUsers?filter={%22where%22:{%22userName%22:{%22like%22:%22Tom*%22,%22options%22:%22i%22}}}

    imagePath = (avatar) => {
        var str = avatar;
        var n = str.includes("uploads/");
        if (n) {
            avatar = window.APIURL + avatar;
            return (
                <img src={avatar} alt="profile picture" />
            )
        } else {
            return (
                <img src={avatar} alt="profile picture" />
            )
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };


    async searchForUser() {
        var user = JSON.parse(localStorage.getItem('accessToken'));
        const notification = this._notificationSystem.current;
        this.setState({ loader: true });
        var result = await apiHelper("get", `api/posts?filter={"where":{"and":[{"title":{"like":"${this.state.search}*","options":"i"}},{"type":"show"}]}}`, {}, null);
        this.setState({ loader: false });
        if (result.length > 0) {
            this.setState({
                searchList: result
            })
            notification.addNotification({
                message: "Select shows you have seen!",
                level: "success",
                tr: "top , right",
            });
        } else {
            notification.addNotification({
                message: "No Record Find",
                level: "warning",
                tr: "top , right",
            });
        }
    }

    render() {
        var th = this;
        
        return (

            <div >
                <NotificationSystem ref={this._notificationSystem} />

                <main>

                    <div class="main-wrapper">
                        <Header />
                        <div class="container">
                            <div class="row">
                                <div class="users-list">
                                    <h5 className="h3New">You’re all signed up...</h5>
                                    <h1 className="h1New">Welcome to <span className="text-primary">bake.</span> What have you watched lately...</h1>

                                    <div class="mt-5 mb-4">
                                        <form class="search-form d-flex">
                                            <input name="search" class="form-control share-text-field" id="email" type="search" placeholder="Search your next binge..." aria-label="Search" value={this.state.search} onChange={this.onChange} />
                                            <span className="search-icon" onClick={(e) => {
                                                this.searchForUser(e);
                                            }}><img src="images/search-icon.png" alt="Search Icon" className="img-fluid" /></span>
                                        </form>
                                    </div>
                                    <div className="video-list mb-5">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="item">
                                                    <div className="image">
                                                        <img src="images/video1.png" alt="" className="img-fluid"/>
                                                    </div>
                                                    <div className="overlay-button">
                                                        <button className="btn btn-white">Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="item">
                                                    <div className="image">
                                                        <img src="images/video2.png" alt="" className="img-fluid"/>
                                                    </div>
                                                    <div className="overlay-button">
                                                        <button className="btn btn-white">Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="item">
                                                    <div className="image">
                                                        <img src="images/video3.png" alt="" className="img-fluid"/>
                                                    </div>
                                                    <div className="overlay-button">
                                                        <button className="btn btn-white">Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="item">
                                                    <div className="image">
                                                        <img src="images/video1.png" alt="" className="img-fluid"/>
                                                    </div>
                                                    <div className="overlay-button">
                                                        <button className="btn btn-white">Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="item">
                                                    <div className="image">
                                                        <img src="images/video2.png" alt="" className="img-fluid"/>
                                                    </div>
                                                    <div className="overlay-button">
                                                        <button className="btn btn-white">Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="item">
                                                    <div className="image">
                                                        <img src="images/video3.png" alt="" className="img-fluid"/>
                                                    </div>
                                                    <div className="overlay-button">
                                                        <button className="btn btn-white">Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>



                                    <div class="lets-Go">
                                        <div class="lets-inter">
                                            <Link to="" className="letgoAtag "> Finish  </Link>
                                        </div>
                                    </div>



                                </div>


                            </div>
                        </div>
                    </div>

                </main>

                {this.state.loader === true ?
                    <div class="Loader">
                        <img src={imageLoader} alt="profile picture" />
                    </div> : ""
                }

            </div>

        );
    }
}

export default Showvideos;
