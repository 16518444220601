import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import imageLoader from "../Image/loader.gif";
import { apiHelper} from "./../Helper/apiHelper";
import NotificationSystem from "react-notification-system";
import UserShowList from "./post/userShowList";
import Genre from "../staticPages/geners/Geners";
import $ from "jquery";
var moment = require("moment");
window.jQuery = $;
export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: "",
      loader: false,
      userId: "",

      bingedList: [],
      bingingList: [],
      upnextList: [],

      fixBingedList: [],
      fixBingingList: [],
      fixUpNextList: [],

      friendCount: 0,

      apiType: "all",
      role: "",
      selectType: "Binged",
      genresType: "dash",
      genresCheck: false,

      avatar : "",
      firstName : "",
      favShow : "",
      favMovie : "",
      
      userId: ""


    };
    this._notificationSystem = React.createRef();
  }
  async componentDidMount() {
    $("#2A").addClass("active1");
    $("body").removeClass("transparent-header");
    $("html, body").animate({ scrollTop: 0 }, "slow");

    let dataId = this.props.match.params;
     
    if(!dataId.id) return
    
    this.setState({ userId: dataId.id }, () => {
      // this.getData();
      this.getFriendCount(dataId.id );
      this.getData_firstload("binged");
      this.getData_firstload("binging");
      this.getData_firstload("upnext");
    });
    if (dataId) {
      var result = await apiHelper(
        "get",
        `api/WebUsers?filter[where][id]=${dataId.id}`,
        null
      );
      if (result.length > 0) {
        let user = result[0];

        this.setState({
          avatar:  user.avatar,
          firstName: user.firstName + " " + user.lastName,
          favShow : user.favShow,
          favMovie : user.favMovie
        });
      }
    } else {
      this.setState({ redirect: true });
    }


    
  }

  async refreshCall(){
    this.getData_firstload("binged");
    this.getData_firstload("binging");
    this.getData_firstload("upnext");
  }

  async getFriendCount(userId) {
    this.setState({ loader: true });
    var result = await apiHelper("get", `api/friendLists?filter[where][userId]=${userId}&filter[where][status]=true`, "", null);
    this.setState({ loader: false });
    if (result) {
        this.setState({
          friendCount: result.length
        })
    }
}

  SelectSection = (selection) => {
    var th = this;
    if (selection === "Binged") {
      this.setState({ selectType: "Binged", loader: true }, () => {
        th.getData();
      });
      $("#11A").addClass("activedash");
      $("#22A").removeClass("activedash");
      $("#33A").removeClass("activedash");
    } else if (selection === "Binging") {
      this.setState({ selectType: "Binging", loader: true }, () => {
        th.getData();
      });
      $("#22A").addClass("activedash");
      $("#11A").removeClass("activedash");
      $("#33A").removeClass("activedash");
    } else if (selection === "UpNext") {
      this.setState({ selectType: "Up Next", loader: true }, () => {
        th.getData();
      });
      $("#33A").addClass("activedash");
      $("#22A").removeClass("activedash");
      $("#11A").removeClass("activedash");
    }
  };

  async getData() {
    var th = this;
    var user = JSON.parse(localStorage.getItem("accessToken"));
    const notification = th._notificationSystem.current;
    th.setState({ loader: true, postList: [] });
    var data = {
      userId: th.state.userId,
      category: th.state.selectType.toLowerCase(),
    };
    var postStatus = th.state.selectType.toLowerCase();
    postStatus = postStatus.replace(/\s/g, "");
    var result = await apiHelper(
      "get",
      `api/showLists?filter[where][userId]=${th.state.userId}&filter[where][postStatus]=${postStatus}&filter[include][post]&filter[order]=id%20DESC`,
      data,
      null
    );
    this.setState({ loader: false });
    if (result) {
      this.setState({
        postList: result,
      });
    } else {
      notification.addNotification({
        message: "No Record Find",
        level: "warning",
        tr: "top , right",
      });
    }
  }

  async getData_firstload(select) {
    var th = this;
    var user = JSON.parse(localStorage.getItem("accessToken"));
    const notification = th._notificationSystem.current;
    th.setState({ loader: true, postList: [] });
    var data = {
      userId: th.state.userId,
      category: select,
    };
    var postStatus = select;
    postStatus = postStatus.replace(/\s/g, "");
    var result = await apiHelper(
      "get",
      `api/showLists?filter[where][userId]=${th.state.userId}&filter[where][postStatus]=${postStatus}&filter[include][post]&filter[order]=id%20DESC`,
      data,
      null
    );
    this.setState({ loader: false });
    if (result) {
      if (select === "binged") {
        this.setState({
          bingedList: result,
          fixBingedList: result,
        });
      } else if (select === "binging") {
        this.setState({
          bingingList: result,
          fixBingingList: result,
        });
      } else if (select === "upnext") {
        this.setState({
          upnextList: result,
          fixUpNextList: result,
        });
      }
    } else {
      notification.addNotification({
        message: "No Record Find",
        level: "warning",
        tr: "top , right",
      });
    }
  }


  async sendGenresData(genres){
    this.getGenresData(genres , 'binged');
		this.getGenresData(genres , 'binging');
		this.getGenresData(genres , 'upnext');
	
  }

  async getGenresData(genres, select) {
    var th = this;
    const notification = th._notificationSystem.current;
    th.setState({ loader: true, postList: [] });
    var data = {
      userId: th.state.userId,
      postStatus: select,
      genres: genres,
    };
    var result = await apiHelper("post", `api/user/getGenresData`, data, null);
    this.setState({ loader: false });
    if (result.status === 200) {
		if (result.posts) {
			if (select === "binged") {
			  this.setState({
				bingedList: result.posts,
			  });
			} else if (select === "binging") {
			  this.setState({
				bingingList: result.posts,
			  });
			} else if (select === "upnext") {
			  this.setState({
				upnextList: result.posts,
			  });
			}
			this.closeGeners();
    } else {
      notification.addNotification({
        message: "No Record Find",
        level: "warning",
        tr: "top , right",
      });
    }
  }
}

  Filter(e) {
    if (e.target.value == "genres") {
      this.openGeners();
    }
    if (e.target.value == "all") {
      this.componentDidMount();
    }
  }

  openGeners() {
    this.setState({
      genresCheck: true,
    });
  }
  closeGeners(e) {
    //this is just getting the value that is selected
    this.setState({
      genresCheck: false,
    });
  }
  imageUrl = (avatar) => {
    var str = avatar;
    var n = str.includes("uploads/");
    if (n) {
      avatar = window.APIURL + avatar;
      return avatar;
    } else {
      return avatar;
    }
  };

  dateCheck = (date) => {
    date = moment(date).fromNow();
    return date;
  };

  FilterMovieShow = (data) => {
    if (data === "all") {
      this.setState({
        bingedList: this.state.fixBingedList,
        bingingList: this.state.fixBingingList,
        upnextList: this.state.fixUpNextList,
        apiType: "all",
      });
      return;
    }

    if (data === "movie") {
      var bingedList = this.state.fixBingedList.filter(
        (word) => word.post.type == "movie"
      );
      var bingingList = this.state.fixBingingList.filter(
        (word) => word.post.type == "movie"
      );
      var upnextList = this.state.fixUpNextList.filter(
        (word) => word.post.type == "movie"
      );
      this.setState({
        bingedList,
        bingingList,
        upnextList,
        apiType: "movie",
      });
      return;
    }

    if (data === "show") {
      var bingedList = this.state.fixBingedList.filter(
        (word) => word.post.type == "show"
      );
      var bingingList = this.state.fixBingingList.filter(
        (word) => word.post.type == "show"
      );
      var upnextList = this.state.fixUpNextList.filter(
        (word) => word.post.type == "show"
      );
      this.setState({
        bingedList,
        bingingList,
        upnextList,
        apiType: "show",
      });
      return;
    }
  };

  render() {
    var user = JSON.parse(localStorage.getItem("accessToken"));
    if (user) {
  
    } else {
      return <Redirect to="/login" />;
    }

    return (
      <main>
        <NotificationSystem ref={this._notificationSystem} />
        <div class="main-wrapper">
          <div class="profile-menu-area " style={{ "padding-top": "70px" }}>
            <div class="container">
              <div class="row align-items-center">
                <div className="col-md-12">
                  <div class="profile-picture">
                    <a href="">
                      <img
                        
                        src={this.imageUrl(this.state.avatar)}
                        
                        
                        alt="profile picture"
                        style={{ width: "200px", height: "200px" }}
                      />
                    </a>
                  </div>
                  <h3 className="user-name">
                    {this.state.firstName}
                  </h3>
                  <div className="profile-tags d-flex">
                    <div className="d-flex">
                      <span>{this.state.favShow}</span>
                    </div>
                    <div className="d-flex">
                      <span>{this.state.favMovie}</span>
                    </div>
                  </div>
                  <div className="friends-btn">
                    <a href={`#/user-friendList/${this.state.userId}`} className="btn btn-grey">
                      <span className="fa fa-user"> </span> {this.state.friendCount} Friends
                    </a>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="profile-tags-links">
                    <div className="links-tags">
                      <div className="btn-wrapper">
                        <button
                          onClick={() => {
                            this.FilterMovieShow("all");
                          }}
                          type="button"
                          class={`post-share-btn ${
                            this.state.apiType === "all" ? "active" : ""
                          } `}
                        >
                          All
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            this.FilterMovieShow("movie");
                          }}
                          type="button"
                          class={`post-share-btn ${
                            this.state.apiType === "movie" ? "active" : ""
                          } `}
                        >
                          Movies
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            this.FilterMovieShow("show");
                          }}
                          type="button"
                          class={`post-share-btn ${
                            this.state.apiType === "show" ? "active" : ""
                          } `}
                        >
                          TV Shows
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div className="heading">
                  <h3>Binged</h3>
                </div>
              </div>
              <div class="col-lg-12">
                <div className="movies-list ">
                  <ul>
                    <UserShowList
                      selectType={this.state.selectType}
                      postList={this.state.bingedList}
                      refreshCall={(e) => this.refreshCall()}
                      popupCheck = {false}
                    />
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div className="heading">
                  <h3>Binging</h3>
                </div>
              </div>
              <div class="col-lg-12">
                <div className="movies-list">
                  <ul>
                    <UserShowList
                      selectType={this.state.selectType}
                      postList={this.state.bingingList}
                      refreshCall={(e) => this.refreshCall()}
                      popupCheck = {false}
                    />
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div className="heading">
                  <h3>Up Next</h3>
                </div>
              </div>
              <div class="col-lg-12">
                <div className="movies-list">
                  <ul>
                    <UserShowList
                      selectType={this.state.selectType}
                      postList={this.state.upnextList}
                      refreshCall={(e) => this.refreshCall()}
                      popupCheck = {false}
                    />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.loader === true ? (
          <div class="Loader">
            <img src={imageLoader} alt="profile picture" />
          </div>
        ) : (
          ""
        )}
        {this.state.genresCheck ? (
          <Genre
            genresType={this.state.genresType}
            getGenresData={(e) => this.sendGenresData(e)}
            closeGeners={(e) => this.closeGeners(e)}
          ></Genre>
        ) : (
          ""
        )}
      </main>
    );
  }
}
