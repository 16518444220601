import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { apiHelper, apiMultipart } from "../../Helper/apiHelper";
import NotificationSystem from "react-notification-system";


class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            userId:'',
        }
        this._notificationSystem = React.createRef();
    }
    async componentDidMount() {
        const user = JSON.parse(localStorage.getItem("accessToken"));
        if (user) {
           this.setState({userId : user.id})
        }
    }



    onSubmit = async (e) => {
        const {
            message,
            userId,
        } = this.state;
    
        e.preventDefault();
        const notification = this._notificationSystem.current;
      
        if (message === "") {
          notification.addNotification({
            message: "Message is Required",
            level: "warning",
            tr: "top , right",
          });
        }    else {
          this.setState({ loaderActive: true });
          this.setState({ errors: "" });
          var data = {
            message: message,
            userId: userId
          }
          var result = await apiHelper(
            "post",
            `api/messages`,
            data,
            null
          );
          if (result) {
            notification.addNotification({
              message: "Message Created Successfully !",
              level: "success",
              tr: "top , right",
            });
            this.props.AddMessageClose();
          } else {
            notification.addNotification({
              message: "Something went wrong Try again Later!",
              level: "warning",
              tr: "top , right",
            });
          }
        }
      };
    
      onChange = (e) => {
        this.setState({
          [e.target.name]: e.target.value,
        });
      };

    Close = (e) => {
        this.props.AddMessageClose();
    }

    render() {
        return (

            <div class="modal fade show" id="textbox12" aria-labelledby="textbox" style={{ "padding-right": "15px", "display": "block" }} aria-modal="true" >
               <NotificationSystem ref={this._notificationSystem} />
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Add Message</h5>
                            <button type="button" class="close" onClick={(e) => this.Close(e)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body custom-scroll">
                            <div class="row">
                                <div className="col-md-12">
                                <div class="input-group">
                                    <textarea name="message" onChange={this.onChange} class="share-field-big custom-scroll" placeholder="Message"></textarea>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" onClick={(e) => this.Close(e)} class="post-share-btn" >cancel</button>
                            <button type="button"   onClick={(e) => {
                                  this.onSubmit(e);
                                }} class="post-share-btn">Create</button>
                        </div>
                    </div>
                </div>
            </div>



        );
    }
}

export default Footer;

