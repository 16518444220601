import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { apiHelper } from "./../Helper/apiHelper";
import Home from './Home';
import Header from './Header';
import Footer from './Footer';
import NotificationSystem from "react-notification-system";
import imageLoader from "../Image/loader.gif";
import "./home.css";
class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: "",
      redirect: false,
      loader: false,
    };
    this._notificationSystem = React.createRef();
  }

  addNotification = (event) => {
    var th = this;
    event.preventDefault();
    const notification = th._notificationSystem.current;
    notification.addNotification({
      message: "Notification message",
      level: "success",
      tr: "top , right",
    });
  };

  async componentWillMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const myParam = urlParams.get("t");
    if (myParam) {
      var result = await apiHelper(
        "get",
        `api/WebUsers?filter[where][token_check]=${myParam}`,
        null
      );
      if (result.length > 0) {
        ;
        
        localStorage.setItem("accessToken", JSON.stringify(result[0]));
        this.setState({ redirect: true });
      }
    }
  }

  componentWillUpdate(nextProps, nextState) { }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = async (e) => {
    const { email, password } = this.state;
    e.preventDefault();
    const notification = this._notificationSystem.current;
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.setState({ loader: false });
    if (email === "") {
      notification.addNotification({
        message: "Email is Required",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else if (!email.match(regexp)) {
      notification.addNotification({
        message: "Email is Invalid",
        level: "warning",
        tr: "top , right",
      });

      return;
    } else if (password === "") {
      notification.addNotification({
        message: "Password is Required",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else {
      this.setState({ loader: true });
      this.setState({ errors: "" });
      var data = {
        email: email,
        password: password,
      };
      var result = await apiHelper("post", "login", data, null);
      
      this.setState({ loader: false });
      if (result.status === 403) {
        notification.addNotification({
          message: result.title,
          level: "warning",
          tr: "top , right",
        });
      } else if (result.status === 200) {
        
        // ;
        localStorage.setItem(
          "LoginSession",
          JSON.stringify(result.accessToken)
        );
        localStorage.setItem("accessToken", JSON.stringify(result.user));
        localStorage.setItem("loginTime", new Date().getTime());
        notification.addNotification({
          message: "Login Successfully !",
          level: "success",
          tr: "top , right",
        });
        this.setState({ redirect: true });
      } else {
        notification.addNotification({
          message: "Incorrect email or password !",
          level: "warning",
          tr: "top , right",
        });
        return;
      }
    }
  };

  onSocialLogin = (path) => {
    window.location.href = window.APIURL + path;
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    const user = JSON.parse(localStorage.getItem("accessToken"));
    if(user){
      return ( <div id="wrapper" >
      <Header/>
      <Home/>
      <Footer/>
    </div>);
    }else{
    return (
      <main>
        <div class="main-wrapper pb-0 mb-0">
          <div class="timeline-wrapper">
            <NotificationSystem ref={this._notificationSystem} />
            <div class="timeline-page-wrapper new-bg">
              <div class="container-fluid p-0">
                <div class="row no-gutters">
                  {/* <div class="col-lg-6 order-2 order-lg-1">
									<div class="timeline-bg-content bg-img" data-bg="assets/images/timeline/adda-timeline.jpg">
										<h3 class="timeline-bg-title"> Welcome To Bake.</h3>
									</div>
								</div> */}
                  <div className="col-md-6">
                    <div className="login-info">
                      <div className="image">
                        <img src="images/pablita-cinema.png" alt="" className="img-fluid" />
                      </div>
                      <div className="steps">
                        <div className="item">
                          <p>Share what you’re<br /> binging with friends.</p>
                        </div>
                        <div className="item">
                          <p>Create and share groups<br />  to stay in the know.</p>
                        </div>
                        <div className="item">
                          <p>Find new movies and tv shows<br /> and add them to your profile</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 d-flex align-items-center justify-content-start">
                    <div class="signup-form-wrapper login">
                      <div className="form-title text-center welcome">
                        <h1 class="create-acc text-center">Welcome to <span className="text-blue">bake.</span></h1>
                        <p className="sub-heading">Great taste in every byte</p>
                      </div>

                      <div class="signup-inner text-center">
                        <form
                          class="signup-inner--form"
                          style={{ "background-color": "#fff0 !important" }}
                        >
                          <div className="scroll-section">
                           
                          </div>
                          <div className="action s-wrapper">
                            <a href="#/register"
                              class="submit-btn"
                            >
                              SIGN UP
                            </a>
                            <a href="#/login"
                              class="submit-btn light"
                            >
                              LOGIN
                            </a>
                            
                    
                          </div>


                        </form>
                        {this.state.loader === true ? (
                          <div class="Loader">
                            <img src={imageLoader} alt="profile picture" />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main >
    );
  }
}
}

export default Landing;
