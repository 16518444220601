import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { apiHelper } from "../../Helper/apiHelper";
import NotificationSystem from "react-notification-system";
import Post from '../post/postListing';
import ShowMoviesPost from '../post/showMovies';
import imageLoader from '../../Image/loader.gif';
import Notification from '../../staticPages/Notification';
import { AddFriend } from "../notification/create-notification";
import AddFriendComponent from '../AddFriend/addFriend';

import $ from 'jquery';
window.jQuery = $;
class Search extends Component {


    constructor(props) {
        super(props);
        this.state = {
            addList: [],
            searchList: [],
            loader: false,
            search: '',
            apiType: "show",
            searchType: "popular",

            userCount: 0,
            postCount: 0,
            moviesCount: 0,
            showCount: 0,

            userLimit: 0,
            postLimit: 0,
            moviesLimit: 0,
            showLimit: 0

        };
        this._notificationSystem = React.createRef();
    }



    componentDidMount() {
        const urlParams = new URLSearchParams(this.props.location.search);
        const myParam = urlParams.get("search");
        const more = urlParams.get("more");
        if(myParam){
            this.setState({search : myParam} , () => {
                this.post("show");
            })
        }else if(more){
            this.setState({ apiType : "friend"})
            this.friends();
        }else{
            this.post("show");
        }
        $("body").removeClass("transparent-header");
        $("html, body").animate({ scrollTop: 0 }, "slow");
        // this.post("show");
        // this.friends();
    }


    addFriend = async (id, index, type) => {
        var user = JSON.parse(localStorage.getItem('accessToken'));
        var data = {
            userId: user.id,
            friendId: id
        }
        if (type === "list") {
            var list = this.state.addList;
            list.splice(index, 1);
            this.setState({ addList: list })
        } else {
            var list = this.state.searchList;
            list.splice(index, 1);
            this.setState({ searchList: list })
        }
        const notification = this._notificationSystem.current;
        let notifyCheck = 0;
        var resultData = await apiHelper("get", `api/friendLists?filter[where][friendId]=${id}&filter[where][userId]=${user.id}`, {}, null);
        
        if (resultData.length == 0) {
            var result = await apiHelper("post", `api/friendLists`, data, null);
            notifyCheck = 1;
            if (result) {
            } else {
                notification.addNotification({
                    message: "Something went wrong !",
                    level: "warning",
                    tr: "top , right",
                });
            }
        }
        var user1 = JSON.parse(localStorage.getItem('accessToken'));
        var data1 = {
            userId: id,
            friendId: user1.id
        }
        var resultData = await apiHelper("get", `api/friendLists?filter[where][friendId]=${user1.id}&filter[where][userId]=${data1.userId}`, {}, null);
        if (resultData.length == 0) {
            var result = await apiHelper("post", `api/friendLists`, data1, null);
            if (result) {
            } else {
                notification.addNotification({
                    message: "Something went wrong !",
                    level: "warning",
                    tr: "top , right",
                });
            }
        }
        
        if(notifyCheck === 1){
            const fina_obj = {
                userId: data.userId,
                type: "friendReq",
                friendId: data.friendId,
            };
            AddFriend(fina_obj);
        }

    }

    async friends() {
        var { userCount, userLimit } = this.state;
        if (userLimit == 0) {
            var hi = 0;
        } else {
            var hi = $(window).height();
        }
        userLimit = 10 + userLimit;
        var user = JSON.parse(localStorage.getItem('accessToken'));
        const notification = this._notificationSystem.current;
        var search = this.state.search == "" ? "" : this.state.search;
        var data = {
            search: search,
            userId: user.id,
            limit: userLimit

        }
        this.setState({ loader: true });
        var result = await apiHelper("post", `api/get/search/users`, data, null);
        $("html, body").animate({ scrollTop: hi }, "slow");
        this.setState({ loader: false });
        if (result.status === 200) {
            this.setState({
                searchList: result.users,
                userLimit: userLimit
            })
            this.friendCount();
            // notification.addNotification({
            //     message: "Who Do You Know ?",
            //     level: "success",
            //     tr: "top , right",
            // });
        } else {
            notification.addNotification({
                message: "No Record Find",
                level: "warning",
                tr: "top , right",
            });
        }
    }


    async friendCount() {
        var user = JSON.parse(localStorage.getItem('accessToken'));
        const notification = this._notificationSystem.current;
        var search = this.state.search == "" ? "" : this.state.search;
        var data = {
            search: search,
            userId: user.id
        }
        var result = await apiHelper("post", `api/get/search/users/count`, data, null);
        this.setState({ loader: false });
        if (result.status === 200) {
            this.setState({
                userCount: result.users
            })
        } else {
            notification.addNotification({
                message: "No Record Find",
                level: "warning",
                tr: "top , right",
            });
        }
    }


    async post(type) {

        var { postCount, postLimit } = this.state;
        if (postLimit == 0) {
            var hi = 0;
        } else {
            var hi = $(window).height();
        }

        postLimit = 10 + postLimit;
        var user = JSON.parse(localStorage.getItem('accessToken'));
        const notification = this._notificationSystem.current;
        this.setState({ loader: true });
        var data = {
            search: this.state.search,
            type: type,
            limit: postLimit,
            searchType : this.state.searchType,
            userId: user.id
        }
        var result = await apiHelper("post", `api/get/search/posts`, data, null);

        this.setState({ loader: false });
        if (result.status === 200) {
            $("html, body").animate({ scrollTop: hi }, "slow");
            this.setState({
                searchList: result.posts,
                postLimit: postLimit
            })
            this.postCount(type);
        } else {
            notification.addNotification({
                message: "No Record Find",
                level: "warning",
                tr: "top , right",
            });
        }
    }

    async postCount(type) {
        var user = JSON.parse(localStorage.getItem('accessToken'));
        const notification = this._notificationSystem.current;

        var data = {
            search: this.state.search,
            type: type
        }
        var result = await apiHelper("post", `api/get/search/count/posts`, data, null);
        this.setState({ loader: false });

        if (result.status === 200) {

            this.setState({
                postCount: result.posts
            })
            // notification.addNotification({
            //     message: "Data Retrive Successfully!",
            //     level: "success",
            //     tr: "top , right",
            // });
        } else {
            notification.addNotification({
                message: "No Record Find",
                level: "warning",
                tr: "top , right",
            });
        }
    }



    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };



    onChangeType1 = (event) => {
        this.setState({
            loader: true,
            userCount: 0,
            postCount: 0,
            moviesCount: 0,
            showCount: 0,
            userLimit: 0,
            postLimit: 0,
            moviesLimit: 0,
            showLimit: 0,
            searchList: []
        }, () => {
            var value = this.state.apiType;
            if (value === "friend") {
                this.friends();
            } else {
                this.post(value);
            }
        })
    };


    onChangeType = (event) => {
        if (event.key === 'Enter') {

            this.setState({
                loader: true,
                userCount: 0,
                postCount: 0,
                moviesCount: 0,
                showCount: 0,
                userLimit: 0,
                postLimit: 0,
                moviesLimit: 0,
                showLimit: 0,
                searchList: []
            }, () => {
                var value = this.state.apiType;
                if (value === "friend") {
                    this.friends();
                } else {
                    this.post(value);
                }

            })
        }
    };
    async searchForUser(value) {
        this.setState({
            loader: true,
            userCount: 0,
            postCount: 0,
            moviesCount: 0,
            showCount: 0,
            userLimit: 0,
            search: '',
            postLimit: 0,
            moviesLimit: 0,
            showLimit: 0,
            apiType: value,
            searchList: [],
        }, () => {
            if (value === "friend") {
                this.friends();
            } else if (value === "post") {
                this.post(value);
            } else if (value === "show") {
                this.post(value);
            } else if (value === "movie") {
                this.post(value);
            }
        });
    }

    async typeSearch(data) {
       this.setState({searchType : data}, () => {
            this.searchForUser(this.state.apiType);
       })
    }

    imagePath = (avatar) => {
        var str = avatar;
        var n = str.includes("uploads/");
        if (n) {
            avatar = window.APIURL + avatar;
            return (
                <img src={avatar} alt="profile picture" />
            )
        } else {
            return (
                <img src={avatar} alt="profile picture" />
            )
        }
    }

    removeIndex = (index) => {
        const array = [...this.state.searchList];
        array.splice(index, 1);
        this.setState({searchList: array}); 

    }

    render() {
        var th = this;
        var user = JSON.parse(localStorage.getItem('accessToken'));
        if (user) {
            var avatar = user.avatar;
            var str = avatar;
            var n = str.includes("uploads/");
            if (n) {
                avatar = window.APIURL + avatar;
            }
        } else {
            return <Redirect to="/login" />;
        }
        return (

            <main>
                <NotificationSystem ref={this._notificationSystem} />
                <div class="main-wrapper pt-80">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 order-1 order-lg-1">
                                <div className="sidebar">
                                    <h2 className="sidebar-title">
                                    <a className="view-alls" href="#/tvClub?type=tvclub">View TV Clubs  <span className="fa fa-arrow-right"></span></a>
                                    </h2>
                                </div>
                            </div>
                            <div class="col-lg-6 order-1 order-lg-2">
                                <div class="card card-small">
                                    {/* <p className="post-text" >Watching Something? Post About It.</p> */}

                                    <div class="share-box-inner">
                                        <div class="profile-thumb">
                                            <a href="#">
                                                <figure class="profile-thumb-middle">
                                                    <img src={avatar} alt="profile picture" />
                                                </figure>
                                            </a>
                                        </div>
                                        <div class="share-content-box w-100">
                                            <div class="share-text-box hideOn_sm">
                                                <input value={this.state.search} onChange={this.onChange} onKeyPress={this.onChangeType} id="email" name="search" class="share-text-field" placeholder="Search Friends, Shows, Genres and More!" />
                                                <button class="btn-share" onClick={(e) => {
                                                    this.onChangeType1(e);
                                                }} type="button">Search</button>
                                            </div>
                                            <div class="share-text-box showOn_sm">
                                                <input value={this.state.search} onChange={this.onChange} onKeyPress={this.onChangeType} id="email" name="search" class="share-text-field" placeholder="Search Anything" />
                                                <button class="btn-share" onClick={(e) => {
                                                    this.onChangeType1(e);
                                                }} type="button">Search</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="filters">
                                    <div className="btn-wrapper mr-3">
                                        <button type="button" onClick={() => {
                                            this.typeSearch("popular");
                                        }} class={`post-share-btn ${this.state.searchType === "popular" ? "active" : ""} `}
                                        >Popular</button>
                                        <button type="button" onClick={() => {
                                            this.typeSearch("recent");
                                        }} class={`post-share-btn ${this.state.searchType === "recent" ? "active" : ""} `} >New to Streaming</button>

                                    </div>
                                    <div className="btn-wrapper">
                                        <button type="button" onClick={() => {
                                            this.searchForUser("friend");
                                        }} class={`post-share-btn ${this.state.apiType === "friend" ? "active" : ""} `}
                                        >New Friends</button>
                                        <button type="button" onClick={() => {
                                            this.searchForUser("post");
                                        }} class={`post-share-btn ${this.state.apiType === "post" ? "active" : ""} `} >Posts</button>
                                        <button type="button" onClick={() => {
                                            this.searchForUser("show");
                                        }} class={`post-share-btn ${this.state.apiType === "show" ? "active" : ""} `} >Shows</button>
                                        <button type="button" onClick={() => {
                                            this.searchForUser("movie");
                                        }} class={`post-share-btn ${this.state.apiType === "movie" ? "active" : ""} `}>Movies</button>
                                    </div>

                                </div>
                                {this.state.apiType === "post" ? <>
                                    <Post postList={this.state.searchList} />
                                    {this.state.postCount > this.state.postLimit ?
                                        <div class="share-content-box box-content-custom" style={{ "text-align": "center" }}>
                                            <button type="button" onClick={() => {
                                                this.post("post");
                                            }} class="post-share-btn" >Load More</button>
                                        </div>
                                        : ""}
                                </> : ""}
                                {this.state.apiType === "friend" ?
                                    <>{
                                        this.state.searchList.map(function (item, i) {
                                            return (

                                                <AddFriendComponent key={item.id} i={i} imagePath={th.imagePath} addFriend={th.addFriend}  item={item} />
                                                // <div key={i} class="card card-small">
                                                //      <div class="share-box-inner">
                                                //         <div class="profile-thumb">
                                                //             <a href={`#/user-profile/${item.id}`}>
                                                //                 <figure class="profile-thumb-middle">
                                                //                     {th.imagePath(item.avatar)}

                                                //                  </figure>
                                                //             </a>
                                                //         </div>

                                                //         <div style={{ "text-align": "initial", "margin-top": "16px" }} class="share-content-box w-100">
                                                //             <form class="share-text-box">
                                                //                 <h6 class="author"><a href={`#/user-profile/${item.id}`}>{item.firstName} {item.lastName}</a></h6>
                                                //                 <button class="btn-share" type="button" onClick={(e) => {
                                                //                     th.addFriend(item.id, i, "search");
                                                //                 }} >Add</button>
                                                //             </form>
                                                //         </div>
                                                //     </div>
                                                // </div> 
                                            )
                                        })}
                                        {this.state.userCount > this.state.userLimit ?
                                            <div class="share-content-box box-content-custom" style={{ "text-align": "center" }}>
                                                <button type="button" onClick={() => {
                                                    this.friends("friend");
                                                }} class="post-share-btn" >Load More</button>
                                            </div> : ""}
                                    </>
                                    : ""}
                                {this.state.apiType === "movie" || this.state.apiType === "show" ?
                                    <>
                                        <ShowMoviesPost postList={this.state.searchList}  removeIndex={this.removeIndex} />
                                        {this.state.apiType === "movie" ?
                                            this.state.postCount > this.state.postLimit ?
                                                <div class="share-content-box box-content-custom" style={{ "text-align": "center" }}>
                                                    <button type="button" onClick={() => {
                                                        this.post("movie");
                                                    }} class="post-share-btn" >Load More</button>
                                                </div> : "" : ""}
                                        {this.state.apiType === "show" ?
                                            this.state.postCount > this.state.postLimit ?
                                                <div class="share-content-box box-content-custom" style={{ "text-align": "center" }}>
                                                    <button type="button" onClick={() => {
                                                        this.post("show");
                                                    }} class="post-share-btn" >Load More</button>
                                                </div> : "" : ""}
                                    </>
                                    : ""

                                }
                            </div>
                            <div class="col-lg-3 order-3 mobileNotif">
                            <Notification currentChat={this.state.currentChat}></Notification>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.loader === true ?
                    <div class="Loader">
                        <img src={imageLoader} alt="profile picture" />
                    </div> : ""
                }
            </main>
        );
    }
}

export default Search;
