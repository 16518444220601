import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Select from "react-select";
import countryList from "react-select-country-list";
import { apiHelper } from "./../Helper/apiHelper";
import NotificationSystem from "react-notification-system";
import imageLoader from "../Image/loader.gif";
import "./home.css";
class Register extends Component {
  constructor(props) {
    super(props);
    this.options = countryList().getData();
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      gender: "",
      age: "",
      options: this.options,
      country: "",
      countryCode: "",
      value: null,
      redirect: false,
      loader: false,
    };
    this._notificationSystem = React.createRef();
    this.options = countryList().getData();
  }
  addNotification = (event) => {
    var th = this;
    event.preventDefault();
    const notification = th._notificationSystem.current;
    notification.addNotification({
      message: "Notification message",
      level: "success",
      tr: "top , right",
    });
  };

  componentWillMount() {
    // var token = localStorage.getItem("accessToken");
    // if (token) {
    //   var loginTime = parseInt(localStorage.getItem("loginTime")) / 1000;
    //   var timeNow = new Date().getTime() / 1000;
    //   var timeDifference = timeNow - loginTime;
    //   var timeDifferenceInMinutes = timeDifference / 60;
    //   if (timeDifferenceInMinutes < window.loginExpiresAfter) {
    //     this.setState({ redirect: true });
    //   } else {
    //     this.setState({ redirect: false });
    //   }
    // } else {
    //   this.setState({ redirect: false });
    // }
  }

  componentWillUpdate(nextProps, nextState) { }
  changeHandler = (value) => {
    this.setState({
      value: value,
      country: value.label,
      countryCode: value.value,
    });
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onSubmit = async (e) => {
    var th = this;
    e.preventDefault();
    const {
      email,
      firstName,
      lastName,
      password,
      confirmPassword,
      gender,
      age,
      country,
      countryCode,
    } = this.state;
    const notification = th._notificationSystem.current;
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === "") {
      notification.addNotification({
        message: "Email is Required",
        level: "warning",
        tr: "top , right",
      });

      return;
    } else if (!email.match(regexp)) {
      notification.addNotification({
        message: "Invalid Email",
        level: "warning",
        tr: "top , right",
      });

      return;
    } else if (firstName === "") {
      notification.addNotification({
        message: "FirstName is Required",
        level: "warning",
        tr: "top , right",
      });

      return;
    } else if (lastName === "") {
      notification.addNotification({
        message: "LastName is Required",
        level: "warning",
        tr: "top , right",
      });

      return;
    } else if (password === "") {
      notification.addNotification({
        message: "Password is Required",
        level: "warning",
        tr: "top , right",
      });

      return;
    } else if (password.length < 8) {
      notification.addNotification({
        message: "Password should Greater than 8 characters",
        level: "warning",
        tr: "top , right",
      });
      return;
    } else if (password !== confirmPassword) {
      notification.addNotification({
        message: "Password doesn't match",
        level: "warning",
        tr: "top , right",
      });

      return;
    } else if (confirmPassword === "") {
      notification.addNotification({
        message: "Password Confirmation is Required",
        level: "warning",
        tr: "top , right",
      });

      return;
    } else if (gender == "") {
      notification.addNotification({
        message: "Gender is Required",
        level: "warning",
        tr: "top , right",
      });

      return;
    } else if (age == "") {
      notification.addNotification({
        message: "Age is Required",
        level: "warning",
        tr: "top , right",
      });

      return;
    } else if (country == "") {
      notification.addNotification({
        message: "Country Selection is Required",
        level: "warning",
        tr: "top , right",
      });

      return;
    } else {
      this.setState({ loader: true });
      this.setState({ errors: "" });
      var data = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        password: password,
        confirmPassword: confirmPassword,
        gender: gender,
        age: age,
        country: country,
        countryCode: countryCode,
      };
      var result = await apiHelper("post", "register", data, null);
      this.setState({ loader: false });
      if (result.status === 403) {
        notification.addNotification({
          message: result.message,
          level: "warning",
          tr: "top , right",
        });
      } else if (result.status === 200) {
        localStorage.setItem(
          "LoginSession",
          JSON.stringify(result.accessToken)
        );
        localStorage.setItem("accessToken", JSON.stringify(result.user));
        localStorage.setItem("loginTime", new Date().getTime());
        notification.addNotification({
          message: result.message,
          level: "warning",
          tr: "top , right",
        });
        this.setState({ redirect: true });
      } else if (result.status === 500) {
        notification.addNotification({
          message: result.message,
          level: "warning",
          tr: "top , right",
        });
      }
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/friendSelection" />;
    }
    return (
      <main>
        <NotificationSystem ref={this._notificationSystem} />
        <div class="main-wrapper pb-0 mb-0">
          <div class="timeline-wrapper">
            <div class="timeline-header"></div>
            <div class="timeline-page-wrapper new-bg">
              <div class="container-fluid p-0">
                <div class="row no-gutters">
                  <div className="col-md-6">
                    <div className="login-info">
                      <div className="image">
                        <img src="images/pablita-cinema.png" alt="" className="img-fluid" />
                      </div>
                      <div className="steps">
                        <div className="item">
                          <p>Share what you’re<br /> binging with friends.</p>
                        </div>
                        <div className="item">
                          <p>Create and share groups<br /> to stay on top of things.</p>
                        </div>
                        <div className="item">
                          <p>Find new movies and tv shows<br /> and add them to your profile</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 d-flex align-items-center justify-content-center">
                    <div class="signup-form-wrapper">
                      <div class="signup-inner text-center">
                        <div className="form-title">
                          <h1 class="create-acc text-left text-blue">bake.</h1>
                          <p className="sub-heading">Sign up below</p>
                        </div>
                        <form class="signup-inner--form signup-inner--form1">
                          <div className="scroll-section">
                            <div class="row">
                              <div class="form-group col-12">
                              <label for="exampleInputEmail1">Email</label>
                                <input
                                  type="email"
                                  name="email"
                                  class="single-field"
                                  // placeholder="Email"
                                  value={this.state.email}
                                  onChange={this.onChange}
                                />
                              </div>
                              <div class="form-group col-md-6">
                              <label for="exampleInputEmail1">First Name</label>
                                <input
                                  name="firstName"
                                  value={this.state.firstName}
                                  onChange={this.onChange}
                                  type="text"
                                  class="single-field"
                                  // placeholder="First Name"
                                />
                              </div>
                              <div class="form-group col-md-6">
                              <label for="exampleInputEmail1">Last Name</label>
                                <input
                                  name="lastName"
                                  value={this.state.lastName}
                                  onChange={this.onChange}
                                  type="text"
                                  class="single-field"
                                  // placeholder="Last Name"
                                />
                              </div>
                              <div class="form-group col-md-6">
                              <label for="exampleInputEmail1">Password</label>
                                <input
                                  name="password"
                                  value={this.state.password}
                                  onChange={this.onChange}
                                  type="password"
                                  class="single-field"
                                  // placeholder="Password"
                                />
                              </div>

                              <div class="form-group col-md-6">
                              <label for="exampleInputEmail1">Confirm Password</label>
                                <input
                                  name="confirmPassword"
                                  value={this.state.confirmPassword}
                                  onChange={this.onChange}
                                  type="password"
                                  class="single-field"
                                  // placeholder="Confirm Password"
                                />
                              </div>
                              <div className="custom-hr"><hr className="w-260"/> </div>
                              

                              <div class="form-group col-md-3 m-0">
                              <label for="exampleInputEmail1">Age</label>
                                {/* <InputRange
																maxValue={20}
																minValue={0}
																value={this.state.value}
																onChange={value => this.setState({ value })} /> */}
                                <select
                                  class="nice-select"
                                  name="age"
                                  id="age"
                                  value={this.state.age}
                                  onChange={this.onChange}
                                >
                                  <option value="">Age</option>
                                  <option value="18+">18+</option>
                                  <option value="18-">18-</option>
                                </select>
                              </div>
                              <div class="form-group col-md-6">
                              <label for="exampleInputEmail1">Country of Residence</label>
                                <Select
                                  className="county_slect"
                                  options={this.state.options}
                                  value={this.state.value}
                                  onChange={this.changeHandler}
                                />
                              </div>
                              <div class="form-group col-md-6">
                              <label for="exampleInputEmail1">Gender</label>
                                <select
                                  id="gender"
                                  class="nice-select"
                                  name="gender"
                                  value={this.state.gender}
                                  onChange={this.onChange}
                                 >
                                  <option value="">Select Gender </option>
                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                  <option value="other">Other</option>
                                </select>
                              </div>

                            </div>

                          </div>

                          <div className="action s-wrapper">
                            <button
                              className="submit-btn"
                              type="button"
                              onClick={(e) => {
                                this.onSubmit(e);
                              }}
                            >
                              Create Account
                            </button>
                            <h6 class="terms-condition">
                              If you already have a acount{" "}
                              <Link to="/login">Login</Link>
                            </h6>
                          </div>

                        </form>
                        {this.state.loader === true ? (
                          <div class="Loader">
                            <img src={imageLoader} alt="profile picture" />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Register;
