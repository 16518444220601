import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { apiHelper, apiMultipart } from "./../../Helper/apiHelper";
import mp3 from '../../Image/pristine-609.mp3';
import { socket } from "./create-notification";
import NotificationSystem from "react-notification-system";
import $ from 'jquery';
var moment = require('moment');
window.jQuery = $;

class LikeComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: '',
            loader: false,
            userId: '',
            postList: [],
            templatesArray: [],
            readCount: 0,
            role: "",
        }
        this._notificationSystem = React.createRef();
    }
    async componentDidMount() {
        var x = document.getElementById("myAudio"); 


     
        $("body").removeClass("transparent-header");
        $("html, body").animate({ scrollTop: 0 }, "slow");
        const user = JSON.parse(localStorage.getItem("accessToken"));
       
        let userId = user.id;
        this.setState({ userId });
        socket.on("new-tv-club-notification-output", data => {
            // x.play(); 
            
            this.setState({
                postList: data
            })
            var temp = [];
         
            
            for (var i = 0; i < data.length; i++) {
               
                if (data[i].read == false && userId == data[i].notifyUser) {
                    temp.push("");
                }
            }
            this.setState({
                readCount: temp.length
            })
        })
       this.getOldNotification();
        
    }

    async getOldNotification(){
       
        const user = JSON.parse(localStorage.getItem("accessToken"));
       
        let userId = user.id;
        var x = document.getElementById("myAudio"); 
        var data1 = await apiHelper(
            "get",
            `api/tvClubNotifications?filter[include]=post&filter[include]=user&filter[include]=tvClub`,
            null
        );
        // x.play(); 
        this.setState({
            postList: data1
        })
        var temp = [];
        var play = 0;
        for (var i = 0; i < data1.length; i++) {
           
            if (data1[i].read == false && userId == data1[i].notifyUser) {
                if (play == 0){
                    // x.play();
                }
                play = 1;
                temp.push("");
            }
        }
        this.setState({
            readCount: temp.length
        })
    }
    componentWillReceiveProps(){
       
}
    imageUrl = (avatar) => {
        var str = avatar;
        var n = str.includes("uploads/");
        if (n) {
            avatar = window.APIURL + avatar;
            return avatar
        } else {
            return avatar
        }
    }

    dateCheck = (date) => {
        date = moment(date).fromNow();
        return date
    }

    toggleHover =async () => {
         const user = JSON.parse(localStorage.getItem("accessToken"));
         
         let userId = user.id;
         let data = {
                "read": true
         }
         if(this.state.readCount == 0) return
         var data1 = await apiHelper(
            "post",
            `api/tvClubNotifications/update?where=%7B%22notifyUser%22%3A%20%22${userId}%22%7D`,
             data
        );
        this.props.TvClubNotification();
        this.getOldNotification();
    }


    render() {
        var th = this;
        return (
            <aside class="widget-area" onMouseEnter={this.toggleHover}>
                <NotificationSystem ref={this._notificationSystem} />
                <div class="card widget-item">
                    <h4 class="widget-title">TvClub Notifications <span class="badge badge-danger mr-1"> {this.state.readCount}</span></h4>
                    <div class="widget-body all_notific_body">
                        <ul class="like-page-list-wrapper">
                            {this.state.postList.slice(0).reverse().map(function (item, i) {
                                return (
                                    <>
                                        {item.user && th.state.userId == item.notifyUser ?
                                            <li key={item.id} class="unorder-list">
                                                <div class="profile-thumb">
                                                    <a href="#">
                                                        <figure class="profile-thumb-small">
                                                            {item.user.avatar ? <img src={th.imageUrl(item.user.avatar)} alt="profile picture" /> : ''}
                                                        </figure>
                                                    </a>
                                                </div>
                                                <div class="unorder-list-info">
                                                    <h5 class="list-title"><a>New ClubPost ({item.tvClub.title})</a></h5>
                                                    <h3 class="list-title"><a >{item.post.description}</a></h3>
                                                    <p class="list-subtitle">{th.dateCheck(item.createdAt)} </p>
                                                </div>
                                            </li>
                                            : ""}
                                        {item.user && item.type == "comment" && th.state.userId == item.notifyUser ?
                                            <li class="unorder-list">
                                                <div class="profile-thumb">
                                                    <a href="#">
                                                        <figure class="profile-thumb-small">
                                                            {item.user.avatar ? <img src={th.imageUrl(item.user.avatar)} alt="profile picture" /> : ''}
                                                        </figure>
                                                    </a>
                                                </div>
                                                <div class="unorder-list-info">
                                                    <h5 class="list-title"><a> New Comment  ({item.tvClub.title})</a></h5>
                                                    <h3 class="list-title"><a >{item.post.description}</a></h3>
                                                    <p class="list-subtitle">{th.dateCheck(item.createdAt)} </p>
                                                </div>
                                            </li>
                                            : ""}
                                      
                                    </>
                                )
                            })}
                        </ul>
                    </div>
                     {/* <audio controls  id="myAudio" style={{"visibility": "hidden"}}>
                    <source src={mp3} type="audio/ogg" />
                    <source src={mp3} type="audio/mp3" />
                </audio> */}
                </div>
            </aside>
        );
    }
}

export default LikeComponent;


